import {
  AccordionButton,
  AccordionButtonProps,
  AccordionItem,
  AccordionPanel,
  Flex,
  Heading,
  Icon,
  Text,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

import {
  RegularArrowChevronDown,
  RegularArrowChevronUp,
} from '../../design/styles/icons/regular';

export type AccordionItemBasicProps = {
  leftIcon?: ReactNode;
  heading: string;
  subHeading?: ReactNode;
  rightComponent?: ReactNode;
  children: ReactNode;
  isDisabled?: boolean;
} & AccordionButtonProps;

export const AccordionItemBasic = ({
  leftIcon,
  heading,
  rightComponent,
  subHeading,
  children,
  isDisabled,
  ...rest
}: AccordionItemBasicProps) => {
  return (
    <AccordionItem isDisabled={isDisabled}>
      {({ isExpanded }) => (
        <>
          <Heading as="h6" textStyle="h6">
            <AccordionButton
              display="flex"
              justifyContent="space-between"
              height={subHeading ? 18 : 12}
              {...rest}
            >
              <Flex flexDir="column">
                <Flex>
                  {leftIcon ? leftIcon : null}
                  <Text textStyle="h6" fontWeight="semibold">
                    {heading}
                  </Text>
                </Flex>

                <Flex pl={leftIcon ? 8 : 0}>{subHeading}</Flex>
              </Flex>

              <Flex alignItems="center">
                {rightComponent}
                <Icon
                  as={
                    isExpanded ? RegularArrowChevronUp : RegularArrowChevronDown
                  }
                  boxSize={4.5}
                  ml={2}
                />
              </Flex>
            </AccordionButton>
          </Heading>
          <AccordionPanel
            backgroundColor="white"
            pb={4}
            pl={leftIcon ? 11 : 3}
            textStyle="bodyM"
          >
            {children}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api';
import { EditSkillGroupsParams, SimpleSkillGroup } from '../../../api/types';
import { URLParams } from '../../../types/params';
import URLParamsConvertor from '../../../utils/URLParamsConvertor';

const name = 'organizationSkillGroups';

export const initialParams: URLParams = {
  page: 0,
  size: 20,
  sort: [],
  search: '',
};

export const initialState: any = {
  list: [],
  hasMore: true,
  params: initialParams,
  loading: false,
  error: null,
  skillNames: [],
  skillGroup: {},
  initialEmployeeList: [],
  employees: [],
};

export const getAllSkillGroups = createAsyncThunk(
  `${name}/getAllSkillGroups`,
  async (params: URLParams) => {
    const response = await api.organization.allSkillGroups(
      URLParamsConvertor(params)
    );
    return response;
  }
);

export const getSkillGroup = createAsyncThunk(
  `${name}/getSkillGroup`,
  async (id: string) => {
    const response = await api.organization.getSkillGroup(id);
    return response.data;
  }
);

export const getInitialSkillNames = createAsyncThunk(
  `${name}/getInitialSkillNames`,
  async () => {
    const response = await api.organization.initialSkillNames();
    return response.data;
  }
);

export const editSkillGroup = createAsyncThunk(
  `${name}/editSkillGroup`,
  async (params: EditSkillGroupsParams, { rejectWithValue }) => {
    try {
      const response = await api.organization.editSkillGroup(params);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createSkillGroup = createAsyncThunk(
  `${name}/createSkillGroup`,
  async (group: SimpleSkillGroup, { rejectWithValue }) => {
    try {
      const response = await api.organization.createSkillGroup(group);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteSkillGroup = createAsyncThunk(
  `${name}/deleteSkillGroup`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.organization.deleteSkillGroup(id);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const assignSkillGroup = createAsyncThunk(
  `${name}/assignSkillGroup`,
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await api.organization.assignSkillGroup(params);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getInitialEmployeeGroupsList = createAsyncThunk(
  `${name}/getInitialEmployeeGroupsList`,
  async () => {
    const response = await api.organization.initialEmployeeGroupsList();
    return response.data;
  }
);

export const searchEmployeeGroups = createAsyncThunk(
  `${name}/searchEmployeeGroups`,
  async (inputValue: string) => {
    const response = await api.organization.searchEmployeeGroups(inputValue);
    return response.data;
  }
);

const OrganizationSkillGroupsSlice = createSlice({
  name,
  initialState,
  reducers: {
    updateParams: (state, { payload }) => {
      state.params = { ...state.params, [payload.prop]: payload.value };
    },
    resetTable: (state) => {
      state.list = initialState.list;
      state.hasMore = initialState.hasMore;
      state.loading = initialState.loading;
      state.params.page = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSkillGroups.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllSkillGroups.fulfilled, (state, action: any) => {
        state.list = [...state.list, ...action.payload.data.content];
        state.hasMore = !action.payload.data.last;
        state.loading = false;
      })
      .addCase(getAllSkillGroups.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
        state.hasMore = false;
      })
      .addCase(getInitialSkillNames.fulfilled, (state, action) => {
        state.skillNames = action.payload;
      })
      .addCase(getSkillGroup.fulfilled, (state, action) => {
        state.skillGroup = action.payload;
      })
      .addCase(getInitialEmployeeGroupsList.fulfilled, (state, action: any) => {
        state.initialEmployeeGroupsList = action.payload;
      })
      .addCase(searchEmployeeGroups.fulfilled, (state, action) => {
        state.employeeGroupss = action.payload;
      });
  },
});

export const { updateParams, resetTable } =
  OrganizationSkillGroupsSlice.actions;
export const organizationSkillGroupsSlice = OrganizationSkillGroupsSlice;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ModalComponent from '../../Modal';
import { Box, Button, Icon } from '@chakra-ui/react';
import { InputField } from '../../../design/components/InputField/InputField';
import isMaxLength from '../../../utils/isMaxLength';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '../../../design/hooks/useToast';
import { AppDispatch, RootState } from '../../../store/store';
import { useForm } from 'react-hook-form';
import { MAX_CHAR_LIMIT_50 as maxLimitName } from '../../../constants';
import SvgSolidSave from '../../../design/styles/icons/solid/SolidSave';
import { ReactComponent as Edit } from '../../../assets/icons/edit.svg';
import formatFilter from '../../../utils/formatFilter/formatFilter';
import { isEqual } from 'lodash';
import {
  createTemplate,
  editTemplate,
  getAllTemplates,
  updateSelectedTemplate,
} from '../../../store/slices/organizationEmployees/organizationEmployees';

const filterTemplateNameAlreadyExists = 'Template name already exists!';

interface Props {
  toastMessages: {
    title: {
      success: string;
      error: string;
    };
    description: {
      success: string;
      error: string;
    };
  };
  filterModalProps: {
    headerTitle: string;
    confirmButtonTitle: string;
  };
  isEditTemplate?: boolean;
  templateId?: string;
  onSave?: () => void;
}

const FilterModal = ({
  toastMessages,
  filterModalProps,
  isEditTemplate,
  templateId,
  onSave,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const selectedTemplate = useSelector<RootState, any>(
    (state: RootState) => state.organizationEmployees.selectedTemplate
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] =
    useState<boolean>(false);
  const filterState = useSelector<RootState, any>(
    (state: RootState) => state.organizationEmployees.filter
  );

  const {
    watch,
    setValue,
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    mode: 'onChange',
  });

  const templateNameInput = watch('templateNameInput');

  const clearInputs = useCallback(() => {
    setValue('templateNameInput', '');

    clearErrors(['templateNameInput']);
  }, [clearErrors, setValue]);

  const handleClose = useCallback(() => {
    setIsModalOpen(false);
    clearInputs();
  }, [clearInputs]);

  const toastMessage = useMemo(() => {
    return {
      title: '',
      description: '',
    };
  }, []);

  const setToastMessage = useCallback(
    (status: string) => {
      switch (status) {
        case 'success':
          toastMessage.description = 'A new template was created.';
          toastMessage.title = 'Successfully created a filter template';
          return toastMessage;
        case 'error':
          toastMessage.description = 'The creation of a new template failed.';
          toastMessage.title = 'Failed!';
          return toastMessage;
      }
    },
    [toastMessage]
  );

  const saveActions = useCallback(
    (res: any) => {
      const responseStatus = !res.error ? 'success' : 'error';
      clearInputs();
      setToastMessage(responseStatus);
      toast({
        position: 'top',
        title: toastMessages.title[responseStatus],
        status: responseStatus,
        description: toastMessages.description[responseStatus],
        isClosable: true,
        duration: 7000,
        variant: 'subtle',
      });
    },
    [
      clearInputs,
      setToastMessage,
      toast,
      toastMessages.description,
      toastMessages.title,
    ]
  );

  const handleDispatch = useCallback(
    (res: any) => {
      const errors = res?.payload?.response?.data?.errors;
      if (errors?.includes(filterTemplateNameAlreadyExists)) {
        setError('templateNameInput', {
          message: 'A template with this name already exists',
        });
      } else {
        saveActions(res);
        handleClose();
        if (onSave) {
          onSave();
        }
      }
      setIsSaveButtonDisabled(false);
    },
    [handleClose, onSave, saveActions, setError]
  );

  const handleSaveDispatch = useCallback(() => {
    const template: any = {
      name: templateNameInput?.trim(),
      employeeFilterDto: {
        ...formatFilter(filterState),
      },
    };
    dispatch(
      isEditTemplate
        ? editTemplate({ template, templateId })
        : createTemplate(template)
    )
      .then((res: any) => {
        handleDispatch(res);
      })
      .then(() => {
        if (!errors?.templateNameInput) {
          dispatch(getAllTemplates()).then((res: any) => {
            const editTemplateId = res.payload?.data?.find((item: any) => item.name === template.name).id;
            dispatch(
              updateSelectedTemplate({ label: template.name, id: editTemplateId ? editTemplateId : "" })
            )
          });
        }
      });
  }, [
    dispatch,
    errors,
    filterState,
    handleDispatch,
    isEditTemplate,
    templateId,
    templateNameInput,
  ]);

  const handleSave = useCallback(() => {
    handleSaveDispatch();
  }, [handleSaveDispatch]);

  useEffect(() => {
    return () => {
      clearInputs();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDisabled = useMemo(() => {
    return (
      isSaveButtonDisabled ||
      !templateNameInput ||
      isMaxLength(templateNameInput, maxLimitName)
    );
  }, [templateNameInput, isSaveButtonDisabled]);

  const FilterModalProps = {
    isModalOpen: isModalOpen,
    onCloseFunc: handleClose,
    headerTitle: filterModalProps.headerTitle,
    confirmButtonTitle: filterModalProps.confirmButtonTitle,
    confirmButtonFunc: handleSave,
    isDisabled,
    isEditTemplate,
    dataTestIdModal: 'filter-modal',
    dataTestIdModalOverlay: 'filter-modal-overlay',
    dataTestIdFooterCancelButton: 'filter-modal-cancel-button',
    dataTestIdFooterConfirmButton: 'filter-modal-confirm-button',
    showSecondConfirmButton: false,
  };

  const openTheModal = useCallback(() => {
    setIsModalOpen(true);
    if (isEditTemplate) {
      setValue('templateNameInput', selectedTemplate.label);
    }
  }, [isEditTemplate, setValue, selectedTemplate]);

  const inputArgs = {
    fontSize: 'lg',
    autoComplete: 'off',
  };
  return (
    <>
      <Button
        leftIcon={
          isEditTemplate ? (
            <Icon as={Edit} fontSize="13px" />
          ) : (
            <Icon as={SvgSolidSave} fontSize="18px" />
          )
        }
        colorScheme="primary"
        variant="outline"
        marginLeft="14px"
        fontSize="13px"
        fontWeight={600}
        onClick={openTheModal}
        data-testid="filter-create-template-button"
        data-qa="filter-create-template-button"
        isDisabled={isEqual(formatFilter(filterState), {
          employeeStatuses: [],
          levelOfExpertise: null,
          skillsWithPriority: [],
        })}
      >
        {isEditTemplate ? 'Edit template' : 'Save as template'}
      </Button>

      <ModalComponent {...FilterModalProps}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center"></Box>
        </Box>
        <Box>
          <InputField
            label="Filter name"
            placeholder="e.g. Design"
            isRequired
            errors={errors}
            {...register('templateNameInput', {
              required: 'Template name field is required',
              validate: {
                maxLength: (v) =>
                  !isMaxLength(v, maxLimitName) ||
                  `Template name cannot exceed more than ${maxLimitName} characters`,
              },
            })}
            {...inputArgs}
            data-qa="filter-modal-name-input"
            data-testid="filter-modal-name-input"
            defaultValue={templateNameInput}
          />
        </Box>
      </ModalComponent>
    </>
  );
};

export default FilterModal;

import React, { useCallback, useEffect, useState } from 'react';
import {
  Flex,
  Text,
  Box,
  Avatar,
  Badge,
  Icon,
  Tag,
  TagLabel,
  TagCloseButton,
  Accordion,
  Menu,
  MenuButton,
  MenuList,
  useRadioGroup,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../../store/store';
import If from '../../../../components/If';
import { colors, fontSizes } from '../../../../design/styles/foundations';
import {
  getEmployeeProfile,
  getHubLocations,
  userSlice,
} from '../../../../store/slices/user/user';
import { AccordionItemBasic } from '../../../../components/Accordion/AccordionItemBasic';
import SvgSolidStar from '../../../../design/styles/icons/solid/SolidStar';
import { ReactComponent as Edit } from '../../../../assets/icons/edit.svg';
import SvgLightStar from '../../../../design/styles/icons/light/LightStar';
import AddToEmployeeGroupsModal from '../AddToEmployeeGroupsModal';
import { MenuItemSingleSelect } from '../../../../design/components/Menu/MenuItemSingleSelect/MenuItemSingleSelect';
import { SimpleEmployeeGroupItem, UserRole } from '../../../../api/types';

interface HubLocationOption {
  id: string;
  name: string;
  isChecked: boolean;
  label: string | unknown;
}

const ProfileOverview = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user);
  const profile = useSelector((state: RootState) => state.user.profile);
  const [locationList, setLocationList] = useState<any>();
  const { id } = useParams();

  const parseLocation = () => {
    if (!profile.location) {
      return "Unknown";
    }
    const removedUnderscore = profile.location.replace(/_/i, ' ')
    const parsedLocation = removedUnderscore.replace(/\w\S*/g,
      function(txt : string) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
    return parsedLocation;
  };

  const handleMapResponse = useCallback((location: HubLocationOption) => {
    const locationsArray: HubLocationOption[] = [];
    if (location) {
      for (let [key, value] of Object.entries(location)) {
        let item = {
          id: key,
          name: key,
          isChecked: false,
          label: value,
        };
        locationsArray.push(item);
      }
      setLocationList(locationsArray);
    } else {
      setLocationList(locationsArray);
    }
  }, []);

  useEffect(() => {
    dispatch(getEmployeeProfile(id));
    dispatch(getHubLocations()).then((res) => handleMapResponse(res?.payload));
  }, [dispatch, handleMapResponse, id]);

  const updateEmployeeProfile = ({
    value,
    type,
  }: {
    value: string;
    type: string;
  }) => {
    dispatch(
      userSlice.actions.updateUserProfile({
        prop: type,
        value: value,
      })
    );
  };

  const getAddedGroups = (groups: any) => {
    dispatch(
      userSlice.actions.updateEmployeeGroups(groups.addedEmployeeGroups)
    );
  };

  const removeGroup = (id: string) => {
    dispatch(
      userSlice.actions.updateEmployeeGroups(
        profile.employeeGroups.filter(
          (group: SimpleEmployeeGroupItem) => group.id !== id
        )
      )
    );
  };

  return (
    <>
      <Box padding="1.6rem">
        <Flex>
          <Flex alignItems="center">
            <Avatar src={profile.image} name={profile.name} w="50px" h="50px" />
            <Box
              ml="12px"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <If condition={profile.status === 'ACTIVE'}>
                <Text
                  fontSize="4xl"
                  fontWeight={700}
                  color={colors.primary[500]}
                >
                  {profile.name}
                </Text>

                <Text fontSize="13px" color={colors.greySolid[900]}>
                  {profile.email}
                </Text>
              </If>
              <If condition={profile.status === 'DEACTIVATED'}>
                <Text
                  fontSize="4xl"
                  fontWeight={700}
                  color={colors.greySolid[600]}
                >
                  {profile.name}
                </Text>
                <Text fontSize="13px" color={colors.greySolid[600]}>
                  {profile.email}
                </Text>
              </If>
            </Box>
          </Flex>
          <Flex paddingLeft="6rem">
            <Box
              minWidth="150px"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Text
                fontSize="13px"
                color={colors.greySolid[900]}
                fontWeight={600}
              >
                Role
              </Text>
              <Flex color={colors.primary[500]} alignItems="center">
                <Text
                  marginRight="10px"
                  fontWeight={600}
                  fontSize={fontSizes.lg}
                >
                  {profile.role === UserRole.Admin ? 'Admin' : 'Employee'}
                </Text>
                <If condition={user.role === UserRole.Admin}>
                  <Menu closeOnSelect={true}>
                    <MenuButton
                      as={Edit}
                      width="12px"
                      height="12px"
                      cursor="pointer"
                      data-testid="employee-profile-overview-role-menu-button"
                    />
                    <MenuList minWidth="240px">
                      <MenuItemSingleSelect
                        options={[
                          {
                            id: 'admin',
                            name: UserRole.Admin,
                            isChecked: false,
                            label: 'Admin',
                          },
                          {
                            id: 'user',
                            name: UserRole.User,
                            isChecked: false,
                            label: 'Employee',
                          },
                        ]}
                        useRadioGroupReturn={useRadioGroup({
                          value: profile.role,
                          onChange: (value) =>
                            updateEmployeeProfile({ value, type: 'role' }),
                        })}
                      />
                    </MenuList>
                  </Menu>
                </If>
              </Flex>
            </Box>
            <Box
              minWidth="150px"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Text
                fontSize="13px"
                color={colors.greySolid[900]}
                fontWeight={600}
              >
                Location
              </Text>
              <Flex
                fontWeight={500}
                fontSize={fontSizes.lg}
                color={colors.primary[500]}
                alignItems="center"
              >
                <Text
                  marginRight="10px"
                  fontWeight={600}
                  fontSize={fontSizes.lg}
                >
                  {
                    parseLocation()
                  }
                </Text>
                <If condition={user.role === UserRole.Admin}>
                  <Menu closeOnSelect={true}>
                    <MenuButton
                      as={Edit}
                      width="12px"
                      height="12px"
                      cursor="pointer"
                    />
                    <MenuList minWidth="240px">
                      <MenuItemSingleSelect
                        options={locationList ? locationList : []}
                        useRadioGroupReturn={useRadioGroup({
                          value:
                            profile.location === null
                              ? 'Unknown'
                              : profile.location,
                          onChange: (value) =>
                            updateEmployeeProfile({ value, type: 'location' }),
                        })}
                      />
                    </MenuList>
                  </Menu>
                </If>
              </Flex>
            </Box>
            <Box
              minWidth="150px"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Text
                fontSize="13px"
                color={colors.greySolid[900]}
                fontWeight={600}
              >
                Status
              </Text>
              <Flex
                fontWeight={600}
                fontSize={fontSizes.lg}
                color={colors.primary[500]}
                alignItems="center"
              >
                <Text
                  marginRight="10px"
                  fontWeight={600}
                  fontSize={fontSizes.lg}
                >
                  {profile.status === 'ACTIVE' ? 'Active' : 'Deactivated'}
                </Text>
                <If condition={user.role === UserRole.Admin}>
                  <Menu closeOnSelect={true}>
                    <MenuButton
                      as={Edit}
                      width="12px"
                      height="12px"
                      cursor="pointer"
                    />
                    <MenuList minWidth="240px">
                      <MenuItemSingleSelect
                        options={[
                          {
                            id: 'active',
                            name: 'ACTIVE',
                            isChecked: false,
                            label: 'Active',
                          },
                          {
                            id: 'deactivated',
                            name: 'DEACTIVATED',
                            isChecked: false,
                            label: 'Deactivated',
                          },
                        ]}
                        useRadioGroupReturn={useRadioGroup({
                          value: profile.status,
                          onChange: (value) =>
                            updateEmployeeProfile({ value, type: 'status' }),
                        })}
                      />
                    </MenuList>
                  </Menu>
                </If>
              </Flex>
            </Box>
          </Flex>
        </Flex>
        <Box marginTop="60px">
          <Flex justifyContent="space-between" marginBottom="5px">
            <Flex alignItems="center">
              <Text fontSize="13px" fontWeight={600} marginRight="12px">
                Groups
              </Text>
              <Badge>{profile.employeeGroups.length}</Badge>
            </Flex>
            <If condition={user.role === UserRole.Admin}>
              <Flex
                alignItems="center"
                fontSize="13px"
                fontWeight={600}
                cursor="pointer"
              >
                <Text color={colors.primary[500]}>
                  <AddToEmployeeGroupsModal
                    selectedIds={[]}
                    assignType={'addToGroup'}
                    isDisabled={false}
                    isGhostButton={true}
                    getAddedGroups={getAddedGroups}
                  />
                </Text>
              </Flex>
            </If>
          </Flex>
          <Flex flexWrap="wrap">
            {profile.employeeGroups.map(
              (group: { id: string; name: string }) => (
                <Tag
                  key={group.id}
                  backgroundColor="#B1CEFF"
                  color="#154DAE"
                  borderRadius="3px"
                  px={2}
                  py="3px"
                  fontWeight="400"
                  margin="4px 8px 4px 0"
                >
                  <TagLabel>{group.name}</TagLabel>
                  <If condition={user.role === UserRole.Admin}>
                    <TagCloseButton
                      fontSize="sm"
                      color={colors.greySolid[900]}
                      width="12px"
                      height="12px"
                      _hover={{
                        backgroundColor: colors.primary[300],
                        borderRadius: '3px',
                      }}
                      onClick={() => removeGroup(group.id)}
                      data-testid={`employee-profile-overview-remove-group-button-${group.id}`}
                      data-qa={`employee-profile-overview-remove-group-button-${group.id}`}
                    />
                  </If>
                </Tag>
              )
            )}
          </Flex>
        </Box>
        <Box marginTop="60px">
          <Flex justifyContent="space-between" marginBottom="5px">
            <Flex alignItems="center">
              <Text fontSize="13px" fontWeight={600} marginRight="12px">
                Pending Skills
              </Text>
              <Badge>{profile.pendingSkills.length}</Badge>
            </Flex>
          </Flex>
          <Flex flexWrap="wrap">
            {profile.pendingSkills.map((skill: string) => (
              <Tag
                key={skill}
                backgroundColor="#FFF0D1"
                color="#C88709"
                borderRadius="3px"
                px={2}
                py="3px"
                fontWeight="400"
                margin="4px 8px 4px 0"
              >
                <TagLabel>{skill}</TagLabel>
              </Tag>
            ))}
          </Flex>
        </Box>
        <Box marginTop="60px">
          <Flex justifyContent="space-between">
            <Flex alignItems="center">
              <Text fontSize="13px" fontWeight={600} marginRight="12px">
                Completed skills
              </Text>
              <Badge>{profile.completedSkills.length}</Badge>
            </Flex>
          </Flex>
          <Box marginTop="16px">
            <Accordion
              defaultIndex={[]}
              allowMultiple
              borderColor="transparent"
              borderRadius="5px"
            >
              {Object.entries(profile.levelOfExpertiseGroups).map(
                ([key, arr]: any, idx: any) => {
                  return (
                    <AccordionItemBasic
                      key={key}
                      leftIcon={
                        <Flex fontSize="22px">
                          <Icon as={idx > 4 ? SvgLightStar : SvgSolidStar} />
                          <Icon as={idx > 3 ? SvgLightStar : SvgSolidStar} />
                          <Icon as={idx > 2 ? SvgLightStar : SvgSolidStar} />
                          <Icon as={idx > 1 ? SvgLightStar : SvgSolidStar} />
                          <Icon as={idx > 0 ? SvgLightStar : SvgSolidStar} />
                        </Flex>
                      }
                      _hover={{
                        backgroundColor: 'none',
                      }}
                      marginTop="10px"
                      heading=""
                      color={colors.primary[500]}
                      backgroundColor="white"
                      borderColor="transparent"
                      borderRadius="5px"
                    >
                      <Flex backgroundColor="white" flexWrap="wrap">
                        {!arr.length ? (
                          <Text fontSize="13px">No skills yet.</Text>
                        ) : (
                          arr.map((skill: string) => (
                            <Tag
                              key={skill}
                              backgroundColor="#CEE9D2"
                              color="#407F4A"
                              borderRadius="3px"
                              px={2}
                              fontWeight="400"
                              margin="4px 8px 4px 0"
                            >
                              <TagLabel>{skill}</TagLabel>
                            </Tag>
                          ))
                        )}
                      </Flex>
                    </AccordionItemBasic>
                  );
                }
              )}
            </Accordion>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProfileOverview;

import React from 'react';
import AssignModal from '../../../../../../components/AssignModal';
import { assignSkillGroups } from '../../../../../../store/slices/organizationEmployeeGroups/organizationEmployeeGroups';
import {
  getInitialSkillGroups,
  searchSkillGroups,
} from '../../../../../../store/slices/organizationSkills/organizationSkills';

interface Props {
  selectedIds: string[];
  assignType: string;
  isDisabled: boolean;
  onSave?: () => void;
}

const AssignSkillGroupsModal = ({
  selectedIds,
  assignType,
  isDisabled,
  onSave,
}: Props) => {
  return (
    <AssignModal
      selectedIds={selectedIds}
      assignType={assignType}
      toastMessages={{
        title: {
          success: 'Successfully assigned skill groups',
          error: 'Failed!',
        },
        description: {
          success:
            'Skill groups were assigned to the selected employee groups.',
          error: 'The assigning a skill groups to employee groups failed.',
        },
      }}
      assignModalProps={{
        headerTitle: 'Assign skill groups',
        confirmButtonTitle: 'Assign',
        placeholderText: 'Search for skill groups',
        dataTestIdModal: 'assign-skill-groups-modal',
        dataTestIdModalOverlay: 'assign-skill-groups-modal-overlay',
        dataTestIdFooterCancelButton: 'assign-skill-groups-modal-cancel-button',
        dataTestIdFooterConfirmButton:
          'assign-skill-groups-modal-confirm-button',
      }}
      assignModalLabel="Skill groups"
      assignAutocompleteInputId="assign-skill-groups-to-employee-groups-autocomplete"
      assignSliceAction={assignSkillGroups}
      assignSearchSliceAction={searchSkillGroups}
      assignInitialListSliceAction={getInitialSkillGroups}
      dataTestIdOpenModalButton="assign-skill-groups-to-employee-groups-button"
      isDisabled={isDisabled}
      onSave={onSave}
      isGroup
    />
  );
};

export default AssignSkillGroupsModal;

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  organizationSkillGroupsSlice,
  getAllSkillGroups,
} from '../../../../../../store/slices/organizationSkillGroups/organizationSkillGroups';
import { AppDispatch, RootState } from '../../../../../../store/store';
import { URLParams } from '../../../../../../types/params';
import SkillGroupsModal from '../SkillGroupsModal';

const CreateSkillGroupsModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const params = useSelector<RootState, URLParams>(
    (state: RootState) => state.organizationSkillGroups.params
  );

  const reloadTable = useCallback(() => {
    dispatch(organizationSkillGroupsSlice.actions.resetTable());
    const newParams = { ...params, page: 0 };
    dispatch(getAllSkillGroups(newParams));
    dispatch(
      organizationSkillGroupsSlice.actions.updateParams({
        prop: 'page',
        value: 1,
      })
    );
  }, [dispatch, params]);

  return (
    <>
      <SkillGroupsModal
        toastMessages={{
          title: {
            success: 'Successfully created a new skill group',
            error: 'Failed!',
          },
          description: {
            success: 'A new skill group was created.',
            error: 'The creation of a new skill group failed.',
          },
        }}
        skillGroupsModalProps={{
          headerTitle: 'Create skill group',
          confirmButtonTitle: 'Create',
        }}
        onSave={reloadTable}
      />
    </>
  );
};

export default CreateSkillGroupsModal;

import React from 'react';
import { InputGroup, InputLeftElement, Input, Icon } from '@chakra-ui/react';
import LightSearch from '../../design/styles/icons/light/LightSearch';
import { colors } from '../../design/styles/foundations/colors';
import './SearchInput.scss';

interface Props {
  placeholder?: string;
  defaultValue?: string;
  handleSearch: (value: string) => void;
  dataTestId: string;
  dataQa: string;
}

function SearchInput({
  placeholder,
  defaultValue,
  handleSearch,
  dataTestId,
  dataQa,
}: Props) {
  return (
    <InputGroup>
      <InputLeftElement
        pointerEvents="none"
        children={
          <Icon
            as={LightSearch}
            color={colors.greySolid[500]}
            w="14px"
            h="14px"
          />
        }
      />
      <Input
        placeholder={placeholder}
        pl="30px"
        color={colors.greySolid[900]}
        fontSize="lg"
        border="none"
        _hover={{
          border: 'none',
          boxShadow: `0px 0px 0px 1px ${colors.primary[500]} !important`,
        }}
        className="search-input"
        data-qa={dataQa}
        data-testid={dataTestId}
        defaultValue={defaultValue}
        onChange={(e) => handleSearch(e.target.value)}
      />
    </InputGroup>
  );
}

export default SearchInput;

export default function showMoreIndicator(id: string | number, elementClass: string, totalItems?: number, maxListLimit = 20, tagsGap: number = 8, numberIndicatorWidth: number = 32) {
  const el: any = document.getElementById(
    `${elementClass}-${id}`
  );
  if (el) {
    const listContainer = el.childNodes[0];
    let listTotalWidth = numberIndicatorWidth;
    const list = listContainer.childNodes[0].childNodes[0].childNodes;
    list.forEach((item: any, i: number) => {
      list[i].removeAttribute('style');
      list[i].classList.remove(
        `${elementClass}-hide-${id}`
      );
      if (
        listTotalWidth + item.offsetWidth + tagsGap >
        listContainer.offsetWidth
      ) {
        list[i].classList.add(
          `${elementClass}-hide-${id}`
        );
        list[i].style.display = 'none';
      } else {
        listTotalWidth += item.offsetWidth + tagsGap;
      }
    });
    const nonVisibleElements = document.getElementsByClassName(
      `${elementClass}-hide-${id}`
    );
    if (totalItems && totalItems >= maxListLimit) {
      const numberOfMoreItems = totalItems - maxListLimit + nonVisibleElements.length;
      return numberOfMoreItems
        ? `+${numberOfMoreItems}`
        : '';
    } else {
      return nonVisibleElements.length > 0
        ? `+${nonVisibleElements.length}`
        : '';
    }
  }
};

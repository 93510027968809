import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  organizationEmployeeGroupsSlice,
  getAllEmployeeGroups,
} from '../../../../../../store/slices/organizationEmployeeGroups/organizationEmployeeGroups';
import { AppDispatch, RootState } from '../../../../../../store/store';
import { URLParams } from '../../../../../../types/params';
import EmployeeGroupsModal from '../EmployeeGroupsModal';

const CreateEmployeeGroupsModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const params = useSelector<RootState, URLParams>(
    (state: RootState) => state.organizationEmployeeGroups.params
  );

  const reloadTable = useCallback(() => {
    dispatch(organizationEmployeeGroupsSlice.actions.resetTable());
    const newParams = { ...params, page: 0 };
    dispatch(getAllEmployeeGroups(newParams));
    dispatch(
      organizationEmployeeGroupsSlice.actions.updateParams({
        prop: 'page',
        value: 1,
      })
    );
  }, [dispatch, params]);

  return (
    <>
      <EmployeeGroupsModal
        toastMessages={{
          title: {
            success: 'Successfully saved an employee group',
            error: 'Failed!',
          },
          description: {
            success: 'An employee group was saved.',
            error: 'The saving of an employee group failed.',
          },
        }}
        employeeGroupsModalProps={{
          headerTitle: 'Create new employee group',
          confirmButtonTitle: 'Create',
        }}
        onSave={reloadTable}
      />
    </>
  );
};

export default CreateEmployeeGroupsModal;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tr, Td, Text, AvatarGroup, Avatar } from '@chakra-ui/react';
import { Checkbox } from '../../../../design/components/Checkbox/Checkbox';
import Table from '../../../../components/Wrappers/Table';
import { Column } from '../../../../components/Wrappers/Table/Table';
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll';
import { URLParams } from '../../../../types/params';
import {
  getAllEmployeeGroups,
  organizationEmployeeGroupsSlice,
} from '../../../../store/slices/organizationEmployeeGroups/organizationEmployeeGroups';
import { AppDispatch, RootState } from '../../../../store/store';
import { EmployeeGroup, SimpleEmployee } from '../../../../api/types';
import { colors } from '../../../../design/styles/foundations/colors';
import '../../../../assets/styles/main.scss';
import EditEmployeeGroupsModal from './components/EditEmployeeGroupsModal';
import DeleteEmployeeGroupsModal from './components/DeleteEmployeeGroupsModal';
import If from '../../../../components/If';
import { selectAll, selectItems } from '../../../../utils/checkboxGroupActions';
import AssignSkillsModal from './components/AssignSkillsModal/AssignSkillsModal';
import AssignSkillGroupsModal from './components/AssignSkillGroupsModal';
import SearchInput from '../../../../components/SearchInput';
import { debounce } from 'lodash';

const HEADER_STYLE: React.CSSProperties = {
  top: '0px',
};

const TABLE_PADDING = 20;

const TABLE_COLUMNS_EMPLOYEE_GROUPS: Column[] = [
  {
    name: 'Name',
    sortValue: 'name',
    style: HEADER_STYLE,
  },
  {
    name: 'Employees',
    sortValue: '',
    style: HEADER_STYLE,
    disableSort: true,
  },
  {
    name: '',
    sortValue: '',
    style: HEADER_STYLE,
    disableSort: true,
  },
];

function EmployeeGroupsTable() {
  const dispatch = useDispatch<AppDispatch>();
  const employeeGroups = useSelector(
    (state: RootState) => state.organizationEmployeeGroups.list
  );
  const hasMore = useSelector(
    (state: RootState) => state.organizationEmployeeGroups.hasMore
  );
  const loading = useSelector<RootState, boolean>(
    (state: RootState) => state.organizationEmployeeGroups.loading
  );
  const params = useSelector<RootState, URLParams>(
    (state: RootState) => state.organizationEmployeeGroups.params
  );
  const containerRef: any = useRef<HTMLHtmlElement>();

  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  const handleCall = useCallback(
    ({ page }: any) => {
      dispatch(getAllEmployeeGroups(params));
      dispatch(
        organizationEmployeeGroupsSlice.actions.updateParams({
          prop: 'page',
          value: page + 1,
        })
      );
    },
    [dispatch, params]
  );

  const handleSort = useCallback(
    (srt: any) => {
      dispatch(
        organizationEmployeeGroupsSlice.actions.updateParams({
          prop: 'sort',
          value: srt,
        })
      );
      dispatch(organizationEmployeeGroupsSlice.actions.resetTable());
    },
    [dispatch]
  );

  const handleDebounce = debounce((value) => {
    dispatch(
      organizationEmployeeGroupsSlice.actions.updateParams({
        prop: 'search',
        value,
      })
    );
    dispatch(organizationEmployeeGroupsSlice.actions.resetTable());
  }, 300);

  const handleSearch = useCallback(
    (value: string) => {
      handleDebounce(value);
    },
    [handleDebounce]
  );

  useEffect(() => {
    return () => {
      dispatch(organizationEmployeeGroupsSlice.actions.resetTable());
    };
  }, [dispatch]);

  const { Trigger } = useInfiniteScroll({
    rows: employeeGroups,
    next: handleCall,
    container: containerRef.current,
    hasMore,
    loading,
    page: params.page,
    size: params.size,
    sort: params.sort,
  });

  const tableHeight = useCallback(() => {
    return (
      containerRef?.current?.getBoundingClientRect().top + TABLE_PADDING || 0
    );
  }, []);

  const handleSelectAllEmployeeGroups = useCallback(
    (isSelected: boolean) => {
      const checkedItemsList = selectAll(isSelected, employeeGroups);
      setCheckedItems(checkedItemsList);
    },
    [employeeGroups]
  );

  const handleSelectEmployeeGroups = useCallback(
    (id: string, e: React.BaseSyntheticEvent) => {
      const checkedItemsList = selectItems(id, e, checkedItems);
      setCheckedItems(checkedItemsList);
    },
    [checkedItems]
  );

  const renderWrapItemEmployees = useCallback((employee: SimpleEmployee) => {
    return (
      <Avatar
        key={employee.id}
        name={employee.name}
        src={employee.image}
        w="32px"
        h="32px"
        showBorder
      />
    );
  }, []);

  const reloadTable = useCallback(() => {
    dispatch(organizationEmployeeGroupsSlice.actions.resetTable());
    const newParams = { ...params, page: 0 };
    dispatch(getAllEmployeeGroups(newParams));
    dispatch(
      organizationEmployeeGroupsSlice.actions.updateParams({
        prop: 'page',
        value: 1,
      })
    );
  }, [dispatch, params]);

  const renderRow = useCallback(
    (row: EmployeeGroup, index: number) => {
      return (
        <Tr
          key={row.id + JSON.stringify(index)}
          data-testid="organization-employee-group-table-row"
          data-qa="organization-employee-group-table-row"
          className="organization-table-row"
          bgColor={checkedItems.includes(row.id) ? colors.primary[100] : ''}
        >
          <Td width="0" pr="0" mr="0">
            <Checkbox
              isChecked={checkedItems.includes(row.id)}
              onChange={(e) => handleSelectEmployeeGroups(row.id, e)}
              data-testid={`organization-employee-group-table-row-checkbox-${row.id}`}
              data-qa={`organization-employee-group-table-row-checkbox-${row.id}`}
              className="chkbox-shadow"
            />
          </Td>

          <Td width="270px">{row.name}</Td>

          <Td>
            <Box display="flex" alignItems="center">
              <AvatarGroup
                size="sm"
                max={15}
                className="organization-table-row-avatar-group-number"
              >
                {row.employees.map((employee: SimpleEmployee) => {
                  return renderWrapItemEmployees(employee);
                })}
              </AvatarGroup>
              <If condition={row.totalEmployees > 0}>
                <Box ml="12px">
                  <Text
                    color={colors.primary[500]}
                    fontSize="sm"
                    fontWeight="semibold"
                  >
                    {row.totalEmployees}{' '}
                    {row.totalEmployees > 1 ? 'employees' : 'employee'}
                  </Text>
                </Box>
              </If>
            </Box>
          </Td>
          <Td width="20px">
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <EditEmployeeGroupsModal id={row.id} onSave={reloadTable} />
              <DeleteEmployeeGroupsModal id={row.id} onDelete={reloadTable} />
            </Box>
          </Td>
        </Tr>
      );
    },
    [
      checkedItems,
      reloadTable,
      handleSelectEmployeeGroups,
      renderWrapItemEmployees,
    ]
  );
  return (
    <>
      <Box display="flex" px="25px" mt="25px" mb="30px">
        <SearchInput
          placeholder="Search a employee group by name"
          defaultValue={params.search}
          handleSearch={handleSearch}
          dataTestId="organization-employee-group-table-search-input"
          dataQa="organization-employee-group-table-search-input"
        />
      </Box>
      <Box
        px="25px"
        overflow="auto"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height={`calc(100vh - ${tableHeight()}px)`}
        ref={containerRef}
      >
        <Table
          data-testid="organization-employee-group-table"
          data-qa="organization-employee-group-table"
          columns={TABLE_COLUMNS_EMPLOYEE_GROUPS}
          rows={employeeGroups}
          sort={params.sort}
          onSort={handleSort}
          Trigger={Trigger}
          stickyHeader
          showCheckbox
          isCheckboxDisabled={!employeeGroups.length}
          isSelectedAll={
            checkedItems.length === employeeGroups.length &&
            employeeGroups.length > 0
          }
          onSelectAll={handleSelectAllEmployeeGroups}
          renderRow={renderRow}
        />
        <Box display="flex" className="organization-table-row-bottom">
          <AssignSkillsModal
            selectedIds={checkedItems}
            assignType={'skills'}
            isDisabled={
              !employeeGroups.length ||
              Boolean(employeeGroups.length && !checkedItems.length)
            }
            onSave={() => setCheckedItems([])}
          />
          <Box ml="8px">
            <AssignSkillGroupsModal
              selectedIds={checkedItems}
              assignType={'skillGroupsToEmployeeGroups'}
              isDisabled={
                !employeeGroups.length ||
                Boolean(employeeGroups.length && !checkedItems.length)
              }
              onSave={() => setCheckedItems([])}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default EmployeeGroupsTable;

import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Text, Flex, Icon, Button } from '@chakra-ui/react';
import { colors } from '../../../design/styles/foundations/colors';
import SvgSolidDraggable from '../../../design/styles/icons/solid/SolidDraggable';
import SvgSolidClose from '../../../design/styles/icons/solid/SolidClose';

const SortableSkill = (props: any) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props?.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Flex
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      fontSize="13px"
      color={colors.greySolid[900]}
      padding="6px 8px"
      alignItems="center"
      justifyContent="space-between"
      _hover={{ bg: '#F6F7FC', borderRadius: '0.25rem', cursor: 'grab' }}
      _active={{ cursor: 'grabbing' }}
      data-testid="filter-priority-skill"
    >
      <Flex>
        <Icon as={SvgSolidDraggable} />
        <Text marginLeft="12px">{props.label}</Text>
      </Flex>
      <Button
        variant="ghost"
        padding="0"
        minWidth="18px"
        maxHeight="18px"
        isDisabled={transform !== null}
        onClick={() => props.removeSkillItem(props.id)}
        data-testid="filter-priority-skill-remove"
      >
        <Icon as={SvgSolidClose} fontSize="13px" padding="0" />
      </Button>
    </Flex>
  );
};

export default SortableSkill;

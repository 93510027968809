import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../../store/store';
import ModalComponent from '../../../../../../components/Modal';
import SvgSolidPlus from '../../../../../../design/styles/icons/solid/SolidPlus';
import { LightSearch } from '../../../../../../design/styles/icons/light';
import {
  createSkillGroup,
  getSkillGroup,
  editSkillGroup,
  getInitialSkillNames,
} from '../../../../../../store/slices/organizationSkillGroups/organizationSkillGroups';
import { InputField } from '../../../../../../design/components/InputField/InputField';
import { AsyncAutocompleteField } from '../../../../../../design/components/AsyncAutocompleteField/AsyncAutocompleteField';
import { useToast } from '../../../../../../design/hooks/useToast';
import isMaxLength from '../../../../../../utils/isMaxLength/isMaxLength';
import {
  EditSkillGroupsParams,
  LoadOption,
  SimpleSkill,
  SimpleSkillGroup,
  SkillGroup,
} from '../../../../../../api/types';
import If from '../../../../../../components/If';
import { ReactComponent as Edit } from '../../../../../../assets/icons/edit.svg';
import { colors } from '../../../../../../design/styles/foundations';
import { debounce, isEqual } from 'lodash';
import { MAX_CHAR_LIMIT_50 as maxLimit } from '../../../../../../constants';
import { searchSkills } from '../../../../../../store/slices/organizationEmployeeGroups/organizationEmployeeGroups';

const skillGroupNameAlreadyExists = 'Skill group already exists!';

interface Props {
  toastMessages: {
    title: {
      success: string;
      error: string;
    };
    description: {
      success: string;
      error: string;
    };
  };
  skillGroupsModalProps: {
    headerTitle: string;
    confirmButtonTitle: string;
  };
  isEditSkillGroup?: boolean;
  id?: string;
  onSave?: () => void;
}

interface SkillValue {
  value: string;
}

const SkillGroupsModal = ({
  toastMessages,
  skillGroupsModalProps,
  isEditSkillGroup = false,
  id,
  onSave,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const skillGroup = useSelector(
    (state: RootState) => state.organizationSkillGroups?.skillGroup
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false);
  const initialSkillNames = useSelector(
    (state: RootState) => state.organizationSkillGroups.skillNames
  );

  const {
    control,
    watch,
    setValue,
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    mode: 'onChange',
  });

  const skillGroupNameInput = watch('skillGroupNameInput');
  const skillsAutocompleteInput = watch('skillsAutocompleteInput');

  const clearInputs = useCallback(() => {
    setValue('skillGroupNameInput', '');
    setValue('skillsAutocompleteInput', '');
    clearErrors(['skillGroupNameInput', 'skillsAutocompleteInput']);
  }, [clearErrors, setValue]);

  const handleClose = useCallback(() => {
    setIsModalOpen(false);
    clearInputs();
  }, [clearInputs]);

  const saveActions = useCallback(
    (res: any) => {
      const responseStatus = !res.error ? 'success' : 'error';
      clearInputs();
      toast({
        position: 'top',
        title: toastMessages.title[responseStatus],
        status: responseStatus,
        description: toastMessages.description[responseStatus],
        isClosable: true,
        duration: 7000,
        variant: 'subtle',
      });
    },
    [clearInputs, toast, toastMessages]
  );
  const handleEditArraysAutocomplete = useCallback(() => {
    const mappedSkills = skillGroup?.skills.map((e: SimpleSkill) => ({
      value: e.name,
      label: e.name,
      id: e.id,
    }));

    const skillGroupName = skillGroupNameInput;

    const addedItems = skillsAutocompleteInput
      ? skillsAutocompleteInput
          .filter(
            ({ value: nameOne }: SkillValue) =>
              !mappedSkills.some(
                ({ value: nameTwo }: SkillValue) => nameTwo === nameOne
              )
          )
          .map((skill: SimpleSkill) => skill.id)
      : '';

    const removedItems = mappedSkills
      ?.filter(
        ({ value: nameOne }: SkillValue) =>
          !skillsAutocompleteInput.some(
            ({ value: nameTwo }: SkillValue) => nameTwo === nameOne
          )
      )
      ?.map((skill: SimpleSkill) => skill.id);

    return {
      skillGroupName,
      skillsToAdd: addedItems,
      skillsToRemove: removedItems,
    };
  }, [skillGroup, skillGroupNameInput, skillsAutocompleteInput]);

  const handleConfirm = useCallback(() => {
    setIsSaveButtonDisabled(true);
    const group: SimpleSkillGroup = {
      skillGroupName: skillGroupNameInput?.trim(),
      skillsToAdd: skillsAutocompleteInput
        ? skillsAutocompleteInput.map(
            (item: { value: string; label: string; id: string }) => item.id
          )
        : [],
    };

    const params: EditSkillGroupsParams = isEditSkillGroup
      ? {
          data: handleEditArraysAutocomplete(),
          id: skillGroup?.id,
        }
      : {};

    const confirmAction = isEditSkillGroup
      ? dispatch(editSkillGroup(params))
      : dispatch(createSkillGroup(group));

    confirmAction.then((res: any) => {
      const errors = res?.payload?.response?.data?.errors;
      if (errors?.includes(skillGroupNameAlreadyExists)) {
        setError('skillGroupNameInput', {
          message: 'A group with this name already exists',
        });
      } else {
        saveActions(res);
        handleClose();
        if (onSave) {
          onSave();
        }
      }
      setIsSaveButtonDisabled(false);
    });
  }, [
    skillGroupNameInput,
    skillsAutocompleteInput,
    isEditSkillGroup,
    handleEditArraysAutocomplete,
    skillGroup?.id,
    dispatch,
    setError,
    saveActions,
    handleClose,
    onSave,
  ]);

  const handleMapResponse = useCallback((res: any) => {
    return res
      ? res.map((e: any, index: number) => ({
          value: e.name,
          label: e.name,
          id: e.id,
        }))
      : [];
  }, []);

  const handleDebounce = debounce((value, cbFunction) => {
    dispatch(searchSkills(value)).then((res) =>
      cbFunction(handleMapResponse(res?.payload))
    );
  }, 300);

  const handleLoadingOptions = useCallback(
    (inputValue: string, cbFunction: (options: LoadOption[]) => void) => {
      handleDebounce(inputValue, cbFunction);
    },
    [handleDebounce]
  );

  useEffect(() => {
    return () => {
      clearInputs();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDisabled = useMemo(() => {
    return (
      isSaveButtonDisabled || (
      !skillGroupNameInput ||
      isMaxLength(skillGroupNameInput, maxLimit) ||
      (!!id &&
        isEditSkillGroup &&
        skillGroupNameInput?.trim() === skillGroup?.name?.trim() &&
        isEqual(
          skillsAutocompleteInput?.map((item: any) => item.id).sort(),
          skillGroup?.skills?.map((item: any) => item.id).sort()
        ))
    ));
  }, [
    id,
    isEditSkillGroup,
    skillGroup?.name,
    skillGroup?.skills,
    skillGroupNameInput,
    skillsAutocompleteInput,
    isSaveButtonDisabled
  ]);

  const CreateNewSkillGroupsModalProps = {
    isModalOpen: isModalOpen,
    onCloseFunc: handleClose,
    headerTitle: skillGroupsModalProps.headerTitle,
    confirmButtonTitle: skillGroupsModalProps.confirmButtonTitle,
    confirmButtonFunc: handleConfirm,
    isDisabled,
    dataTestIdModal: 'skill-group-modal',
    dataTestIdModalOverlay: 'skill-group-modal-overlay',
    dataTestIdFooterCancelButton: 'skill-group-modal-cancel-button',
    dataTestIdFooterConfirmButton: 'skill-group-modal-confirm-button',
  };

  const openTheModal = useCallback(() => {
    setIsModalOpen(true);
    dispatch(getInitialSkillNames());
    if (isEditSkillGroup && id) {
      dispatch(getSkillGroup(id)).then((res: any) => {
        setValue('skillGroupNameInput', res?.payload?.name);
        setValue(
          'skillsAutocompleteInput',
          res?.payload?.skills?.map((e: SkillGroup) => ({
            value: e.name,
            label: e.name,
            id: e.id,
          }))
        );
      });
    }
  }, [dispatch, id, isEditSkillGroup, setValue]);

  const inputArgs = {
    fontSize: 'lg',
    autoComplete: 'off',
  };
  return (
    <>
      <If condition={!!isEditSkillGroup}>
        <Icon
          as={Edit}
          w="16px"
          h="16px"
          color={`${colors.greySolid[800]}`}
          _hover={{ color: colors.primary[500], cursor: 'pointer' }}
          className="organization-table-row-edit-icon"
          onClick={openTheModal}
          data-testid={`organization-edit-skill-edit-button-${id}`}
          data-qa={`organization-edit-skill-edit-button-${id}`}
        />
      </If>
      <If condition={!isEditSkillGroup}>
        <Button
          leftIcon={<SvgSolidPlus width="20px" height="20px" />}
          colorScheme="primary"
          variant="solid"
          size="md"
          fontWeight="600"
          iconSpacing="4px"
          ml={3}
          onClick={openTheModal}
          data-testid="organization-create-skill-group-button"
          data-qa="organization-create-skill-group-button"
        >
          Create new
        </Button>
      </If>
      <ModalComponent {...CreateNewSkillGroupsModalProps}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center"></Box>
        </Box>
        <Box>
          <InputField
            label="Skill group name"
            placeholder="e.g. front-end"
            isRequired
            errors={errors}
            {...register('skillGroupNameInput', {
              required: 'Skill name field is required',
              validate: {
                maxLength: (v) =>
                  !isMaxLength(v, maxLimit) ||
                  `Skill name group cannot exceed more than ${maxLimit} characters`,
              },
            })}
            {...inputArgs}
            data-qa="skill-group-name-input"
            data-testid="skill-group-name-input"
            defaultValue={skillGroupNameInput}
          />
        </Box>
        <Box mt="24px">
          <AsyncAutocompleteField
            label="Skills"
            placeholder={
              <Flex alignItems="center">
                <Icon as={LightSearch} mr="1" fontSize={'16px'} />
                <Text>Search for skills</Text>
              </Flex>
            }
            hideDropdownIndicator
            numOfLabels={15}
            isMulti
            options={handleMapResponse(initialSkillNames)}
            loadOptions={handleLoadingOptions}
            name="skillsAutocompleteInput"
            control={control}
            isClearable={false}
            closeMenuOnSelect
            id="skill-group-modal-skills-autocomplete-input"
            defaultValue={skillsAutocompleteInput}
          />
        </Box>
      </ModalComponent>
    </>
  );
};

export default SkillGroupsModal;

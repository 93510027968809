import * as React from 'react';
import { SVGProps } from 'react';
const SvgSolidSave = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1.5em"
    height="1.5em"
    viewBox="-3 0 23 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.22266 12.4443H9.77821V15.9999H6.22266V12.4443Z"
      fill="currentColor"
    />
    <path
      d="M15.2178 5.07556L10.9244 0.782222C10.4248 0.281982 9.74701 0.000622407 9.04 0H6.22222V5.33333H10.6667C10.9024 5.33333 11.1285 5.42698 11.2952 5.59368C11.4619 5.76038 11.5556 5.98647 11.5556 6.22222C11.5556 6.45797 11.4619 6.68406 11.2952 6.85076C11.1285 7.01746 10.9024 7.11111 10.6667 7.11111H5.33333C5.09759 7.11111 4.87149 7.01746 4.70479 6.85076C4.5381 6.68406 4.44444 6.45797 4.44444 6.22222V0H2.66667C1.95942 0 1.28115 0.280952 0.781049 0.781049C0.280952 1.28115 0 1.95942 0 2.66667V13.3333C0 14.0406 0.280952 14.7189 0.781049 15.219C1.28115 15.719 1.95942 16 2.66667 16H4.44444V12.4444C4.44444 11.9729 4.63175 11.5208 4.96514 11.1874C5.29854 10.854 5.75073 10.6667 6.22222 10.6667H9.77778C10.2493 10.6667 10.7015 10.854 11.0349 11.1874C11.3683 11.5208 11.5556 11.9729 11.5556 12.4444V16H13.3333C14.0406 16 14.7189 15.719 15.219 15.219C15.719 14.7189 16 14.0406 16 13.3333V6.96C15.9994 6.25299 15.718 5.57517 15.2178 5.07556V5.07556Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSolidSave;

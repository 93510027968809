import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api';
import { URLParams } from '../../../types/params';
import URLParamsConvertor from '../../../utils/URLParamsConvertor';

const name = 'organizationEmployee';

export const initialParams: URLParams = {
  page: 0,
  size: 20,
  sort: [],
  search: '',
};

export const initialState: any = {
  list: [],
  hasMore: true,
  params: initialParams,
  filter: {
    employeeStatuses: [
      { id: 'active', name: 'active', isChecked: false, label: 'Active' },
      {
        id: 'deactivated',
        name: 'deactivated',
        isChecked: false,
        label: 'Deactivated',
      },
      { id: 'invited', name: 'invited', isChecked: false, label: 'Invited' },
    ],
    levelOfExpertise: null,
    skillsWithPriority: [],
  },
  loading: false,
  error: null,
  templateList: [],
  selectedTemplateFilter: {},
  selectedTemplate: null,
  showFilteredSkills: false,
};

export const getAllEmployees = createAsyncThunk(
  `${name}/getAllEmployees`,
  async ({ filter, params }: any) => {
    const response = await api.organization.allEmployees(
      filter,
      URLParamsConvertor(params)
    );
    return response;
  }
);

export const inviteEmployees = createAsyncThunk(
  `${name}/inviteEmployees`,
  async (emails: string[], { rejectWithValue }) => {
    try {
      const response = await api.organization.inviteEmployees({
        employeeEmails: emails,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAllTemplates = createAsyncThunk(
  `${name}/getAllTemplates`,
  async () => {
    const response = await api.organization.allTemplates();
    return response;
  }
);

export const getTemplate = createAsyncThunk(
  `${name}/getTemplate`,
  async (id: string | undefined) => {
    const response = await api.organization.getTemplate(id);
    return response.data;
  }
);

export const createTemplate = createAsyncThunk(
  `${name}/createTemplate`,
  async (template: any, { rejectWithValue }) => {
    try {
      const response = await api.organization.createTemplate(template);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const editTemplate = createAsyncThunk(
  `${name}/editTemplate`,
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await api.organization.editTemplate(params);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteTemplate = createAsyncThunk(
  `${name}/deleteTemplate`,
  async (id: string | undefined) => {
    const response = await api.organization.deleteTemplate(id);
    return response;
  }
);

const OrganizationEmployeeSlice = createSlice({
  name,
  initialState,
  reducers: {
    updateParams: (state, { payload }) => {
      state.params = { ...state.params, [payload.prop]: payload.value };
    },
    resetTable: (state) => {
      state.list = initialState.list;
      state.hasMore = initialState.hasMore;
      state.loading = initialState.loading;
      state.params.page = 0;
      state.showFilteredSkills = false;
    },
    setFilters: (state, { payload }) => {
      state.filter = payload;
    },
    updateFilters: (state, { payload }) => {
      state.filter = { ...state.filter, [payload.prop]: payload.value };
    },
    updateSelectedTemplateFilter: (state, action) => {
      state.selectedTemplateFilter = action.payload;
    },
    updateSelectedTemplate: (state, action) => {
      state.selectedTemplate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllEmployees.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllEmployees.fulfilled, (state, action: any) => {
        state.list = [...state.list, ...action.payload.data.employeePage.content];
        state.hasMore = !action.payload.data.employeePage.last;
        state.loading = false;
        state.showFilteredSkills = action.payload?.data?.showFilteredSkills ? true : false;
      })
      .addCase(getAllEmployees.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
        state.hasMore = false;
        state.showFilteredSkills = false;
      })
      .addCase(inviteEmployees.pending, (state, action) => {
        state.emails = action.meta.arg;
      })
      .addCase(inviteEmployees.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(getAllTemplates.fulfilled, (state, action: any) => {
        state.templateList = action.payload.data;
      })
      .addCase(getAllTemplates.rejected, (state, action) => {
        state.error = action.error;
      });
  },
});

export const {
  updateParams,
  resetTable,
  setFilters,
  updateFilters,
  updateSelectedTemplateFilter,
  updateSelectedTemplate,
} = OrganizationEmployeeSlice.actions;
export const organizationEmployeeSlice = OrganizationEmployeeSlice;

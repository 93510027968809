import React from 'react';
import AssignModal from '../../../../../../components/AssignModal';
import {
  getInitialEmployeeGroupsList,
  searchEmployeeGroups,
} from '../../../../../../store/slices/organizationSkillGroups/organizationSkillGroups';
import { assignSkillsToEmployeeGroups } from '../../../../../../store/slices/organizationSkills/organizationSkills';

interface Props {
  selectedIds: string[];
  assignType: string;
  isDisabled: boolean;
  onSave?: () => void;
}

const AssignSkillsToEmployeeGroupsModal = ({
  selectedIds,
  assignType,
  isDisabled,
  onSave,
}: Props) => {
  return (
    <AssignModal
      selectedIds={selectedIds}
      assignType={assignType}
      assignModalProps={{
        headerTitle: 'Assign employee groups',
        confirmButtonTitle: 'Assign',
        placeholderText: 'Search for employee groups',
        dataTestIdModal: 'organization-assign-skills-to-employee-groups-modal',
        dataTestIdModalOverlay:
          'organization-assign-skills-to-employee-groups-modal-overlay',
        dataTestIdFooterCancelButton:
          'organization-assign-skills-to-employee-groups-modal-footer-cancel-button',
        dataTestIdFooterConfirmButton:
          'organization-assign-skills-to-employee-groups-modal-footer-assign-button',
      }}
      toastMessages={{
        title: {
          success: 'Successfully assigned skills',
          error: 'Failed!',
        },
        description: {
          success: 'Skills were assigned to the selected employee groups.',
          error: 'The assigning skills to employee groups failed.',
        },
      }}
      assignModalLabel="Employee groups"
      assignAutocompleteInputId="organization-assign-skills-to-employee-groups-autocomplete"
      assignSliceAction={assignSkillsToEmployeeGroups}
      assignSearchSliceAction={searchEmployeeGroups}
      assignInitialListSliceAction={getInitialEmployeeGroupsList}
      dataTestIdOpenModalButton="organization-assign-skills-to-employee-groups-button"
      isDisabled={isDisabled}
      onSave={onSave}
      isGroup
    />
  );
};

export default AssignSkillsToEmployeeGroupsModal;

import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { http } from '../../../api';
import { useToast } from '../../../design/hooks/useToast';
import { globalInterceptorErrorHandler } from './errorHandlers';

interface Props {
  children?: ReactNode;
}

function Secure({ children }: Props) {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const [interceptorsStarted, setInterceptorsStarted] = useState(false);

  const startGlobalInterceptors = useCallback(() => {
    setInterceptorsStarted(true);

    const id = http.interceptors.response.use(null, (error) =>
      globalInterceptorErrorHandler(error, dispatch, navigate, toast)
    );

    return id;
  }, [dispatch, navigate, toast]);

  useEffect(() => {
    if (!interceptorsStarted) {
      startGlobalInterceptors();
    }
  }, [interceptorsStarted, startGlobalInterceptors]);

  return <>{interceptorsStarted && children}</>;
}

export default Secure;

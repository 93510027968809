export const selectAll = (isSelected: boolean, array: any[]) => {
  if (isSelected) {
    const checkedItemsList: string[] = [];
    array.forEach((item: any) => checkedItemsList.push(item.id));
    return checkedItemsList;
  } else {
    return [];
  }
};

export const selectItems = (
  id: string,
  e: React.BaseSyntheticEvent,
  checkedItems: any[]
) => {
  const isChecked = e.target.checked;
  let checkedItemsList: string[] = [...checkedItems];
  if (isChecked) {
    checkedItemsList.push(id);
  } else {
    checkedItemsList = checkedItemsList.filter((item) => {
      return item !== id;
    });
  }
  return checkedItemsList;
};

import { Flex, UseRadioGroupReturn } from '@chakra-ui/react';

import { MenuItemCheckboxProps } from '../MenuItemMultiSelect/MenuItemMultiSelect';
import { MenuItemSingle } from './MenuItemSingle';
import { SVGProps } from 'react';

export type MenuItemSingleSelectProps = {
  options: MenuItemCheckboxProps[];
  useRadioGroupReturn: UseRadioGroupReturn;
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  onItemClick?: (
    event:
      | React.MouseEvent<SVGElement, MouseEvent>
      | React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string | undefined,
    label: string,
    isDelete: boolean
  ) => void;
};
export const MenuItemSingleSelect = ({
  options,
  icon,
  onItemClick,
  useRadioGroupReturn,
}: MenuItemSingleSelectProps) => {
  return (
    <Flex flexDirection="column" {...useRadioGroupReturn.getRootProps()}>
      {options.map((option) => {
        const { name, label, id } = option;
        return (
          <MenuItemSingle
            key={name}
            name={name ?? ''}
            label={label ?? ''}
            icon={icon}
            id={id}
            onItemClick={onItemClick}
            {...useRadioGroupReturn.getRadioProps({
              value: name,
            })}
          />
        );
      })}
    </Flex>
  );
};

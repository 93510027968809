import { useCallback, useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Button, Flex, Icon, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../../store/store';
import ModalComponent from '../../../../../../components/Modal';
import { ReactComponent as Edit } from '../../../../../../assets/icons/edit.svg';
import { InputField } from '../../../../../../design/components/InputField/InputField';
import { useToast } from '../../../../../../design/hooks/useToast';
import isMaxLength from '../../../../../../utils/isMaxLength/isMaxLength';
import { AsyncAutocompleteField } from '../../../../../../design/components/AsyncAutocompleteField/AsyncAutocompleteField';
import { debounce, isEqual } from 'lodash';
import {
  getEmployeeGroup,
  searchEmployees,
  resetEmployees,
  resetEmployeeGroup,
  editEmployeeGroup,
  createEmployeeGroup,
  getInitialEmployeeList,
} from '../../../../../../store/slices/organizationEmployeeGroups/organizationEmployeeGroups';
import { colors } from '../../../../../../design/styles/foundations';
import If from '../../../../../../components/If';
import SvgSolidPlus from '../../../../../../design/styles/icons/solid/SolidPlus';
import { LightSearch } from '../../../../../../design/styles/icons/light/';
import { MAX_CHAR_LIMIT_50 as maxLimit } from '../../../../../../constants';
import { LoadOption } from '../../../../../../api/types';

const employeeGroupNameAlreadyExists = 'Employee group already exists!';

interface Props {
  toastMessages: {
    title: {
      success: string;
      error: string;
    };
    description: {
      success: string;
      error: string;
    };
  };
  employeeGroupsModalProps: {
    headerTitle: string;
    confirmButtonTitle: string;
  };
  isEditEmployeeGroup?: boolean;
  id?: string;
  onSave?: () => void;
}

const EmployeeGroupsModal = ({
  toastMessages,
  employeeGroupsModalProps,
  isEditEmployeeGroup = false,
  id,
  onSave,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const userEmail = useSelector((state: RootState) => state.user?.email);
  const employeeGroup = useSelector(
    (state: RootState) => state.organizationEmployeeGroups?.employeeGroup
  );

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false);
  const initialEmployees = useSelector(
    (state: RootState) => state.organizationEmployeeGroups.initialEmployeeList
  );

  const {
    control,
    watch,
    setValue,
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    mode: 'onChange',
  });
  const employeeGroupNameInput = watch('employeeGroupNameInput');
  const employeesAutocompleteInput = watch('employeesAutocompleteInput');

  const clearInputs = useCallback(() => {
    setValue('employeeGroupNameInput', '');
    setValue('employeesAutocompleteInput', []);
    clearErrors(['employeeGroupNameInput', 'employeesAutocompleteInput']);
    dispatch(resetEmployees());
    dispatch(resetEmployeeGroup());
  }, [clearErrors, dispatch, setValue]);

  const handleClose = useCallback(() => {
    setIsModalOpen(false);
    clearInputs();
  }, [clearInputs]);

  const saveActions = useCallback(
    (res: any) => {
      const responseStatus = !res.error ? 'success' : 'error';
      clearInputs();
      toast({
        position: 'top',
        title: toastMessages.title[responseStatus],
        status: responseStatus,
        description: toastMessages.description[responseStatus],
        isClosable: true,
        duration: 7000,
        variant: 'subtle',
      });
    },
    [clearInputs, toastMessages, toast]
  );

  const handleDispatch = useCallback(
    (res: any) => {
      const errors = res?.payload?.response?.data?.errors;
      if (errors?.includes(employeeGroupNameAlreadyExists)) {
        setError('employeeGroupNameInput', {
          message: 'A group with this name already exists',
        });
      } else {
        saveActions(res);
        handleClose();
        if (onSave) {
          onSave();
        }
      }
      setIsSaveButtonDisabled(false);
    },
    [handleClose, onSave, saveActions, setError]
  );

  const handleConfirm = useCallback(() => {
    setIsSaveButtonDisabled(true);
    const params: any = {
      data: {
        employeeGroupName: employeeGroupNameInput?.trim(),
        employeeIds: employeesAutocompleteInput
          ? employeesAutocompleteInput.map(
              (item: { value: string; label: string; id: string }) => item.id
            )
          : [],
      },
      id,
      createdBy: isEditEmployeeGroup ? null : userEmail,
    };

    if (isEditEmployeeGroup) {
      dispatch(editEmployeeGroup(params)).then((res) => handleDispatch(res));
    } else {
      dispatch(createEmployeeGroup(params)).then((res) => handleDispatch(res));
    }
  }, [
    employeeGroupNameInput,
    employeesAutocompleteInput,
    id,
    isEditEmployeeGroup,
    userEmail,
    dispatch,
    handleDispatch,
  ]);

  const handleMapResponse = useCallback((res: any) => {
    return res
      ? res.map((e: any, index: number) => ({
          value: e.name,
          label: (
            <Flex key={e.id} alignItems="center">
              <Avatar
                key={e.id}
                size={'2xs'}
                name={e.name}
                src={e.image}
                marginRight="8px"
              />
              <Text key={e.id + JSON.stringify(index)}>{e.name}</Text>
            </Flex>
          ),
          id: e.id,
        }))
      : [];
  }, []);

  const handleDebounce = debounce((value, cbFunction) => {
    dispatch(searchEmployees(value)).then((res) =>
      cbFunction(handleMapResponse(res?.payload))
    );
  }, 300);

  const handleLoadingOptions = useCallback(
    (inputValue: string, cbFunction: (options: LoadOption[]) => void) => {
      if (inputValue.length > 1) {
        handleDebounce(inputValue, cbFunction);
      } else {
        cbFunction(handleMapResponse(initialEmployees));
      }
    },
    [handleDebounce, handleMapResponse, initialEmployees]
  );

  useEffect(() => {
    return () => {
      clearInputs();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDisabled = useMemo(() => {
    return (
      isSaveButtonDisabled || (
      !employeeGroupNameInput ||
      isMaxLength(employeeGroupNameInput, maxLimit) ||
      (!!id &&
        isEditEmployeeGroup &&
        employeeGroupNameInput?.trim() === employeeGroup?.name?.trim() &&
        isEqual(
          employeesAutocompleteInput?.map((item: any) => item.id).sort(),
          employeeGroup?.employees?.map((item: any) => item.id).sort()
        ))
    ));
  }, [
    employeeGroup?.name,
    employeeGroup?.employees,
    employeeGroupNameInput,
    employeesAutocompleteInput,
    id,
    isEditEmployeeGroup,
    isSaveButtonDisabled
  ]);

  const EmployeeGroupsModalProps = {
    isModalOpen: isModalOpen,
    onCloseFunc: handleClose,
    headerTitle: employeeGroupsModalProps.headerTitle,
    confirmButtonTitle: employeeGroupsModalProps.confirmButtonTitle,
    confirmButtonFunc: handleConfirm,
    isDisabled,
    dataTestIdModal: 'employee-group-modal',
    dataTestIdModalOverlay: 'employee-group-modal-overlay',
    dataTestIdFooterCancelButton: 'employee-group-modal-cancel-button',
    dataTestIdFooterConfirmButton: 'employee-group-modal-confirm-button',
  };

  const openTheModal = useCallback(() => {
    setIsModalOpen(true);
    dispatch(getInitialEmployeeList());
    if (isEditEmployeeGroup && id) {
      dispatch(getEmployeeGroup(id)).then((res: any) => {
        setValue('employeeGroupNameInput', res?.payload?.name);
        setValue(
          'employeesAutocompleteInput',
          handleMapResponse(res?.payload?.employees)
        );
      });
    }
  }, [dispatch, handleMapResponse, id, isEditEmployeeGroup, setValue]);

  const inputArgs = {
    fontSize: 'lg',
    autoComplete: 'off',
  };

  return (
    <>
      <If condition={!!isEditEmployeeGroup}>
        <Icon
          as={Edit}
          w="16px"
          h="16px"
          color={`${colors.greySolid[800]}`}
          _hover={{ color: colors.primary[500], cursor: 'pointer' }}
          className="organization-table-row-edit-icon"
          onClick={openTheModal}
          data-testid={`organization-edit-employee-button-${id}`}
          data-qa={`organization-edit-employee-button-${id}`}
        />
      </If>
      <If condition={!isEditEmployeeGroup}>
        <Button
          leftIcon={<SvgSolidPlus width="20px" height="20px" />}
          colorScheme="primary"
          variant="solid"
          size="md"
          fontWeight="600"
          iconSpacing="4px"
          ml={3}
          onClick={openTheModal}
          data-testid="organization-create-employee-group-button"
          data-qa="organization-create-employee-group-button"
        >
          Create new
        </Button>
      </If>
      <ModalComponent {...EmployeeGroupsModalProps}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center"></Box>
        </Box>
        <Box>
          <InputField
            label="Group name"
            placeholder="e.g. Development team"
            isRequired
            errors={errors}
            {...register('employeeGroupNameInput', {
              required: 'Group name field is required',
              validate: {
                maxLength: (v) =>
                  !isMaxLength(v, maxLimit) ||
                  `Group name cannot exceed more than ${maxLimit} characters`,
              },
            })}
            {...inputArgs}
            data-qa="employee-group-modal-name-input"
            data-testid="employee-group-modal-name-input"
            defaultValue={employeeGroupNameInput}
          />
        </Box>
        <Box mt="24px">
          <AsyncAutocompleteField
            name="employeesAutocompleteInput"
            label="Add employees"
            placeholder={
              <Flex alignItems="center">
                <Icon as={LightSearch} mr="1" fontSize={'16px'} />
                <Text>Search for employees by email</Text>
              </Flex>
            }
            numOfLabels={15}
            isMulti
            options={handleMapResponse(initialEmployees)}
            loadOptions={handleLoadingOptions}
            control={control}
            isClearable={false}
            closeMenuOnSelect
            hideDropdownIndicator
            id="employee-group-modal-employees-autocomplete-input"
            defaultValue={employeesAutocompleteInput}
          />
        </Box>
      </ModalComponent>
    </>
  );
};

export default EmployeeGroupsModal;

import React from 'react';
import { useSelector } from 'react-redux';
import { SkillsTabs } from '../../../../api/types';
import {
  getAllCompletedSkills,
  skillsCompletedSlice,
} from '../../../../store/slices/skillsCompleted/skillsCompleted';
import { RootState } from '../../../../store/store';
import SkillsTable from '../SkillsTable';

function SkillsCompletedTable() {
  const skillsState = useSelector((state: RootState) => state.skillsCompleted);

  return (
    <SkillsTable
      skillsSlice={skillsCompletedSlice}
      getAllSlice={getAllCompletedSkills}
      skillsType={SkillsTabs.Completed}
      skillsState={skillsState}
    />
  );
}

export default SkillsCompletedTable;

import React from 'react';
import AssignModal from '../../../../components/AssignModal';
import { assignSkillGroups } from '../../../../store/slices/organizationEmployeeGroups/organizationEmployeeGroups';
import {
  getInitialEmployeeGroupsList,
  searchEmployeeGroups,
} from '../../../../store/slices/organizationSkillGroups/organizationSkillGroups';

interface Props {
  selectedIds: string[];
  assignType: string;
  isDisabled: boolean;
  isGhostButton: boolean;
  onSave?: () => void;
  getAddedGroups?: any;
}

const AddToEmployeeGroupsModal = ({
  selectedIds,
  assignType,
  isDisabled,
  isGhostButton,
  onSave,
  getAddedGroups,
}: Props) => {
  return (
    <AssignModal
      selectedIds={selectedIds}
      assignType={assignType}
      toastMessages={{
        title: {
          success: 'Successfully assigned employee groups',
          error: 'Failed!',
        },
        description: {
          success:
            'Employee groups were assigned to the selected skill groups.',
          error: 'The assigning an employee group to skill groups failed.',
        },
      }}
      assignModalProps={{
        headerTitle: 'Add group',
        confirmButtonTitle: 'Save',
        placeholderText: 'Search for employee groups',
        dataTestIdModal: 'add-employee-groups-modal',
        dataTestIdModalOverlay: 'add-employee-groups-modal-overlay',
        dataTestIdFooterCancelButton: 'add-employee-groups-modal-cancel-button',
        dataTestIdFooterConfirmButton:
          'add-employee-groups-modal-confirm-button',
      }}
      assignModalLabel="Employee groups"
      assignAutocompleteInputId="add-employee-groups-to-employee-groups-autocomplete"
      assignSliceAction={assignSkillGroups}
      assignSearchSliceAction={searchEmployeeGroups}
      assignInitialListSliceAction={getInitialEmployeeGroupsList}
      dataTestIdOpenModalButton="add-employee-groups-to-employee-button"
      isDisabled={isDisabled}
      isGhostButton={isGhostButton}
      onSave={onSave}
      getAddedGroups={getAddedGroups}
      isGroup
    />
  );
};

export default AddToEmployeeGroupsModal;

import { useCallback, useMemo, useState } from 'react';
import { Avatar, chakra, Flex, Icon, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import ModalComponent from '../Modal';
import { useToast } from '../../design/hooks/useToast';
import { Button } from '../../design/components/Button/Button';
import { AsyncAutocompleteField } from '../../design/components/AsyncAutocompleteField/AsyncAutocompleteField';
import { LightSearch } from '../../design/styles/icons/light';
import { useForm } from 'react-hook-form';
import { debounce, isEqual } from 'lodash';
import { ReactComponent as PersonIcon } from '../../assets/icons/person.svg';
import { ReactComponent as PersonsIcon } from '../../assets/icons/persons.svg';
import { SimpleEmployee, SimpleSkill } from '../../api/types';
import SvgSolidPlus from '../../design/styles/icons/solid/SolidPlus';

interface Props {
  selectedIds: string[];
  assignType: string;
  toastMessages: {
    title: {
      success: string;
      error: string;
    };
    description: {
      success: string;
      error: string;
    };
  };
  assignModalProps: {
    headerTitle: string;
    confirmButtonTitle: string;
    placeholderText: string;
    dataTestIdModal: string;
    dataTestIdModalOverlay: string;
    dataTestIdFooterCancelButton: string;
    dataTestIdFooterConfirmButton: string;
  };
  assignModalLabel: string;
  assignModalTooltipText?: string;
  assignAutocompleteInputId: string;
  assignSliceAction: any;
  assignSearchSliceAction: any;
  assignInitialListSliceAction: any;
  dataTestIdOpenModalButton: string;
  isDisabled: boolean;
  isGhostButton?: boolean;
  onSave?: () => void;
  getAddedGroups?: any;
  isGroup?: boolean;
}

const AssignModal = ({
  selectedIds,
  assignType,
  toastMessages,
  assignModalProps,
  assignModalLabel,
  assignModalTooltipText,
  assignAutocompleteInputId,
  assignSliceAction,
  assignSearchSliceAction,
  assignInitialListSliceAction,
  dataTestIdOpenModalButton,
  isDisabled,
  isGhostButton,
  onSave,
  getAddedGroups,
  isGroup,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const profile = useSelector((state: RootState) => state.user?.profile);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [initialList, setInitialList] = useState<any>();
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false);

  const { control, watch, reset } = useForm<any>({
    mode: 'onChange',
  });

  const autocompleteInput = watch('autocompleteInput');

  const openTheModal = useCallback(() => {
    setIsModalOpen(true);
    dispatch(assignInitialListSliceAction()).then((res: any) =>
      setInitialList(res.payload)
    );
  }, [assignInitialListSliceAction, dispatch]);

  const handleClose = useCallback(() => {
    setIsModalOpen(false);
    reset();
  }, [reset]);

  const getAssignTypeData = useCallback(
    (assignType: string) => {
      switch (assignType) {
        case 'skills':
          return {
            skillIds: autocompleteInput.map(
              (item: { value: string; label: string; id: string }) => item.id
            ),
            employeeGroupIds: selectedIds,
          };
        case 'skillGroupsToEmployeeGroups':
          return {
            skillGroupIds: autocompleteInput.map(
              (item: { value: string; label: string; id: string }) => item.id
            ),
            employeeGroupIds: selectedIds,
          };
        case 'employeeGroupsToSkillGroups':
          return {
            employeeGroupIds: autocompleteInput.map(
              (item: { value: string; label: string; id: string }) => item.id
            ),
            skillGroupIds: selectedIds,
          };
        case 'employee':
          return {
            skillGroupIds: selectedIds,
            employeeIds: autocompleteInput.map(
              (item: { value: string; label: string; id: string }) => item.id
            ),
          };
        case 'skillsToEmployee':
          return {
            skillIds: selectedIds,
            employeeIds: autocompleteInput.map(
              (item: { value: string; label: string; id: string }) => item.id
            ),
          };
        case 'skillsToEmployeeGroups':
          return {
            skillIds: selectedIds,
            employeeGroupIds: autocompleteInput.map(
              (item: { value: string; label: string; id: string }) => item.id
            ),
          };
        case 'addToGroup':
          return {
            addedEmployeeGroups: autocompleteInput.map(
              (item: { value: string; label: string; id: string }) => {
                return { id: item.id, name: item.value };
              }
            ),
          };
      }
    },
    [autocompleteInput, selectedIds]
  );

  const confirmAction = useCallback(
    (res: any) => {
      reset();
      const responseStatus = !res.error ? 'success' : 'error';
      toast({
        position: 'top',
        title: toastMessages.title[responseStatus],
        status: responseStatus,
        description: toastMessages.description[responseStatus],
        isClosable: true,
        duration: 7000,
        variant: 'subtle',
      });
    },
    [reset, toast, toastMessages.description, toastMessages.title]
  );

  const handleAssign = useCallback(() => {
    setIsSaveButtonDisabled(true);
    dispatch(assignSliceAction(getAssignTypeData(assignType))).then(
      (res: any) => {
        confirmAction(res);
        handleClose();
        if (onSave) {
          onSave();
        }
        setIsSaveButtonDisabled(false);
      }
    );
  }, [
    getAssignTypeData,
    assignType,
    dispatch,
    assignSliceAction,
    confirmAction,
    handleClose,
    onSave,
  ]);

  const handleAddToGroup = () => {
    getAssignTypeData(assignType);
    getAddedGroups(getAssignTypeData(assignType));
    handleClose();
  };

  const handleAssignMapResponse = useCallback(
    (res: SimpleEmployee[] | SimpleSkill[]) => {
      return res
        ? res.map((e: any, index: number) => ({
            value: e.name,
            label:
              assignType !== 'employee' && assignType !== 'skillsToEmployee' ? (
                e.name
              ) : (
                <Flex key={e.id} alignItems="center">
                  <Avatar
                    key={e.id}
                    size={'2xs'}
                    name={e.name}
                    src={e.image}
                    marginRight="8px"
                  />
                  <Text key={e.id + JSON.stringify(index)}>{e.name}</Text>
                </Flex>
              ),
            id: e.id,
          }))
        : [];
    },
    [assignType]
  );

  const handleDebounce = debounce((value, cbFunction) => {
    dispatch(assignSearchSliceAction(value)).then((res: any) =>
      cbFunction(handleAssignMapResponse(res?.payload))
    );
  }, 300);

  const handleAssignLoadingOptions = useCallback(
    (inputValue: string, cbFunction: (options: any) => void) => {
      if (assignType === 'skills') {
        handleDebounce(inputValue, cbFunction);
      } else {
        if (inputValue.length > 1) {
          handleDebounce(inputValue, cbFunction);
        } else {
          cbFunction(handleAssignMapResponse(initialList));
        }
      }
    },
    [assignType, handleDebounce, handleAssignMapResponse, initialList]
  );

  const isDisabledConfirmButton = useMemo(() => {
    if (isSaveButtonDisabled) { 
      return isSaveButtonDisabled;
    }
    if (assignType === 'addToGroup') {
      return (
        typeof autocompleteInput === 'undefined' ||
        isEqual(
          autocompleteInput?.map((item: any) => item.id).sort(),
          profile.employeeGroups?.map((item: any) => item.id).sort()
        )
      );
    } else {
      return !autocompleteInput?.length;
    }
  }, [assignType, autocompleteInput, profile?.employeeGroups, isSaveButtonDisabled]);

  const isAssign = () => {
    if (isGhostButton) {
      return SvgSolidPlus;
    } else {
      if (isGroup) {
        return chakra(PersonsIcon);
      } else {
        return chakra(PersonIcon);
      }
    }
  };

  const AssignModalProps = {
    isModalOpen: isModalOpen,
    onCloseFunc: handleClose,
    headerTitle: assignModalProps.headerTitle,
    placeholderText: assignModalProps.placeholderText,
    confirmButtonTitle: assignModalProps.confirmButtonTitle,
    confirmButtonFunc:
      assignType === 'addToGroup' ? handleAddToGroup : handleAssign,
    isDisabled: isDisabledConfirmButton,
    dataTestIdModal: assignModalProps.dataTestIdModal,
    dataTestIdModalOverlay: assignModalProps.dataTestIdModalOverlay,
    dataTestIdFooterCancelButton: assignModalProps.dataTestIdFooterCancelButton,
    dataTestIdFooterConfirmButton:
      assignModalProps.dataTestIdFooterConfirmButton,
  };

  return (
    <>
      <Button
        pr="16px"
        py="9px"
        size="lg"
        isDisabled={isDisabled}
        leftIcon={isAssign()}
        onClick={openTheModal}
        data-testid={dataTestIdOpenModalButton}
        data-qa={dataTestIdOpenModalButton}
        variant={isGhostButton ? 'ghost' : 'solid'}
      >
        {AssignModalProps.headerTitle}
      </Button>
      <ModalComponent {...AssignModalProps}>
        <AsyncAutocompleteField
          name="autocompleteInput"
          label={assignModalLabel}
          placeholder={
            <Flex alignItems="center">
              <Icon as={LightSearch} mr="1" fontSize={'16px'} />
              <Text>{AssignModalProps.placeholderText}</Text>
            </Flex>
          }
          numOfLabels={15}
          isMulti
          options={handleAssignMapResponse(initialList)}
          loadOptions={handleAssignLoadingOptions}
          control={control}
          isClearable={false}
          closeMenuOnSelect
          hideDropdownIndicator
          id={assignAutocompleteInputId}
          info={assignModalTooltipText ? assignModalTooltipText : ''}
          defaultValue={
            assignType === 'addToGroup'
              ? profile.employeeGroups.map((group: any) => {
                  return {
                    label: group.name,
                    value: group.name,
                    id: group.id,
                  };
                })
              : []
          }
        />
      </ModalComponent>
    </>
  );
};

export default AssignModal;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Icon, Tooltip } from '@chakra-ui/react';
import SvgLightStar from '../../design/styles/icons/light/LightStar';
import SvgSolidStar from '../../design/styles/icons/solid/SolidStar';
import { colors } from '../../design/styles/foundations';
import { Skill } from '../../api/types';
interface Props {
  skills: Skill[];
  skill: Skill;
  index: number;
  dataTestId: string;
  dataQa: string;
  assessmentValue?: number[];
  setAssessment?: (value: number[]) => void;
  showByIndex?: boolean;
  isFromButton?: boolean;
  isClickedItem?: boolean;
}
function AssessmentStars({
  skills,
  skill,
  index,
  dataTestId,
  dataQa,
  assessmentValue,
  setAssessment,
  showByIndex,
  isFromButton,
  isClickedItem,
}: Props) {
  const handleLevelOfExpertise = (levelOfExpertise: number | null) => {
    return levelOfExpertise !== null ? levelOfExpertise : 0;
  };

  const [ratings, setRatings] = useState(
    skills.map((skill: Skill) => handleLevelOfExpertise(skill.levelOfExpertise))
  );

  const currentIndex = useMemo(() => {
    return showByIndex ? index : 0;
  }, [showByIndex, index]);

  useEffect(() => {
    const ratings = assessmentValue
      ? assessmentValue
      : skills.map((skill: Skill) =>
          handleLevelOfExpertise(skill.levelOfExpertise)
        );
    setRatings(ratings);
  }, [assessmentValue, showByIndex, skill.levelOfExpertise, skills]);

  const updateHoverRating = (index: number, score: number) => {
    setRatings((prevState: number[]) => {
      const nextRatings = prevState.map((r, i) => {
        if (i === index) {
          return score;
        } else {
          return r;
        }
      });
      return nextRatings;
    });
  };

  const handleStarMouseEnter = useCallback(
    (e: React.SyntheticEvent<EventTarget>) => {
      if (!(e.target instanceof HTMLDivElement)) {
        return;
      }
      if (
        typeof e.target.dataset.index === 'undefined' ||
        typeof e.target.dataset.starindex === 'undefined'
      ) {
        return;
      }
      const starIndex = parseInt(e.target.dataset.starindex);
      updateHoverRating(currentIndex, starIndex + 1);
    },
    [currentIndex]
  );

  const handleStarMouseLeave = useCallback(
    (e: React.SyntheticEvent<EventTarget>) => {
      if (!(e.target instanceof HTMLDivElement)) {
        return;
      }
      if (
        typeof e.target.dataset.index === 'undefined' ||
        typeof e.target.dataset.expertiselevel === 'undefined'
      ) {
        return;
      }
      const levelOfExpertise = parseInt(e.target.dataset.expertiselevel);
      const rating = assessmentValue?.length
        ? assessmentValue[currentIndex]
        : levelOfExpertise;
      updateHoverRating(currentIndex, rating);
    },
    [assessmentValue, currentIndex]
  );

  const handleOnClick = useCallback(() => {
    if (ratings.length && setAssessment) {
      setAssessment(showByIndex ? [ratings[index]] : ratings);
    }
  }, [index, ratings, setAssessment, showByIndex]);

  const handleStarsColor = () => {
    if (
      handleLevelOfExpertise(skill.levelOfExpertise) === 0 &&
      ratings[currentIndex] < 1
    ) {
      return colors.primary[300];
    } else if (!isFromButton) {
      return colors.primary[500];
    } else if (isClickedItem) {
      return colors.primary[500];
    } else {
      return colors.primary[300];
    }
  };

  const getTooltipExpertise = useCallback((currentStar: number) => {
    switch (currentStar) {
      case 1:
        return '1. I know nothing about this.';
      case 2:
        return '2. I have informal knowledge of it. (Ex: I know what it is about and what is the purpose of it I know what kind of problem it solves)';
      case 3:
        return '3. I have a shallow knowledge about it. (Ex: I have done some hands-on exercises/tutorials with it, but I have never used it in a real project or day to day tasks)';
      case 4:
        return '4. I have moderate-decent knowledge about it. (Ex: I have used it in projects and I am able to complete mid-complexity tasks with it)';
      case 5:
        return '5. I am an expert. (Ex: I have used it in projects and I am able to complete tasks of any complexity with it.)';
    }
  }, []);

  return (
    <>
      <Box display="flex">
        {[
          'firstStar',
          'secondStar',
          'thirdStar',
          'fourthStar',
          'fifthStar',
        ].map((val, starIndex) => {
          return (
            <Tooltip
              label={getTooltipExpertise(starIndex + 1)}
              fontSize="md"
              placement="top"
              key={val}
            >
              <Box
                data-testid={`${dataTestId}-${skill.name}-${starIndex}`}
                data-qa={`${dataQa}-${skill.name}-${starIndex}`}
                data-index={index}
                data-starindex={starIndex}
                data-expertiselevel={handleLevelOfExpertise(
                  skill.levelOfExpertise
                )}
                onMouseEnter={handleStarMouseEnter}
                onMouseLeave={handleStarMouseLeave}
                onClick={handleOnClick}
                cursor="pointer"
              >
                <Icon
                  as={
                    starIndex + 1 > ratings[currentIndex] || starIndex === -1
                      ? SvgLightStar
                      : SvgSolidStar
                  }
                  boxSize={7}
                  color={handleStarsColor()}
                  pointerEvents="none"
                />
              </Box>
            </Tooltip>
          );
        })}
      </Box>
    </>
  );
}

export default AssessmentStars;

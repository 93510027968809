/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Box, Icon } from '@chakra-ui/react';
import styles from './CancellablePill.module.scss';
import cancel from '../../../design/styles/icons/regular/RegularClose';

type Props = {
  name: string;
  selections: string[];
  onRemove: () => void;
};

const CancellablePill = ({ name, selections, onRemove }: Props) => {
  return (
    <Box className={styles.container}>
      <Box as="span">
        <Box as="span" className={styles.name} data-testid={`chip-${name}`}>
          {name}:
        </Box>
        {selections.map(
          (selection, index: number) =>
            `${selection}${selections.length - 1 !== index ? ', ' : ''}`
        )}
      </Box>
      <Icon
        fontSize="13px"
        className={styles.cancel}
        as={cancel}
        onClick={() => onRemove()}
        data-testid={`chip-${name}-close`}
      />
    </Box>
  );
};

export default CancellablePill;

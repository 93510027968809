import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Tr,
  Td,
  Tag,
  TagLabel,
  AvatarGroup,
  Avatar,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { Checkbox } from '../../../../design/components/Checkbox/Checkbox';
import Table from '../../../../components/Wrappers/Table';
import { Column } from '../../../../components/Wrappers/Table/Table';
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll';
import { URLParams } from '../../../../types/params';
import {
  getAllSkills,
  organizationSkillsSlice,
} from '../../../../store/slices/organizationSkills/organizationSkills';
import { AppDispatch, RootState } from '../../../../store/store';
import { OrganizationSkill, SimpleEmployee } from '../../../../api/types';
import { colors } from '../../../../design/styles/foundations/colors';
import '../../../../assets/styles/main.scss';
import showMoreIndicator from '../../../../utils/showMoreIndicator/showMoreIndicator';
import If from '../../../../components/If';
import { selectAll, selectItems } from '../../../../utils/checkboxGroupActions';
import DeleteSkillModal from './components/DeleteSkillModal';
import AssignSkillsToEmployeeModal from './components/AssignSkillsToEmployeeModal/AssignSkillsToEmployeeModal';
import EditSkillModal from './components/EditSkillModal';
import AssignSkillsToEmployeeGroupsModal from './components/AssignSkillsToEmployeeGroupsModal/AssignSkillsToEmployeeGroupsModal';
import SearchInput from '../../../../components/SearchInput';
import { debounce } from 'lodash';


const HEADER_STYLE: React.CSSProperties = {
  top: '0px',
};

const TABLE_PADDING = 20;

const TABLE_COLUMNS_SKILLS: Column[] = [
  {
    name: 'Name',
    sortValue: 'name',
    style: HEADER_STYLE,
  },
  {
    name: 'Skill group',
    sortValue: '',
    style: HEADER_STYLE,
    disableSort: true,
  },
  {
    name: 'Completed by',
    sortValue: '',
    style: HEADER_STYLE,
    disableSort: true,
  },
  {
    name: '',
    sortValue: '',
    style: HEADER_STYLE,
    disableSort: true,
  },
];

function SkillsTable() {
  const dispatch = useDispatch<AppDispatch>();
  const skills = useSelector(
    (state: RootState) => state.organizationSkills.list
  );
  const hasMore = useSelector(
    (state: RootState) => state.organizationSkills.hasMore
  );
  const loading = useSelector<RootState, boolean>(
    (state: RootState) => state.organizationSkills.loading
  );
  const params = useSelector<RootState, URLParams>(
    (state: RootState) => state.organizationSkills.params
  );
  const containerRef: any = useRef<HTMLHtmlElement>();
  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  const handleCall = useCallback(
    ({ page }: any) => {
      dispatch(getAllSkills(params));
      dispatch(
        organizationSkillsSlice.actions.updateParams({
          prop: 'page',
          value: page + 1,
        })
      );
    },
    [dispatch, params]
  );

  const handleSort = useCallback(
    (srt: any) => {
      dispatch(
        organizationSkillsSlice.actions.updateParams({
          prop: 'sort',
          value: srt,
        })
      );
      dispatch(organizationSkillsSlice.actions.resetTable());
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch(organizationSkillsSlice.actions.resetTable());
    };
  }, [dispatch]);

  const { Trigger } = useInfiniteScroll({
    rows: skills,
    next: handleCall,
    container: containerRef.current,
    hasMore,
    loading,
    page: params.page,
    size: params.size,
    sort: params.sort,
  });

  const tableHeight = useCallback(() => {
    return (
      containerRef?.current?.getBoundingClientRect().top + TABLE_PADDING || 0
    );
  }, []);

  const handleSelectAllOrganizationSkills = useCallback(
    (isSelected: boolean) => {
      const checkedItemsList = selectAll(isSelected, skills);
      setCheckedItems(checkedItemsList);
    },
    [skills]
  );

  const handleSelectOrganizationSkills = useCallback(
    (id: string, e: React.BaseSyntheticEvent) => {
      const checkedItemsList = selectItems(id, e, checkedItems);
      setCheckedItems(checkedItemsList);
    },
    [checkedItems]
  );

  const handleDebounce = debounce((value) => {
    dispatch(
      organizationSkillsSlice.actions.updateParams({
        prop: 'search',
        value,
      })
    );
    dispatch(organizationSkillsSlice.actions.resetTable());
  }, 300);

  const handleSearch = useCallback(
    (value: string) => {
      handleDebounce(value);
    },
    [handleDebounce]
  );

  const renderWrapItemSkillGroup = useCallback((skillGroup: string) => {
    return (
      <WrapItem key={skillGroup}>
        <Tag
          color={colors.primary[800]}
          backgroundColor={colors.primary[200]}
          borderRadius="3px"
          px={2}
          py="3px"
          fontWeight="400"
        >
          <TagLabel>{skillGroup}</TagLabel>
        </Tag>
      </WrapItem>
    );
  }, []);

  const renderWrapItemCompletedBy = useCallback((employee: SimpleEmployee) => {
    return (
      <Avatar
        key={employee.name}
        name={employee.name}
        src={employee.image}
        w="32px"
        h="32px"
        showBorder
      />
    );
  }, []);

  const reloadTable = useCallback(() => {
    dispatch(organizationSkillsSlice.actions.resetTable());
    const newParams = { ...params, page: 0 };
    dispatch(getAllSkills(newParams));
    dispatch(
      organizationSkillsSlice.actions.updateParams({
        prop: 'page',
        value: 1,
      })
    );
  }, [dispatch, params]);

  const renderRow = useCallback(
    (row: OrganizationSkill, index: number) => {
      return (
        <Tr
          key={row.id + index}
          data-testid="organization-skills-table-row"
          data-qa="organization-skills-table-row"
          className="organization-table-row"
          bgColor={checkedItems.includes(row.id) ? colors.primary[100] : ''}
        >
          <Td width="0" pr="0" mr="0">
            <Checkbox
              isChecked={checkedItems.includes(row.id)}
              onChange={(e) => handleSelectOrganizationSkills(row.id, e)}
              data-testid={`organization-skills-table-row-checkbox-${row.id}`}
              data-qa={`organization-skills-table-row-checkbox-${row.id}`}
              className="chkbox-shadow"
            />
          </Td>
          <Td width="104px">{row.name}</Td>
          <Td
            width="350px"
            id={`organization-skills-table-row-skill-groups-${index}`}
          >
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Wrap height="24px">
                {row.skillGroups?.map((skillGroup: string) => {
                  return renderWrapItemSkillGroup(skillGroup);
                })}
              </Wrap>
              <Box ml="5px">
                <Text
                  color={colors.primary[800]}
                  fontSize="sm"
                  fontWeight="semibold"
                >
                  {showMoreIndicator(
                    index,
                    'organization-skills-table-row-skill-groups',
                    row.totalSkillGroups
                  )}
                </Text>
              </Box>
            </Box>
          </Td>
          <Td width="150px">
            <Box display="flex" alignItems="center">
              <AvatarGroup
                size="sm"
                max={15}
                className="organization-table-row-avatar-group-number"
              >
                {row.completedBy?.map((employee: SimpleEmployee) => {
                  return renderWrapItemCompletedBy(employee);
                })}
              </AvatarGroup>
              <If condition={row.completedByTotal > 0}>
                <Box ml="12px">
                  <Text
                    color={colors.primary[500]}
                    fontSize="sm"
                    fontWeight="semibold"
                  >
                    {row.completedByTotal}{' '}
                    {row.completedByTotal > 1 ? 'employees' : 'employee'}
                  </Text>
                </Box>
              </If>
            </Box>
          </Td>
          <Td width="20px" className="skills">
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <EditSkillModal id={row.id} onSave={reloadTable} />
              <DeleteSkillModal id={row.id} onDelete={reloadTable} />
            </Box>
          </Td>
        </Tr>
      );
    },
    [
      checkedItems,
      handleSelectOrganizationSkills,
      reloadTable,
      renderWrapItemCompletedBy,
      renderWrapItemSkillGroup,
    ]
  );

  return (
    <>
      <Box display="flex" px="25px" mt="25px" mb="30px">
        <SearchInput
          placeholder="Search a skill by name"
          defaultValue={params.search}
          handleSearch={handleSearch}
          dataTestId="organization-skills-table-search-input"
          dataQa="organization-skills-table-search-input"
        />
      </Box>
      <Box
        px="25px"
        overflow="auto"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height={`calc(100vh - ${tableHeight()}px)`}
        ref={containerRef}
      >
        <Table
          data-testid="organization-skills-table"
          data-qa="organization-skills-table"
          columns={TABLE_COLUMNS_SKILLS}
          rows={skills}
          sort={params.sort}
          onSort={handleSort}
          Trigger={Trigger}
          stickyHeader
          showCheckbox
          isCheckboxDisabled={!skills.length}
          isSelectedAll={
            checkedItems.length === skills.length && skills.length > 0
          }
          onSelectAll={handleSelectAllOrganizationSkills}
          renderRow={renderRow}
        />
        <Box display="flex" className="organization-table-row-bottom">
          <AssignSkillsToEmployeeModal
            selectedIds={checkedItems}
            assignType={'skillsToEmployee'}
            isDisabled={
              !skills.length || Boolean(skills.length && !checkedItems.length)
            }
            onSave={() => setCheckedItems([])}
          />
          <Box ml="8px">
            <AssignSkillsToEmployeeGroupsModal
              selectedIds={checkedItems}
              assignType="skillsToEmployeeGroups"
              isDisabled={
                !skills.length || Boolean(skills.length && !checkedItems.length)
              }
              onSave={() => setCheckedItems([])}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SkillsTable;

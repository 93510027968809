import React from 'react';
import { deleteSkillGroup } from '../../../../../../store/slices/organizationSkillGroups/organizationSkillGroups';
import DeleteModal from '../../../../../../components/DeleteModal';

interface Props {
  id: string;
  onDelete?: () => void;
}

const DeleteSkillGroupsModal = ({ id, onDelete }: Props) => {
  return (
    <DeleteModal
      id={id}
      toastMessages={{
        title: {
          success: 'Successfully deleted a skill group',
          error: 'Failed!',
        },
        description: {
          success: 'A skill group was deleted.',
          error: 'The deletion of a skill group failed.',
        },
      }}
      deleteModalProps={{
        headerTitle: 'Delete skill group',
        confirmButtonTitle: 'Delete',
        dataTestIdModal: 'organization-delete-skill-group-modal',
        dataTestIdModalOverlay: 'organization-delete-skill-group-modal-overlay',
        dataTestIdFooterCancelButton:
          'organization-delete-skill-group-modal-footer-cancel-button',
        dataTestIdFooterConfirmButton:
          'organization-delete-skill-group-modal-footer-delete-button',
      }}
      deleteModalBodyText="Are you sure you want to delete skill group?"
      deleteModalBodyAdditionalText="* Deleting cannot be undone and all data within will be lost."
      deleteIconClassName="organization-table-row-delete-icon"
      deleteIconTestId="organization-delete-skill-group-button"
      deleteSliceAction={deleteSkillGroup}
      onDelete={onDelete}
    />
  );
};

export default DeleteSkillGroupsModal;

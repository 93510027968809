export default function formatFilter(filterObject: any) {
  return {
    employeeStatuses: filterObject.employeeStatuses
      .filter((item: any) => item.isChecked === true)
      .map((obj: any) => obj.name?.toUpperCase()),
    levelOfExpertise: filterObject.levelOfExpertise
      ? Number(
          filterObject.levelOfExpertise.substring(
            filterObject.levelOfExpertise.length - 1
          )
        )
      : null,
    skillsWithPriority: filterObject.skillsWithPriority.map(
      (skill: any) => skill.id
    ),
  };
}

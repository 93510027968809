import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Tr,
  Td,
  Tag,
  Flex,
  Avatar,
  Text,
  Wrap,
  WrapItem,
  Tooltip,
  Icon,
} from '@chakra-ui/react';
import Table from '../../../../components/Wrappers/Table';
import { Column } from '../../../../components/Wrappers/Table/Table';
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll';
import { URLParams } from '../../../../types/params';
import {
  getAllEmployees,
  getAllTemplates,
  organizationEmployeeSlice,
} from '../../../../store/slices/organizationEmployees/organizationEmployees';
import { AppDispatch, RootState } from '../../../../store/store';
import { Employee } from '../../../../api/types';
import { colors } from '../../../../design/styles/foundations/colors';
import SvgSolidStar from '../../../../design/styles/icons/solid/SolidStar';
import SvgRegularStar from '../../../../design/styles/icons/regular/RegularStar';
import '../../../../assets/styles/main.scss';
import If from '../../../../components/If';
import { Status } from '../../../../design/components/Status/Status';
import showMoreIndicator from '../../../../utils/showMoreIndicator';
import { Link } from 'react-router-dom';
import SearchInput from '../../../../components/SearchInput';
import { debounce } from 'lodash';
import Filter from '../../../../components/Filter';
import formatFilter from '../../../../utils/formatFilter';

const HEADER_STYLE: React.CSSProperties = {
  top: '0px',
};

const TABLE_PADDING = 20;

function EmployeesTable() {
  const dispatch = useDispatch<AppDispatch>();
  const employees = useSelector(
    (state: RootState) => state.organizationEmployees.list
  );
  const showFilteredSkills = useSelector(
    (state: RootState) => state.organizationEmployees.showFilteredSkills
  );
  const hasMore = useSelector(
    (state: RootState) => state.organizationEmployees.hasMore
  );
  const loading = useSelector<RootState, boolean>(
    (state: RootState) => state.organizationEmployees.loading
  );
  const params = useSelector<RootState, URLParams>(
    (state: RootState) => state.organizationEmployees.params
  );
  const filter = useSelector<RootState, any>(
    (state: RootState) => state.organizationEmployees.filter
  );
  const containerRef: any = useRef<HTMLHtmlElement>();

  const TABLE_COLUMNS_EMPLOYEES: Column[] = showFilteredSkills ? [
    {
      name: 'Employee',
      sortValue: 'email',
      style: HEADER_STYLE,
    },
    {
      name: 'Targeted skills',
      sortValue: '',
      style: HEADER_STYLE,
      disableSort: true,
    },
    {
      name: 'Status',
      sortValue: 'employeeStatus',
      style: HEADER_STYLE,
    },
  ] : [
    {
      name: 'Employee',
      sortValue: 'email',
      style: HEADER_STYLE,
    },
    {
      name: 'Completed skills',
      sortValue: '',
      style: HEADER_STYLE,
      disableSort: true,
    },
    {
      name: 'Pending skills',
      sortValue: 'pendingSkills',
      style: HEADER_STYLE,
      disableSort: true,
    },
    {
      name: 'Status',
      sortValue: 'employeeStatus',
      style: HEADER_STYLE,
    },
  ];

  const handleCall = useCallback(
    ({ page }: any) => {
      dispatch(getAllEmployees({ filter: formatFilter(filter), params }));
      dispatch(getAllTemplates());
      dispatch(
        organizationEmployeeSlice.actions.updateParams({
          prop: 'page',
          value: page + 1,
        })
      );
    },
    [dispatch, filter, params]
  );

  const handleSort = useCallback(
    (srt: any) => {
      dispatch(
        organizationEmployeeSlice.actions.updateParams({
          prop: 'sort',
          value: srt,
        })
      );
      dispatch(organizationEmployeeSlice.actions.resetTable());
    },
    [dispatch]
  );

  const handleDebounce = debounce((value) => {
    dispatch(
      organizationEmployeeSlice.actions.updateParams({
        prop: 'search',
        value,
      })
    );
    dispatch(organizationEmployeeSlice.actions.resetTable());
  }, 300);

  const handleSearch = useCallback(
    (value: string) => {
      handleDebounce(value);
    },
    [handleDebounce]
  );

  useEffect(() => {
    return () => {
      dispatch(organizationEmployeeSlice.actions.resetTable());
    };
  }, [dispatch]);

  const { Trigger } = useInfiniteScroll({
    rows: employees,
    next: handleCall,
    container: containerRef.current,
    hasMore,
    loading,
    page: params.page,
    size: params.size,
    sort: params.sort,
  });

  const tableHeight = useCallback(() => {
    return (
      containerRef?.current?.getBoundingClientRect().top + TABLE_PADDING || 0
    );
  }, []);

  const employeeStatus = (status: string) => {
    switch (status) {
      case 'ACTIVE':
        return 'done';
      case 'INVITED':
        return 'pending';
      case 'DEACTIVATED':
        return 'neutral';
    }
    return 'error';
  };

  const skillTagBgColor = useCallback(
    (rowStatus: string, skillType: string) => {
      if (rowStatus === 'DEACTIVATED') {
        return colors.greySolid[300];
      } else if (skillType === 'completed') {
        return colors.green[300];
      } else {
        return colors.yellow[300];
      }
    },
    []
  );

  const skillTagColor = useCallback((rowStatus: string, skillType: string) => {
    if (rowStatus === 'DEACTIVATED') {
      return colors.greySolid[600];
    } else if (skillType === 'completed') {
      return colors.green[700];
    } else {
      return colors.yellow[700];
    }
  }, []);

  const renderWrapItem = useCallback(
    (skill: any, row: any, skillType: string) => {
      return (
        <WrapItem key={skill.name}>
          <Tooltip
            p="2"
            borderRadius="6px"
            placement="top"
            colorScheme={colors.greySolid[900]}
            label={
              <Box display="flex" flexDirection={'column'}>
                <Box display="flex" alignItems="center">
                  {[
                    `${skillType}1st`,
                    `${skillType}2nd`,
                    `${skillType}3rd`,
                    `${skillType}4th`,
                    `${skillType}5th`,
                  ].map((val, i) => (
                    <Icon
                      boxSize={7}
                      as={i < skill.assessment ? SvgSolidStar : SvgRegularStar}
                      key={val}
                      color={`${colors.primary[500]}`}
                    />
                  ))}
                </Box>
                <Text p={'1'}>{skill.comment}</Text>
              </Box>
            }
          >
            <Tag
              variant="subtle"
              bg={skillTagBgColor(row.status, skillType)}
              color={skillTagColor(row.status, skillType)}
              p="4px 8px"
              borderRadius="3px"
              fontSize="sm"
              fontWeight="normal"
            >
              {skill.name}
            </Tag>
          </Tooltip>
        </WrapItem>
      );
    },
    [skillTagBgColor, skillTagColor]
  );

  const renderRow = useCallback(
    (row: Employee, index: number) => {
      return (
        <Tr
          key={index}
          data-testid="organization-employees-table-row"
          data-qa="organization-employees-table-row"
          height="80px"
          className="organization-table-row"
        >
          <Td width="280px">
            <Flex alignItems="center">
              <Avatar
                src={row.image}
                name={`${row.firstName} ${row.lastName}`}
                w="32px"
                h="32px"
              />
              <Box
                ml="12px"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <If condition={row.status === 'ACTIVE'}>
                  <Link
                    to={`/employees/${row.id}/profile`}
                    data-testid={`organization-employee-profile-${row.id}`}
                    data-qa={`organization-employee-profile-${row.id}`}
                  >
                    <Text
                      fontSize="md"
                      fontWeight="medium"
                      color={colors.primary[800]}
                    >
                      {`${row.firstName} ${row.lastName}`}
                    </Text>
                  </Link>
                  <Text fontSize="11px" color={colors.greySolid[600]}>
                    {row.email}
                  </Text>
                </If>
                <If condition={row.status === 'DEACTIVATED'}>
                  <Link to={`/employees/${row.id}/profile`}>
                    <Text
                      fontSize="md"
                      fontWeight="medium"
                      color={colors.greySolid[600]}
                    >
                      {`${row.firstName} ${row.lastName}`}
                    </Text>
                  </Link>
                  <Text fontSize="11px" color={colors.greySolid[600]}>
                    {row.email}
                  </Text>
                </If>
                <If condition={row.status === 'INVITED'}>
                  <Text
                    fontSize="md"
                    fontWeight="medium"
                    color={colors.greySolid[900]}
                  >
                    {row.email}
                  </Text>
                </If>
              </Box>
            </Flex>
          </Td>
          <If condition={showFilteredSkills}>
            <Td
              width="250px"
              id={`organization-employees-table-row-targeted-skills-${index}`}
              className="targeted"
            >
              <Box display="flex" justifyContent="flex-start" alignItems="center">
                <Wrap height="24px">
                  {row.skills
                    .filter((item: any) => item.status === 'COMPLETED')
                    .map((skill: any, i: number) =>
                      renderWrapItem(skill, row, 'completed')
                    )}
                  {row.skills
                    .filter((item: any) => item.status === 'PENDING')
                    .map((skill: any) => renderWrapItem(skill, row, 'pending'))
                    }
                </Wrap>
                <Box ml="5px">
                  <Text
                    color={skillTagColor(row.status, 'completed')}
                    fontSize="sm"
                    fontWeight="semibold"
                  >
                    {showMoreIndicator(
                      index,
                      'organization-employees-table-row-completed-skills'
                    )}
                  </Text>
                </Box>
              </Box>
            </Td>
          </If>
          <If condition={!showFilteredSkills}>
            <Td
              width="250px"
              id={`organization-employees-table-row-completed-skills-${index}`}
            >
              <Box display="flex" justifyContent="flex-start" alignItems="center">
                <Wrap height="24px">
                  {row.skills
                    .filter((item: any) => item.status === 'COMPLETED')
                    .map((skill: any, i: number) =>
                      renderWrapItem(skill, row, 'completed')
                    )}
                </Wrap>
                <Box ml="5px">
                  <Text
                    color={skillTagColor(row.status, 'completed')}
                    fontSize="sm"
                    fontWeight="semibold"
                  >
                    {showMoreIndicator(
                      index,
                      'organization-employees-table-row-completed-skills'
                    )}
                  </Text>
                </Box>
              </Box>
            </Td>
            <Td
              width="250px"
              id={`organization-employees-table-row-pending-skills-${index}`}
            >
              <Box display="flex" justifyContent="flex-start" alignItems="center">
                <Wrap height="24px">
                  {row.skills
                    .filter((item: any) => item.status === 'PENDING')
                    .map((skill: any) => renderWrapItem(skill, row, 'pending'))}
                </Wrap>
                <Box ml="5px">
                  <Text
                    color={skillTagColor(row.status, 'pending')}
                    fontSize="sm"
                    fontWeight="semibold"
                  >
                    {showMoreIndicator(
                      index,
                      'organization-employees-table-row-pending-skills'
                    )}
                  </Text>
                </Box>
              </Box>
            </Td>
          </If>
          <Td width="210px">
            <Status
              variant={employeeStatus(row?.status)}
              text={row.status.charAt(0) + row.status.slice(1).toLowerCase()}
            />
          </Td>
        </Tr>
      );
    },
    [renderWrapItem, skillTagColor, showFilteredSkills]
  );
  return (
    <>
      <Box display="flex" px="25px" mt="25px" mb="30px">
        <SearchInput
          placeholder="Search employees by name or email"
          defaultValue={params.search}
          handleSearch={handleSearch}
          dataTestId="organization-employee-table-search-input"
          dataQa="organization-employee-table-search-input"
        />
      </Box>
      <Filter />
      <Box
        px="25px"
        overflow="auto"
        height={`calc(100vh - ${tableHeight()}px)`}
        ref={containerRef}
      >
        <Table
          data-testid="organization-employees-table"
          data-qa="organization-employees-table"
          columns={TABLE_COLUMNS_EMPLOYEES}
          rows={employees}
          sort={params.sort}
          onSort={handleSort}
          Trigger={Trigger}
          stickyHeader
          renderRow={renderRow}
        />
      </Box>
    </>
  );
}

export default EmployeesTable;

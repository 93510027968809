import { SerializedError } from '@reduxjs/toolkit';
import { URLParams } from '../types/params';

// User
export type User = {
  firstName: string;
  lastName: string;
  email: string;
  image: string;
  status: string;
  role: string;
};

export type UserSliceState = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  image: string;
  status: string;
  role: string;
  profile?: {
    name: string;
    email: string;
    role: string;
    location?: string | null;
    status: string;
    employeeGroups: string[];
    pendingSkills: string[];
    completedSkills: string[];
    levelOfExpertiseGroups: {
      One: string[];
      Two: string[];
      Three: string[];
      Four: string[];
      Five: string[];
    };
  };
  initialProfile?: {};
  history: History[],
  hubLocations: string[];
  loading: boolean;
  error: SerializedError | null;
  params: URLParams,
  list: any,
  hasMore: boolean,
};

export enum UserRole {
  Admin = 'ROLE_ADMIN',
  User = 'ROLE_USER',
}

// Skills
export type Skill = {
  id: string;
  name: string;
  levelOfExpertise: number | null;
  skillGroups: string[];
  totalSkillGroups: number;
  assessmentStatus: string;
  comment?: string;
};

export type SkillsSliceState = {
  list: Skill[];
  hasMore: boolean;
  loading: boolean;
  params: URLParams;
  error: SerializedError | null;
  skillsTotal: number;
};

export enum SkillsTabs {
  Pending = 'Pending',
  Completed = 'Completed',
  All = 'All',
}

export enum EmployeeProfileTabs {
  Overview = 'Overview',
  History = 'History',
}

// Organization
export enum OrganizationTabs {
  Skills = 'Skills',
  SkillGroups = 'Skill groups',
  Employees = 'Employees',
  EmployeeGroups = 'Employee groups',
}

export type SimpleEmployee = {
  id: string;
  name: string;
  image?: string;
};

export type SimpleEmployeeGroupItem = {
  id: string;
  name: string;
};

export type OrganizationSkill = {
  id: string;
  name: string;
  skillGroups: string[];
  totalSkillGroups: number;
  completedBy: SimpleEmployee[];
  completedByTotal: number;
};

export type Employee = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  image: string;
  status: string;
  role: string;
  skills: string[];
  showFilteredSkills: boolean;
};

export type History = {
  message: string;
  time: string;
};

export type SimpleSkill = {
  id: string;
  name: string;
};

export type SimpleSkillGroup = {
  skillGroupName: string;
  skills?: SimpleSkill[];
  skillsToAdd?: SimpleSkill[];
};

export type SkillGroup = {
  id: string;
  name: string;
  skills: SimpleSkill[];
};

export type EmployeeGroup = {
  id: string;
  name: string;
  employees: SimpleEmployee[];
  totalEmployees: number;
};

export type SimpleEmployeeGroup = {
  name: string;
  employees: SimpleEmployee[];
};

export type SimpleSkillParams = {
  data?: {
    skillName: string;
    infoLink: string;
    skillGroupsToAdd: string[];
    skillGroupsToRemove: string[];
  };
  id?: string;
};

export type CreateSkillParams = {
  skillName: string;
  infoLink: string;
  skillGroups: string[];
};

export type EditSkillParams = {
  data?: {
    skillName: string;
    infoLink: string;
    skillGroupsToAdd: string[];
    skillGroupsToRemove: string[];
  };
  id?: string;
};

export type EditSkillGroupsParams = {
  data?: {
    skillGroupName: string;
    skillsToAdd: string[];
    skillsToRemove: string[];
  };
  id?: string;
};

export type LoadOption = {
  value: string;
  label: string;
  id: string;
};

export type FilterSkill = {
  name: string;
  label: string;
  id: string;
};

export type FilterStatus = {
  id: string;
  name: string;
  isChecked: boolean;
  label: string;
};

export enum FilterKey {
  Status = 'employeeStatuses',
  Expertise = 'levelOfExpertise',
  Skill = 'skillsWithPriority',
}

export type FilterObj = {
  employeeStatuses: FilterStatus[];
  levelOfExpertise: number | null;
  skillsWithPriority: string[];
};

export type FilterTemplate = {
  name: string;
  employeeFilterDto: FilterObj;
};

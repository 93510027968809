import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Tag,
  TagLabel,
  Box,
  Tr,
  Td,
  WrapItem,
  Wrap,
  Text,
} from '@chakra-ui/react';
import { Checkbox } from '../../../../design/components/Checkbox/Checkbox';
import Table from '../../../../components/Wrappers/Table';
import { Column } from '../../../../components/Wrappers/Table/Table';
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll';
import { URLParams } from '../../../../types/params';
import { AppDispatch, RootState } from '../../../../store/store';
import { Skill, SkillsTabs } from '../../../../api/types';
import { colors } from '../../../../design/styles/foundations/colors';
import { Status } from '../../../../design/components/Status/Status';
import '../../../../assets/styles/main.scss';
import CompleteAssessmentModal from '../../../Organization/components/SkillsTable/components/CompleteAssessmentModal';
import showMoreIndicator from '../../../../utils/showMoreIndicator';
import { selectAll, selectItems } from '../../../../utils/checkboxGroupActions';
import getStatusValue from '../../../../utils/getStatusValue';

const HEADER_STYLE: React.CSSProperties = {
  top: '0px',
};

const TABLE_PADDING = 20;

interface Props {
  getAllSlice: any;
  skillsSlice: any;
  skillsType: string;
  skillsState: any;
}

function SkillsTable({
  getAllSlice,
  skillsSlice,
  skillsType,
  skillsState,
}: Props) {
  const TABLE_COLUMNS_SKILLS: Column[] = [
    {
      name: 'Skill',
      sortValue: 'skill.name',
      style: HEADER_STYLE,
    },
    {
      name: 'Level of expertise',
      sortValue: 'level',
      style: HEADER_STYLE,
      disableSort: true,
    },
    {
      name: 'Skill groups',
      sortValue: 'skillGroups',
      style: HEADER_STYLE,
      disableSort: true,
    },
    {
      name: 'Status',
      sortValue: 'assessmentStatus',
      style: HEADER_STYLE,
      disableSort: skillsType !== SkillsTabs.All,
    },
  ];

  const dispatch = useDispatch<AppDispatch>();
  const skills = useSelector(() => skillsState.list);
  const userEmail = useSelector((state: RootState) => state.user.email);
  const hasMore = useSelector(() => skillsState.hasMore);
  const loading = useSelector<RootState, boolean>(() => skillsState.loading);
  const params = useSelector<RootState, URLParams>(() => skillsState.params);
  const containerRef: any = useRef<HTMLHtmlElement>();
  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  const handleCall = useCallback(
    ({ page }: any) => {
      const newParams = { ...params, email: userEmail };
      dispatch(getAllSlice(newParams));
      dispatch(
        skillsSlice.actions.updateParams({
          prop: 'page',
          value: page + 1,
        })
      );
    },
    [dispatch, getAllSlice, params, skillsSlice.actions, userEmail]
  );

  const handleSort = useCallback(
    (srt: any) => {
      dispatch(skillsSlice.actions.updateParams({ prop: 'sort', value: srt }));
      dispatch(skillsSlice.actions.resetTable());
    },
    [dispatch, skillsSlice.actions]
  );

  useEffect(() => {
    return () => {
      dispatch(skillsSlice.actions.resetTable());
    };
  }, [dispatch, skillsSlice.actions]);

  const { Trigger } = useInfiniteScroll({
    rows: skills,
    next: handleCall,
    container: containerRef.current,
    hasMore,
    loading,
    page: params.page,
    size: params.size,
    sort: params.sort,
  });

  const tableHeight = useCallback(() => {
    return (
      containerRef?.current?.getBoundingClientRect().top + TABLE_PADDING || 0
    );
  }, []);

  const handleSelectAllSkills = useCallback(
    (isSelected: boolean) => {
      const checkedItemsList = selectAll(isSelected, skills);
      setCheckedItems(checkedItemsList);
    },
    [skills]
  );

  const handleSelectSkills = useCallback(
    (id: string, e: React.BaseSyntheticEvent) => {
      const checkedItemsList = selectItems(id, e, checkedItems);
      setCheckedItems(checkedItemsList);
    },
    [checkedItems]
  );

  const reloadTable = useCallback(() => {
    setCheckedItems([]);
    dispatch(skillsSlice.actions.resetTable());
    const newParams = { ...params, page: 0, email: userEmail };
    dispatch(getAllSlice(newParams));
    dispatch(
      skillsSlice.actions.updateParams({
        prop: 'page',
        value: 1,
      })
    );
  }, [dispatch, getAllSlice, params, skillsSlice.actions, userEmail]);

  const renderRow = useCallback(
    (row: Skill, index: number) => {
      return (
        <Tr
          key={index}
          data-testid="skills-table-row"
          data-qa="skills-table-row"
          bgColor={checkedItems.includes(row.id) ? colors.primary[100] : ''}
        >
          <Td width="0" pr="0" mr="0">
            <Checkbox
              isChecked={checkedItems.includes(row.id)}
              onChange={(e) => handleSelectSkills(row.id, e)}
              data-testid={`skills-table-row-checkbox-${row.id}`}
              data-qa={`skills-table-row-checkbox-${row.id}`}
              className="chkbox-shadow"
            />
          </Td>
          <Td>{row.name}</Td>
          <Td width="200px">
            <CompleteAssessmentModal
              skills={skills}
              selectedSkills={[row]}
              index={index}
              onCompleteAssessment={reloadTable}
            />
          </Td>
          <Td id={`skills-table-row-skill-groups-${index}`} maxWidth="300px">
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Wrap height="24px">
                {row.skillGroups.map((skillGroup: string) => {
                  return (
                    <WrapItem key={skillGroup}>
                      <Tag
                        color={colors.primary[800]}
                        backgroundColor={colors.primary[200]}
                        borderRadius="3px"
                        px={2}
                        py="3px"
                        fontWeight="400"
                      >
                        <TagLabel>{skillGroup}</TagLabel>
                      </Tag>
                    </WrapItem>
                  );
                })}
              </Wrap>
              <Box ml="5px">
                <Text
                  color={colors.primary[800]}
                  fontSize="sm"
                  fontWeight="semibold"
                >
                  {showMoreIndicator(
                    index,
                    'skills-table-row-skill-groups',
                    row.totalSkillGroups
                  )}
                </Text>
              </Box>
            </Box>
          </Td>

          <Td>
            <Status
              variant={getStatusValue(row.assessmentStatus)}
              text={
                row.assessmentStatus.charAt(0).toUpperCase() +
                row.assessmentStatus.toLowerCase().slice(1)
              }
            />
          </Td>
        </Tr>
      );
    },
    [checkedItems, skills, reloadTable, handleSelectSkills]
  );

  return (
    <>
      <Box
        px="25px"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        overflow="auto"
        height={`calc(100vh - ${tableHeight()}px)`}
        ref={containerRef}
      >
        <Table
          data-testid="skills-table"
          data-qa="skills-table"
          columns={TABLE_COLUMNS_SKILLS}
          rows={skills}
          sort={params.sort}
          onSort={handleSort}
          Trigger={Trigger}
          stickyHeader
          showCheckbox
          isCheckboxDisabled={!skills.length}
          isSelectedAll={
            checkedItems.length === skills.length && skills.length > 0
          }
          onSelectAll={handleSelectAllSkills}
          renderRow={renderRow}
        />
        <Box display="flex" className="organization-table-row-bottom">
          <CompleteAssessmentModal
            skills={skills}
            selectedSkills={skills.filter((item: Skill) =>
              checkedItems.includes(item.id)
            )}
            skillsType={skillsType}
            onCompleteAssessment={reloadTable}
            showByIndex
            isFromButton
          />
        </Box>
      </Box>
    </>
  );
}

export default SkillsTable;

import React, { useCallback, useRef, useState } from 'react';
import {
  Box,
  Text,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  useRadioGroup,
  Button,
  MenuDivider,
  Input,
  MenuItem,
  MenuGroup,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import CancellablePill from './CancellablePill/CancellablePill';
import { MenuItemSingleSelect } from '../../design/components/Menu/MenuItemSingleSelect/MenuItemSingleSelect';
import {
  MenuItemCheckboxProps,
  MenuItemMultiSelect,
} from '../../design/components/Menu/MenuItemMultiSelect/MenuItemMultiSelect';
import { colors } from '../../design/styles/foundations/colors';
import LightSearch from '../../design/styles/icons/light/LightSearch';
import SvgSolidArrowChevronDown from '../../design/styles/icons/solid/SolidArrowChevronDown';
import { searchSkills } from '../../store/slices/organizationEmployeeGroups/organizationEmployeeGroups';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import If from '../If';
import { debounce, isEqual } from 'lodash';
import SvgSolidFilter from '../../design/styles/icons/solid/SolidFilter';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import SortableSkill from './SortableSkill/SortableSkill';
import { LEVEL_OF_EXPERTISE } from '../../constants/index';
import {
  FilterKey,
  FilterObj,
  FilterSkill,
  FilterStatus,
  SimpleSkill,
} from '../../api/types';
import {
  deleteTemplate,
  getAllEmployees,
  getAllTemplates,
  getTemplate,
  resetTable,
  setFilters,
  updateFilters,
  updateSelectedTemplateFilter,
  updateSelectedTemplate,
} from '../../store/slices/organizationEmployees/organizationEmployees';
import { URLParams } from '../../types/params';
import formatFilter from '../../utils/formatFilter';
import CreateFilterTemplateModal from './CreateFilterTemplateModal/CreateFilterTemplateModal';
import SvgSolidDelete from '../../design/styles/icons/solid/SolidDelete';

const initialFilter: FilterObj = {
  employeeStatuses: [
    { id: 'active', name: 'active', isChecked: false, label: 'Active' },
    {
      id: 'deactivated',
      name: 'deactivated',
      isChecked: false,
      label: 'Deactivated',
    },
    { id: 'invited', name: 'invited', isChecked: false, label: 'Invited' },
  ],
  levelOfExpertise: null,
  skillsWithPriority: [],
};

const Filter = () => {
  const dispatch = useDispatch<AppDispatch>();
  const params = useSelector<RootState, URLParams>(
    (state: RootState) => state.organizationEmployees.params
  );
  const filter = useSelector<RootState, any>(
    (state: RootState) => state.organizationEmployees.filter
  );
  const templates = useSelector<RootState, any>(
    (state: RootState) => state.organizationEmployees.templateList
  );
  const selectedTemplate = useSelector<RootState, any>(
    (state: RootState) => state.organizationEmployees.selectedTemplate
  );
  const [searchedSkills, setSearchedSkills] = useState<SimpleSkill[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 18,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = filter.skillsWithPriority.findIndex(
        (i: FilterSkill) => i.id === active.id
      );
      const newIndex = filter.skillsWithPriority.findIndex(
        (i: FilterSkill) => i.id === over?.id
      );

      updateFilterState(
        FilterKey.Skill,
        arrayMove(filter.skillsWithPriority, oldIndex, newIndex)
      );
    }
  };
  const removeSkillItem = (id: string) => {
    updateFilterState(FilterKey.Skill, [
      ...filter.skillsWithPriority.filter(
        (skill: FilterSkill) => skill.id !== id
      ),
    ]);
  };

  const handleOnChangeStatus = (options: MenuItemCheckboxProps[]) => {
    updateFilterState(FilterKey.Status, options);
  };

  const handleOnChangeExpertise = (value: any) => {
    updateFilterState(FilterKey.Expertise, value);
  };

  const handleMap = (list: FilterStatus[]) => {
    return list
      .filter((item: FilterStatus) => item.isChecked)
      .map((item: FilterStatus) => item.label);
  };
  const handleDebounce = debounce((value: string) => {
    dispatch(searchSkills(value)).then((res) => setSearchedSkills(res.payload));
  }, 300);
  const handleSearchSkill = useCallback(
    (value: string) => {
      if (value.length > 1) {
        handleDebounce(value);
      } else {
        setSearchedSkills([]);
      }
    },
    [handleDebounce]
  );

  const handleSkillMapResponse = (res: SimpleSkill[]) => {
    return res
      ? res
          .map((e: SimpleSkill) => ({
            name: e.name,
            label: e.name,
            id: e.id,
          }))
          .filter(({ id: id1 }: { id: string }) =>
            filter.skillsWithPriority.every(
              ({ id: id2 }: { id: string }) => id2 !== id1
            )
          )
      : [];
  };

  const handleClearAll = () => {
    dispatch(setFilters(initialFilter));
    dispatch(resetTable());
    dispatch(updateSelectedTemplate({ label: '', id: '' }));
  };

  const handleApplyFilter = useCallback(() => {
    dispatch(setFilters(filter));
    dispatch(resetTable());
    dispatch(
      getAllEmployees({
        filter: formatFilter(filter),
        params: { ...params, page: 0 },
      })
    );
  }, [dispatch, filter, params]);

  const updateFilterState = useCallback(
    (
      prop: string,
      value: FilterSkill | FilterStatus | string | unknown[] | null
    ) => {
      dispatch(
        updateFilters({
          prop,
          value,
        })
      );
    },
    [dispatch]
  );

  const removeStatus = () => {
    updateFilterState(FilterKey.Status, initialFilter.employeeStatuses);
  };

  const removeExpertise = () => {
    updateFilterState(FilterKey.Expertise, null);
  };

  const removeSkill = () => {
    updateFilterState(FilterKey.Skill, initialFilter.skillsWithPriority);
  };

  const handleFilterTemplate = (
    event:
      | React.MouseEvent<SVGElement, MouseEvent>
      | React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string | undefined,
    label: string,
    isDelete: boolean
  ) => {
    event.stopPropagation();
    if (isDelete) {
      dispatch(deleteTemplate(id))
        .then(() => dispatch(getAllTemplates()))
        .then(() => isCurrentTemplateDeleted(label));
    } else {
      dispatch(getTemplate(id)).then((res) =>
        updateEmployeeStatuses(res.payload, label, id)
      );
    }
  };

  const isCurrentTemplateDeleted = (label: string) => {
    if (label === selectedTemplate?.label) {
      dispatch(updateSelectedTemplate({ label: '', id: '' }));
      dispatch(updateSelectedTemplateFilter({}));
    }
  };

  function updateEmployeeStatuses(
    response: any,
    label: string,
    id: string | undefined
  ) {
    const employeeStatuses = [...initialFilter.employeeStatuses];

    const updatedStatuses = employeeStatuses.map((status) => {
      if (response?.employeeStatuses.includes(status.name.toUpperCase())) {
        return { ...status, isChecked: true };
      } else {
        return { ...status, isChecked: false };
      }
    });

    const updatedSkills = response?.skillsWithPriority.map((item: any) => {
      return {
        ...item,
        label: item.name,
      };
    });

    const mappedFilterResponse = {
      employeeStatuses: [...updatedStatuses],
      levelOfExpertise: response?.levelOfExpertise
        ? 'Level ' + response?.levelOfExpertise
        : response?.levelOfExpertise,
      skillsWithPriority: updatedSkills ? [...updatedSkills] : [],
    };

    dispatch(setFilters(mappedFilterResponse));
    dispatch(updateSelectedTemplateFilter(mappedFilterResponse));
    dispatch(updateSelectedTemplate({ label, id }));
  }

  return (
    <>
      <Box minHeight="112px" data-testid="filter-container">
        <Flex paddingLeft="25px">
          <Menu closeOnSelect={true}>
            <MenuButton
              color={colors.greySolid[800]}
              bgColor={colors.greyAlpha[50]}
              as={Button}
              data-testid="filter-choose-filter-template-menu-button"
              marginRight="24px"
              fontWeight={600}
              fontSize="13px"
              minWidth="182px"
              rightIcon={<Icon fontSize="13px" as={SvgSolidArrowChevronDown} />}
              _hover={{ bg: colors.greyAlpha[100] }}
              _expanded={{ bg: colors.greyAlpha[100] }}
              _active={{
                bg: colors.greyAlpha[100],
              }}
            >
              {selectedTemplate?.label && !isEqual(filter, initialFilter)
                ? selectedTemplate?.label
                : 'Choose filter template'}
            </MenuButton>
            <MenuList
              minWidth="240px"
              zIndex="2"
              data-testid="choose-filter-template-menu-list"
            >
              <If condition={templates && !templates.length}>
                <Text
                  fontSize="12px"
                  textAlign="center"
                  color={colors.greySolid[500]}
                >
                  No templates yet.
                </Text>
              </If>
              <MenuItemSingleSelect
                options={templates ? templates : []}
                icon={SvgSolidDelete}
                onItemClick={handleFilterTemplate}
                useRadioGroupReturn={useRadioGroup({
                  value: selectedTemplate?.label,
                })}
              />
            </MenuList>
          </Menu>
          <Menu closeOnSelect={false}>
            <MenuButton
              color={colors.greySolid[800]}
              bgColor={colors.greyAlpha[50]}
              as={Button}
              data-testid="filter-status-menu-button"
              marginRight="8px"
              rightIcon={<Icon as={SvgSolidArrowChevronDown} />}
              fontSize="13px"
              fontWeight={600}
              _hover={{ bg: colors.greyAlpha[100] }}
              _expanded={{ bg: colors.greyAlpha[100] }}
              _active={{
                bg: colors.greyAlpha[100],
              }}
            >
              Status
            </MenuButton>
            <MenuList
              minWidth="240px"
              zIndex="2"
              data-testid="filter-status-menu-list"
            >
              <MenuItemMultiSelect
                onChange={handleOnChangeStatus}
                multiOptions={filter.employeeStatuses}
              />
            </MenuList>
          </Menu>
          <Menu closeOnSelect>
            <MenuButton
              as={Button}
              data-testid="filter-level-of-expertise-menu-button"
              marginRight="8px"
              color={colors.greySolid[800]}
              bgColor={colors.greyAlpha[50]}
              rightIcon={<Icon as={SvgSolidArrowChevronDown} />}
              fontSize="13px"
              fontWeight={600}
              _hover={{ bg: colors.greyAlpha[100] }}
              _expanded={{ bg: colors.greyAlpha[100] }}
              _active={{
                bg: colors.greyAlpha[100],
              }}
            >
              Level of expertise
            </MenuButton>
            <MenuList
              minWidth="240px"
              zIndex="2"
              data-testid="filter-level-of-expertise-menu-list"
            >
              <MenuItemSingleSelect
                options={LEVEL_OF_EXPERTISE}
                useRadioGroupReturn={useRadioGroup({
                  onChange: (e) => handleOnChangeExpertise(e),
                  value: filter.levelOfExpertise ? filter.levelOfExpertise : '',
                })}
              />
            </MenuList>
          </Menu>
          <Menu
            closeOnSelect={false}
            initialFocusRef={inputRef}
            data-testid="filter-skill-menu"
          >
            <MenuButton
              as={Button}
              color={colors.greySolid[800]}
              bgColor={colors.greyAlpha[50]}
              data-testid="filter-skill-menu-button"
              marginRight="8px"
              rightIcon={<Icon as={SvgSolidArrowChevronDown} />}
              fontSize="13px"
              fontWeight={600}
              _hover={{ bg: colors.greyAlpha[100] }}
              _expanded={{ bg: colors.greyAlpha[100] }}
              _active={{
                bg: colors.greyAlpha[100],
              }}
            >
              Skill
            </MenuButton>
            <MenuList
              minWidth="240px"
              zIndex="2"
              onFocus={() => inputRef.current?.focus()}
            >
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={
                    <Icon as={LightSearch} color={colors.greySolid[500]} />
                  }
                  height="25px"
                />
                <Input
                  ref={inputRef}
                  autoFocus
                  paddingLeft="25px"
                  placeholder="Search"
                  size="xs"
                  onChange={(e) => handleSearchSkill(e.target.value)}
                  data-testid="filter-search-skill-input"
                />
              </InputGroup>
              <MenuGroup title="Priority">
                <If condition={!filter.skillsWithPriority.length}>
                  <Box
                    fontSize="12px"
                    textAlign="center"
                    color={colors.greySolid[500]}
                  >
                    No selected skills
                  </Box>
                </If>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext
                    items={filter.skillsWithPriority}
                    strategy={rectSortingStrategy}
                  >
                    {filter.skillsWithPriority?.map((item: FilterSkill) => (
                      <Box key={item.id}>
                        <SortableSkill
                          removeSkillItem={removeSkillItem}
                          {...item}
                        />
                      </Box>
                    ))}
                  </SortableContext>
                </DndContext>
              </MenuGroup>
              <MenuDivider />
              <MenuGroup data-testid="filter-skill-menu-list">
                {filter.skillsWithPriority.length < 10 ? (
                  handleSkillMapResponse(searchedSkills).map(
                    (item: FilterSkill) => {
                      return (
                        <MenuItem
                          fontSize="13px"
                          key={item.id}
                          onClick={() =>
                            updateFilterState(FilterKey.Skill, [
                              ...filter.skillsWithPriority,
                              item,
                            ])
                          }
                          data-testid="filter-searched-skill"
                        >
                          {item.label}
                        </MenuItem>
                      );
                    }
                  )
                ) : (
                  <Box
                    fontSize="12px"
                    textAlign="center"
                    color={colors.greySolid[500]}
                  >
                    Maximum number of skills is 10
                  </Box>
                )}
              </MenuGroup>
            </MenuList>
          </Menu>
          <Button
            minWidth="32px"
            width="32px"
            minHeight="32px"
            marginRight="8px"
            onClick={() => handleApplyFilter()}
            data-testid="filter-apply-button"
          >
            <Icon as={SvgSolidFilter} fontSize="18px" />
          </Button>
          <CreateFilterTemplateModal
            isEditTemplate={!!selectedTemplate?.label.length}
            templateId={selectedTemplate?.id}
          />
        </Flex>
        <Flex
          paddingLeft="25px"
          marginTop="22px"
          alignItems="center"
          flexWrap="wrap"
        >
          <If condition={Boolean(handleMap(filter.employeeStatuses).length)}>
            <CancellablePill
              name={'Status'}
              selections={handleMap(filter.employeeStatuses)}
              onRemove={() => removeStatus()}
              data-testid="filter-cancel-pill-status"
            />
          </If>
          <If condition={filter.levelOfExpertise}>
            <CancellablePill
              name={'Expertise'}
              selections={[filter.levelOfExpertise]}
              onRemove={() => removeExpertise()}
              data-testid="filter-cancel-pill-expertise"
            />
          </If>
          <If condition={filter.skillsWithPriority.length}>
            <CancellablePill
              name={'Skill'}
              selections={filter.skillsWithPriority?.map(
                (skill: FilterSkill) => skill.label
              )}
              onRemove={() => removeSkill()}
              data-testid="filter-cancel-pill-skill"
            />
          </If>
          <If
            condition={
              handleMap(filter.employeeStatuses).length ||
              filter.levelOfExpertise ||
              filter.skillsWithPriority.length
            }
          >
            <Button
              variant="ghost"
              onClick={() => handleClearAll()}
              data-testid="filter-clear-all"
            >
              Clear all
            </Button>
          </If>
        </Flex>
      </Box>
    </>
  );
};

export default Filter;

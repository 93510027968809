import {
  createSlice,
  createAsyncThunk,
  SerializedError,
} from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import api from '../../../api';
import {
  EmployeeGroup,
  SimpleEmployee,
  SimpleEmployeeGroup,
} from '../../../api/types';
import { URLParams } from '../../../types/params';
import URLParamsConvertor from '../../../utils/URLParamsConvertor';

const name = 'organizationEmployeeGroups';

export const initialParams: URLParams = {
  page: 0,
  size: 20,
  sort: [],
  search: '',
};

interface InitialState {
  list: EmployeeGroup[];
  hasMore: boolean;
  params: URLParams;
  loading: boolean;
  error: SerializedError | null;
  initialEmployeeList: SimpleEmployee[];
  employees: SimpleEmployee[];
  employeeGroup: SimpleEmployeeGroup | null;
}

export const initialState: InitialState = {
  list: [],
  hasMore: true,
  params: initialParams,
  loading: false,
  error: null,
  initialEmployeeList: [],
  employees: [],
  employeeGroup: null,
};

export const getAllEmployeeGroups = createAsyncThunk(
  `${name}/getAllEmployeeGroups`,
  async (params: URLParams) => {
    const response = await api.organization.allEmployeeGroups(
      URLParamsConvertor(params)
    );
    return response;
  }
);

export const getInitialEmployeeList = createAsyncThunk(
  `${name}/getInitialEmployeeList`,
  async () => {
    const response = await api.organization.initialEmployeeList();
    return response.data;
  }
);

export const searchEmployees = createAsyncThunk(
  `${name}/searchEmployees`,
  async (inputValue: string) => {
    const response = await api.organization.searchEmployees(inputValue);
    return response.data;
  }
);

export const getEmployeeGroup = createAsyncThunk(
  `${name}/getEmployeeGroup`,
  async (id: string) => {
    const response = await api.organization.getEmployeeGroup(id);
    return response.data;
  }
);

export const editEmployeeGroup = createAsyncThunk(
  `${name}/editEmployeeGroup`,
  async (
    params: {
      data: {
        employeeGroupName: string;
        employeeIds: string[];
      };
      id: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response: AxiosResponse<SimpleEmployeeGroup> =
        await api.organization.editEmployeeGroup(params);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteEmployeeGroup = createAsyncThunk(
  `${name}/deleteEmployeeGroup`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.organization.deleteEmployeeGroup(id);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createEmployeeGroup = createAsyncThunk(
  `${name}/createEmployeeGroup`,
  async (
    params: {
      data: {
        employeeGroupName: string;
        employeeIds: string[];
      };
      createdBy: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.organization.createEmployeeGroup(params);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const assignSkills = createAsyncThunk(
  `${name}/assignSkills`,
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await api.organization.assignSkills(params);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const assignSkillGroups = createAsyncThunk(
  `${name}/assignSkillGroups`,
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await api.organization.assignSkillGroups(params);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const searchSkills = createAsyncThunk(
  `${name}/searchSkills`,
  async (inputValue: string) => {
    const response = await api.organization.searchSkills(inputValue);
    return response.data;
  }
);

const OrganizationEmployeeGroupsSlice = createSlice({
  name,
  initialState,
  reducers: {
    updateParams: (state, { payload }) => {
      state.params = { ...state.params, [payload.prop]: payload.value };
    },
    resetTable: (state) => {
      state.list = initialState.list;
      state.hasMore = initialState.hasMore;
      state.loading = initialState.loading;
      state.params.page = 0;
    },
    resetEmployees: (state) => {
      state.employees = initialState.employees;
    },
    resetEmployeeGroup: (state) => {
      state.employeeGroup = initialState.employeeGroup;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllEmployeeGroups.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllEmployeeGroups.fulfilled, (state, action: any) => {
        state.list = [...state.list, ...action.payload.data.content];
        state.hasMore = !action.payload.data.last;
        state.loading = false;
      })
      .addCase(getAllEmployeeGroups.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
        state.hasMore = false;
      })
      .addCase(getInitialEmployeeList.fulfilled, (state, action: any) => {
        state.initialEmployeeList = action.payload;
      })
      .addCase(searchEmployees.fulfilled, (state, action) => {
        state.employees = action.payload;
      })
      .addCase(getEmployeeGroup.fulfilled, (state, action) => {
        state.employeeGroup = action.payload;
      });
  },
});

export const { updateParams, resetTable, resetEmployees, resetEmployeeGroup } =
  OrganizationEmployeeGroupsSlice.actions;
export const organizationEmployeeGroupsSlice = OrganizationEmployeeGroupsSlice;

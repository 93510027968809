import React from 'react';

import FilterModal from '../FilterModal/FilterModal';
interface Props {
  isEditTemplate?: boolean;
  templateId?: string;
}

const CreateFilterTemplateModal = ({ isEditTemplate, templateId }: Props) => {
  return (
    <>
      <FilterModal
        toastMessages={{
          title: {
            success: isEditTemplate
              ? 'Successfully edited template'
              : 'Successfully created a new template',
            error: 'Failed!',
          },
          description: {
            success: isEditTemplate
              ? 'Filter template was edited.'
              : 'A new filter template was created.',
            error: isEditTemplate
              ? 'Editing a template failed.'
              : 'The creation of a new template failed.',
          },
        }}
        filterModalProps={{
          headerTitle: isEditTemplate ? 'Edit template' : 'Save as template',
          confirmButtonTitle: isEditTemplate ? 'Save' : 'Create',
        }}
        isEditTemplate={isEditTemplate}
        templateId={templateId}
      />
    </>
  );
};

export default CreateFilterTemplateModal;

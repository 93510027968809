import { useCallback, useState } from 'react';
import { Box, Icon, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import ModalComponent from '../Modal';
import SvgSolidDelete from '../../design/styles/icons/solid/SolidDelete';
import { useToast } from '../../design/hooks/useToast';
import { colors } from '../../design/styles/foundations';
import If from '../If';

interface Props {
  id: string;
  toastMessages: {
    title: {
      success: string;
      error: string;
    };
    description: {
      success: string;
      error: string;
    };
  };
  deleteModalProps: {
    headerTitle: string;
    confirmButtonTitle: string;
    dataTestIdModal: string;
    dataTestIdModalOverlay: string;
    dataTestIdFooterCancelButton: string;
    dataTestIdFooterConfirmButton: string;
  };
  deleteModalBodyText: string;
  deleteModalBodyAdditionalText?: string;
  deleteIconClassName: string;
  deleteIconTestId: string;
  deleteSliceAction: any;
  onDelete?: () => void;
}

const DeleteModal = ({
  id,
  toastMessages,
  deleteModalProps,
  deleteModalBodyText,
  deleteModalBodyAdditionalText,
  deleteIconClassName,
  deleteIconTestId,
  deleteSliceAction,
  onDelete,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const deleteActions = useCallback(
    (res: any) => {
      const responseStatus = !res.error ? 'success' : 'error';
      toast({
        position: 'top',
        title: toastMessages.title[responseStatus],
        status: responseStatus,
        description: toastMessages.description[responseStatus],
        isClosable: true,
        duration: 7000,
        variant: 'subtle',
      });
    },
    [toast, toastMessages.description, toastMessages.title]
  );

  const handleDelete = useCallback(() => {
    setIsButtonDisabled(true);
    dispatch(deleteSliceAction(id)).then((res: any) => {
      deleteActions(res);
      handleClose();
      setIsButtonDisabled(false);
      if (!res.error && onDelete) {
        onDelete();
      }
    });
  }, [dispatch, deleteSliceAction, id, deleteActions, handleClose, onDelete]);

  const DeleteSkillGroupsModalProps = {
    isModalOpen: isModalOpen,
    onCloseFunc: handleClose,
    headerTitle: deleteModalProps.headerTitle,
    confirmButtonTitle: deleteModalProps.confirmButtonTitle,
    confirmButtonFunc: handleDelete,
    isDisabled: isButtonDisabled,
    dataTestIdModal: deleteModalProps.dataTestIdModal,
    dataTestIdModalOverlay: deleteModalProps.dataTestIdModalOverlay,
    dataTestIdFooterCancelButton: deleteModalProps.dataTestIdFooterCancelButton,
    dataTestIdFooterConfirmButton:
      deleteModalProps.dataTestIdFooterConfirmButton,
  };

  const openTheModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  return (
    <>
      <Icon
        as={SvgSolidDelete}
        w="20px"
        h="22px"
        color={`${colors.greySolid[800]}`}
        _hover={{ color: colors.primary[500], cursor: 'pointer' }}
        className={deleteIconClassName}
        ml="12px"
        onClick={openTheModal}
        data-testid={`${deleteIconTestId}-${id}`}
        data-qa={`${deleteIconTestId}-${id}`}
      />
      <ModalComponent {...DeleteSkillGroupsModalProps}>
        <Box display="flex" flexDirection="column">
          <Text color={colors.greySolid[900]}>{deleteModalBodyText}</Text>
          <If condition={!!deleteModalBodyAdditionalText}>
            <Text color={colors.red[800]} fontSize="sm">
              {deleteModalBodyAdditionalText}
            </Text>
          </If>
        </Box>
      </ModalComponent>
    </>
  );
};

export default DeleteModal;

import SkillModal from '../SkillModal';

interface Props {
  id: string;
  onSave?: () => void;
}

const EditSkillModal = ({ id, onSave }: Props) => {
  return (
    <>
      <SkillModal
        id={id}
        toastMessages={{
          title: {
            success: 'Successfully saved a skill',
            error: 'Failed!',
          },
          description: {
            success: 'A skill was saved.',
            error: 'The saving of a skill failed.',
          },
        }}
        skillModalProps={{
          headerTitle: 'Edit skill',
          confirmButtonTitle: 'Save',
        }}
        isEditSkill
        onSave={onSave}
      />
    </>
  );
};

export default EditSkillModal;

import React from 'react';
import { useSelector } from 'react-redux';
import { SkillsTabs } from '../../../../api/types';
import {
  getAllSkills,
  skillsAllSlice,
} from '../../../../store/slices/skillsAll/skillsAll';
import { RootState } from '../../../../store/store';
import SkillsTable from '../SkillsTable';

function SkillsAllTable() {
  const skillsState = useSelector((state: RootState) => state.skillsAll);

  return (
    <SkillsTable
      skillsSlice={skillsAllSlice}
      getAllSlice={getAllSkills}
      skillsType={SkillsTabs.All}
      skillsState={skillsState}
    />
  );
}

export default SkillsAllTable;

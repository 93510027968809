import * as React from 'react';
import { SVGProps } from 'react';
const SvgSolidDraggable = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.938 14.8366C1.22933 15.1286 1.58333 15.2746 2 15.2746C2.41667 15.2746 2.77067 15.1286 3.062 14.8366C3.354 14.5453 3.5 14.1913 3.5 13.7746C3.5 13.3579 3.354 13.0036 3.062 12.7116C2.77067 12.4203 2.41667 12.2746 2 12.2746C1.58333 12.2746 1.22933 12.4203 0.938 12.7116C0.646 13.0036 0.5 13.3579 0.5 13.7746C0.5 14.1913 0.646 14.5453 0.938 14.8366Z"
      fill="#B3B3BA"
    />
    <path
      d="M0.938 9.06161C1.22933 9.35361 1.58333 9.49961 2 9.49961C2.41667 9.49961 2.77067 9.35361 3.062 9.06161C3.354 8.77028 3.5 8.41628 3.5 7.99961C3.5 7.58294 3.354 7.22894 3.062 6.93761C2.77067 6.64561 2.41667 6.49961 2 6.49961C1.58333 6.49961 1.22933 6.64561 0.938 6.93761C0.646 7.22894 0.5 7.58294 0.5 7.99961C0.5 8.41628 0.646 8.77028 0.938 9.06161Z"
      fill="#B3B3BA"
    />
    <path
      d="M0.938 3.28661C1.22933 3.57861 1.58333 3.72461 2 3.72461C2.41667 3.72461 2.77067 3.57861 3.062 3.28661C3.354 2.99528 3.5 2.64128 3.5 2.22461C3.5 1.80794 3.354 1.45361 3.062 1.16161C2.77067 0.870276 2.41667 0.724609 2 0.724609C1.58333 0.724609 1.22933 0.870276 0.938 1.16161C0.646 1.45361 0.5 1.80794 0.5 2.22461C0.5 2.64128 0.646 2.99528 0.938 3.28661Z"
      fill="#B3B3BA"
    />
    <path
      d="M6.938 14.8366C7.22933 15.1286 7.58333 15.2746 8 15.2746C8.41667 15.2746 8.77067 15.1286 9.062 14.8366C9.354 14.5453 9.5 14.1913 9.5 13.7746C9.5 13.3579 9.354 13.0036 9.062 12.7116C8.77067 12.4203 8.41667 12.2746 8 12.2746C7.58333 12.2746 7.22933 12.4203 6.938 12.7116C6.646 13.0036 6.5 13.3579 6.5 13.7746C6.5 14.1913 6.646 14.5453 6.938 14.8366Z"
      fill="#B3B3BA"
    />
    <path
      d="M6.938 9.06161C7.22933 9.35361 7.58333 9.49961 8 9.49961C8.41667 9.49961 8.77067 9.35361 9.062 9.06161C9.354 8.77028 9.5 8.41628 9.5 7.99961C9.5 7.58294 9.354 7.22894 9.062 6.93761C8.77067 6.64561 8.41667 6.49961 8 6.49961C7.58333 6.49961 7.22933 6.64561 6.938 6.93761C6.646 7.22894 6.5 7.58294 6.5 7.99961C6.5 8.41628 6.646 8.77028 6.938 9.06161Z"
      fill="#B3B3BA"
    />
    <path
      d="M6.938 3.28661C7.22933 3.57861 7.58333 3.72461 8 3.72461C8.41667 3.72461 8.77067 3.57861 9.062 3.28661C9.354 2.99528 9.5 2.64128 9.5 2.22461C9.5 1.80794 9.354 1.45361 9.062 1.16161C8.77067 0.870276 8.41667 0.724609 8 0.724609C7.58333 0.724609 7.22933 0.870276 6.938 1.16161C6.646 1.45361 6.5 1.80794 6.5 2.22461C6.5 2.64128 6.646 2.99528 6.938 3.28661Z"
      fill="#B3B3BA"
    />
  </svg>
);
export default SvgSolidDraggable;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api';
import { SkillsSliceState } from '../../../api/types';
import { URLParams } from '../../../types/params';
import URLParamsConvertor from '../../../utils/URLParamsConvertor';

const name = 'skillsCompleted';

export const initialParams: URLParams = {
  page: 0,
  size: 20,
  sort: [],
  search: '',
  email: '',
};

export const initialState: SkillsSliceState = {
  list: [],
  hasMore: true,
  params: initialParams,
  loading: false,
  error: null,
  skillsTotal: 0,
};

export const getAllCompletedSkills = createAsyncThunk(
  `${name}/getAllCompletedSkills`,
  async (params: URLParams) => {
    const response = await api.skills.completed(URLParamsConvertor(params));
    return response;
  }
);

export const completeAssessment = createAsyncThunk(
  `${name}/completeAssessment`,
  async (
    data: {
      email: string;
      skillId: string;
      levelOfExpertise: number;
      comment: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.skills.completeAssessment(data);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const SkillsCompletedSlice = createSlice({
  name,
  initialState,
  reducers: {
    updateParams: (state, { payload }) => {
      state.params = { ...state.params, [payload.prop]: payload.value };
    },
    resetSkillsTotal: (state) => {
      state.skillsTotal = initialState.skillsTotal;
    },
    resetTable: (state) => {
      state.list = initialState.list;
      state.hasMore = initialState.hasMore;
      state.loading = initialState.loading;
      state.params.page = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCompletedSkills.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCompletedSkills.fulfilled, (state, action: any) => {
        state.list = [...state.list, ...action.payload.data.content];
        state.hasMore = !action.payload.data.last;
        state.skillsTotal = action.payload.data.content.length;
        state.loading = false;
      })
      .addCase(getAllCompletedSkills.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
        state.hasMore = false;
      });
  },
});

export const { updateParams, resetTable, resetSkillsTotal } =
  SkillsCompletedSlice.actions;
export const skillsCompletedSlice = SkillsCompletedSlice;

import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { createTransform, persistReducer } from 'redux-persist';
import user from './slices/user';
import skillsPending from './slices/skillsPending';
import skillsCompleted from './slices/skillsCompleted';
import skillsAll from './slices/skillsAll';
import organizationSkills from './slices/organizationSkills';
import organizationSkillGroups from './slices/organizationSkillGroups';
import organizationEmployees from './slices/organizationEmployees';
import organizationEmployeeGroups from './slices/organizationEmployeeGroups';

const denyListTransform = createTransform((inboundState: any, key: any) => {
  if (key === 'params') {
    return { ...inboundState, page: 0 };
  }
  return inboundState;
});

const userPersistConfig = {
  key: 'user',
  storage,
  transforms: [denyListTransform],
};

const skillsPendingPersistConfig = {
  key: 'skillsPending',
  storage,
  blacklist: ['list', 'hasMore', 'loading'],
  transforms: [denyListTransform],
};

const skillsCompletedPersistConfig = {
  key: 'skillsCompleted',
  storage,
  blacklist: ['list', 'hasMore', 'loading'],
  transforms: [denyListTransform],
};

const skillsAllPersistConfig = {
  key: 'skillsAll',
  storage,
  blacklist: ['list', 'hasMore', 'loading'],
  transforms: [denyListTransform],
};

const organizationSkillsPersistConfig = {
  key: 'organizationSkills',
  storage,
  blacklist: ['list', 'hasMore', 'loading'],
  transforms: [denyListTransform],
};

const organizationSkillGroupsPersistConfig = {
  key: 'organizationSkillGroups',
  storage,
  blacklist: ['list', 'hasMore', 'loading'],
  transforms: [denyListTransform],
};

const organizationEmployeesPersistConfig = {
  key: 'organizationEmployees',
  storage,
  blacklist: ['list', 'hasMore', 'loading'],
  transforms: [denyListTransform],
};

const organizationEmployeeGroupsPersistConfig = {
  key: 'organizationEmployees',
  storage,
  blacklist: ['list', 'hasMore', 'loading'],
  transforms: [denyListTransform],
};

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, user.reducer),
  skillsPending: persistReducer(
    skillsPendingPersistConfig,
    skillsPending.reducer
  ),
  skillsCompleted: persistReducer(
    skillsCompletedPersistConfig,
    skillsCompleted.reducer
  ),
  skillsAll: persistReducer(skillsAllPersistConfig, skillsAll.reducer),
  organizationSkills: persistReducer(
    organizationSkillsPersistConfig,
    organizationSkills.reducer
  ),
  organizationSkillGroups: persistReducer(
    organizationSkillGroupsPersistConfig,
    organizationSkillGroups.reducer
  ),
  organizationEmployees: persistReducer(
    organizationEmployeesPersistConfig,
    organizationEmployees.reducer
  ),
  organizationEmployeeGroups: persistReducer(
    organizationEmployeeGroupsPersistConfig,
    organizationEmployeeGroups.reducer
  ),
});

export default rootReducer;

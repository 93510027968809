import { MenuItemCheckboxProps } from "./MenuItemMultiSelect";

export const groupMultiCheckboxStyle: MenuItemCheckboxProps = {
  size: "lg",
  height: 8,
  width: "100%",
  paddingX: 2,
  color: "grey.900",
  _hover: { backgroundColor: "light.appBg", borderRadius: "base" },
};

import axios from 'axios';
import {
  User,
  Skill,
  Employee,
  OrganizationSkill,
  SkillGroup,
  SimpleSkill,
  SimpleSkillGroup,
  EmployeeGroup,
  SimpleEmployee,
  CreateSkillParams,
  EditSkillParams,
  EditSkillGroupsParams,
  SimpleEmployeeGroupItem,
  FilterTemplate,
  FilterObj,
} from './types';

export const http = axios.create({
  baseURL: process.env.REACT_APP_API_PREFIX,
  withCredentials: true,
});

const auth = {
  login: (token: string) =>
    http.get<User>('/login', { headers: { Authorization: token } }),
  logout: () => http.get<User>('/logout'),
  getEmployeeProfile: (id: any) => http.get(`employee/employees/${id}/profile`),
  getEmployeeHistory: (id: any, params: any) =>
    http.get(`/admin/employee/${id}/activity`, { params }),
  updateEmployeeProfile: (data: any, id: string) =>
    http.post(`employee/employees/${id}`, data),
  getHubLocations: () => http.get(`employee/employees/hub-locations`),
};

const skills = {
  pending: (params: URLSearchParams) =>
    http.get<Skill[]>('/employee/skills/pending', { params }),
  completed: (params: URLSearchParams) =>
    http.get<Skill[]>('/employee/skills/completed', { params }),
  all: (params: URLSearchParams) =>
    http.get<Skill[]>('/employee/skills/all', { params }),
  completeAssessment: (data: {
    email: string;
    skillId: string;
    levelOfExpertise: number;
    comment: string;
  }) => http.post('/employee/skills/complete-assessment', data),
};

const organization = {
  allEmployees: (filter: FilterObj, params: URLSearchParams) =>
    http.post<Employee[]>('/admin/employees/all', filter, { params }),
  createTemplate: (template: FilterTemplate) =>
    http.post('/admin/templates/add', template),
  editTemplate: (params: any) =>
    http.put(`/admin/templates/edit/${params.templateId}`, params.template),
  allTemplates: () => http.get('/admin/templates/all'),
  getTemplate: (id: string | undefined) => http.get(`/admin/templates/${id}`),
  deleteTemplate: (id: string | undefined) =>
    http.delete(`/admin/templates/${id}`),
  allSkills: (params: URLSearchParams) =>
    http.get<OrganizationSkill[]>('/admin/skills/all', { params }),
  initialSkillNames: () => http.get<SimpleSkill[]>('/admin/skills/names/all'),
  allSkillGroups: (params: URLSearchParams) =>
    http.get<SkillGroup[]>('/admin/skill-groups/all', { params }),
  allEmployeeGroups: (params: URLSearchParams) =>
    http.get<EmployeeGroup[]>('/admin/employee-groups/all', { params }),
  initialEmployeeList: () =>
    http.get<SimpleEmployee[]>('/admin/employee-groups/employees/all'),
  getSkill: (id: string) => http.get(`/admin/skills/${id}`),
  createSkill: (data: CreateSkillParams) =>
    http.post('/admin/skills/create', data),
  editSkill: (params: EditSkillParams) =>
    http.put(`/admin/skills/${params.id}`, params.data),
  initialSkillGroups: () =>
    http.get<{ id: string; name: string }[]>('/admin/skill-groups/names/all'),
  searchSkillGroups: (inputValue: string) =>
    http.get(`/admin/skills/skill-groups?skillGroupName=${inputValue}`),
  deleteSkill: (id: string) => http.delete(`/admin/skills/${id}`),
  getSkillGroup: (id: string) => http.get(`/admin/skill-groups/${id}`),
  createSkillGroup: (data: SimpleSkillGroup) =>
    http.post('/admin/skill-groups/create', data),
  editSkillGroup: (params: EditSkillGroupsParams) =>
    http.put(`/admin/skill-groups/${params.id}`, params.data),
  deleteSkillGroup: (id: string) => http.delete(`/admin/skill-groups/${id}`),
  initialEmployeeGroupsList: () =>
    http.get<SimpleEmployeeGroupItem[]>('/admin/employee-groups/names/all'),
  searchEmployeeGroups: (inputValue: string) =>
    http.get(`/admin/employee-groups/search?name=${inputValue}`),
  assignSkillGroup: (data: {
    skillGroupIds: string[];
    employeeIds: string[];
  }) => http.post('/admin/skill-groups/assign/skill-groups', data),
  inviteEmployees: (emails: { employeeEmails: string[] }) =>
    http.post<{ numberOfValidEmails: number; status: string }>(
      '/admin/employees/invite',
      emails
    ),
  searchEmployees: (inputValue: string) =>
    http.get(`/admin/employee-groups/employees?email=${inputValue}`),
  getEmployeeGroup: (id: string) => http.get(`/admin/employee-groups/${id}`),
  editEmployeeGroup: (params: any) =>
    http.put(`/admin/employee-groups/${params.id}`, params.data),
  deleteEmployeeGroup: (id: string) =>
    http.delete(`/admin/employee-groups/${id}`),
  createEmployeeGroup: (params: any) =>
    http.post(
      `/admin/employee-groups/create?createdBy=${params.createdBy}`,
      params.data
    ),
  assignSkills: (data: { employeeIds: string[]; skillIds: string[] }) =>
    http.post('/admin/employee-groups/assign/skills', data),
  assignSkillGroups: (data: {
    employeeIds: string[];
    skillGroupIds: string[];
  }) => http.post('/admin/employee-groups/assign/skill-groups', data),
  assignSkillsToEmployees: (data: {
    employeeIds: string[];
    skillIds: string[];
  }) => http.post('/admin/skills/assign/employees', data),
  searchSkills: (inputValue: string) =>
    http.get(
      `/admin/employee-groups/assign/skills?skill=${encodeURIComponent(
        inputValue
      )}`
    ),
};

const api = {
  auth,
  skills,
  organization,
};

export default api;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../../store/store';
import ModalComponent from '../../../../../../components/Modal';
import SvgSolidPlus from '../../../../../../design/styles/icons/solid/SolidPlus';
import {
  createSkill,
  editSkill,
  getInitialSkillGroups,
  getSkill,
  searchSkillGroups,
} from '../../../../../../store/slices/organizationSkills/organizationSkills';
import { InputField } from '../../../../../../design/components/InputField/InputField';
import { AsyncAutocompleteField } from '../../../../../../design/components/AsyncAutocompleteField/AsyncAutocompleteField';
import { useToast } from '../../../../../../design/hooks/useToast';
import isMaxLength from '../../../../../../utils/isMaxLength/isMaxLength';
import { LightSearch } from '../../../../../../design/styles/icons/light';
import {
  MAX_CHAR_LIMIT_50 as maxLimitName,
  MAX_CHAR_LIMIT_255 as maxLimitLink,
} from '../../../../../../constants';
import { debounce, isEqual } from 'lodash';
import If from '../../../../../../components/If';
import { colors } from '../../../../../../design/styles/foundations/colors';
import { ReactComponent as Edit } from '../../../../../../assets/icons/edit.svg';
import {
  CreateSkillParams,
  EditSkillParams,
  SimpleSkill,
  SkillGroup,
} from '../../../../../../api/types';

const skillNameAlreadyExists = 'Skill already exists!';

interface Props {
  toastMessages: {
    title: {
      success: string;
      error: string;
    };
    description: {
      success: string;
      error: string;
    };
  };
  skillModalProps: {
    headerTitle: string;
    confirmButtonTitle: string;
  };
  isEditSkill?: boolean;
  id?: string;
  onSave?: () => void;
}

interface SkillGroupValue {
  value: string;
}

const SkillModal = ({
  toastMessages,
  skillModalProps,
  isEditSkill = false,
  id,
  onSave,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isInfoLinkValid, setIsInfoLinkValid] = useState<boolean>(true);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false);
  const skill = useSelector(
    (state: RootState) => state.organizationSkills?.skill
  );
  const initialSkillGroups = useSelector(
    (state: RootState) => state.organizationSkills.initialSkillGroups
  );

  const {
    control,
    watch,
    setValue,
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    mode: 'onChange',
  });

  const skillNameInput = watch('skillNameInput');
  const infoLinkInput = watch('infoLinkInput');
  const skillGroupsAutocompleteInput = watch('skillGroupsAutocompleteInput');

  const clearInputs = useCallback(() => {
    setValue('skillNameInput', '');
    setValue('infoLinkInput', '');
    setValue('skillGroupsAutocompleteInput', '');
    clearErrors([
      'skillNameInput',
      'infoLinkInput',
      'skillGroupsAutocompleteInput',
    ]);
  }, [clearErrors, setValue]);

  const handleClose = useCallback(() => {
    setIsModalOpen(false);
    setIsInfoLinkValid(true);
    clearInputs();
  }, [clearInputs]);

  const toastMessage = useMemo(() => {
    return {
      title: '',
      description: '',
    };
  }, []);

  const setToastMessage = useCallback(
    (status: string) => {
      switch (status) {
        case 'success':
          toastMessage.title = 'Successfully created a new skill';
          toastMessage.description = 'A new skill was created.';
          return toastMessage;
        case 'error':
          toastMessage.title = 'Failed!';
          toastMessage.description = 'The creation of a new skill failed.';
          return toastMessage;
      }
    },
    [toastMessage]
  );

  const saveActions = useCallback(
    (res: any) => {
      const responseStatus = !res.error ? 'success' : 'error';
      clearInputs();
      setIsInfoLinkValid(true);
      setToastMessage(responseStatus);
      toast({
        position: 'top',
        title: toastMessages.title[responseStatus],
        status: responseStatus,
        description: toastMessages.description[responseStatus],
        isClosable: true,
        duration: 7000,
        variant: 'subtle',
      });
    },
    [
      clearInputs,
      setToastMessage,
      toast,
      toastMessages.description,
      toastMessages.title,
    ]
  );

  const handleDispatch = useCallback(
    (res: any, saveAndCreate?: boolean) => {
      const errors = res?.payload?.response?.data?.errors;
      if (errors?.includes(skillNameAlreadyExists)) {
        setError('skillNameInput', {
          message: 'A skill with this name already exists',
        });
      } else {
        saveActions(res);
        !saveAndCreate && handleClose();
        if (onSave) {
          onSave();
        }
      }
      setIsSaveButtonDisabled(false);
    },
    [handleClose, onSave, saveActions, setError]
  );

  const handleEditArraysAutocomplete = useCallback(() => {
    const mappedSkills = skill?.skillGroups.map((e: SimpleSkill) => ({
      value: e.name,
      label: e.name,
      id: e.id,
    }));

    const skillName = skillNameInput;
    const infoLink = infoLinkInput;

    const addedItems = skillGroupsAutocompleteInput
      ? skillGroupsAutocompleteInput
          .filter(
            ({ value: nameOne }: SkillGroupValue) =>
              !mappedSkills.some(
                ({ value: nameTwo }: SkillGroupValue) => nameTwo === nameOne
              )
          )
          .map((skill: SimpleSkill) => skill.id)
      : '';

    const removedItems = mappedSkills
      ?.filter(
        ({ value: nameOne }: SkillGroupValue) =>
          !skillGroupsAutocompleteInput.some(
            ({ value: nameTwo }: SkillGroupValue) => nameTwo === nameOne
          )
      )
      ?.map((skill: SimpleSkill) => skill.id);

    return {
      skillName,
      infoLink,
      skillGroupsToAdd: addedItems,
      skillGroupsToRemove: removedItems,
    };
  }, [
    infoLinkInput,
    skillGroupsAutocompleteInput,
    skillNameInput,
    skill?.skillGroups,
  ]);

  const handleSaveDispatch = useCallback(
    (saveAndCreate?: boolean) => {
      setIsSaveButtonDisabled(true);
      const skill: CreateSkillParams = {
        skillName: skillNameInput?.trim(),
        infoLink: infoLinkInput?.trim(),
        skillGroups: skillGroupsAutocompleteInput
          ? skillGroupsAutocompleteInput.map(
              (item: { value: string; label: string; id: string }) => item.id
            )
          : [],
      };

      const params: EditSkillParams = isEditSkill
        ? {
            data: handleEditArraysAutocomplete(),
            id,
          }
        : {};

      const confirmAction = isEditSkill
        ? dispatch(editSkill(params))
        : dispatch(createSkill(skill));

      confirmAction.then((res: any) => {
        handleDispatch(res, saveAndCreate);
      });
    },
    [
      dispatch,
      handleDispatch,
      handleEditArraysAutocomplete,
      id,
      infoLinkInput,
      isEditSkill,
      skillGroupsAutocompleteInput,
      skillNameInput,
    ]
  );

  const handleSave = useCallback(() => {
    handleSaveDispatch();
  }, [handleSaveDispatch]);

  const handleSaveAndCreate = useCallback(() => {
    handleSaveDispatch(true);
  }, [handleSaveDispatch]);

  const isValidHttpUrl = useCallback((e: string) => {
    try {
      const url = new URL(e);
      if (url.protocol === 'http:' || url.protocol === 'https:') {
        setIsInfoLinkValid(true);
        return true;
      }
    } catch (err) {
      setIsInfoLinkValid(false);
      return false;
    }
  }, []);

  const handleAssignMapResponse = useCallback((res: any) => {
    return res
      ? res.map((e: any) => ({
          value: e.name,
          label: e.name,
          id: e.id,
        }))
      : [];
  }, []);

  const handleDebounce = debounce((value, cbFunction) => {
    dispatch(searchSkillGroups(value)).then((res: any) =>
      cbFunction(handleAssignMapResponse(res?.payload))
    );
  }, 300);

  const handleLoadingOptions = useCallback(
    (inputValue: string, cbFunction: (options: any) => void) => {
      if (inputValue.length > 1) {
        handleDebounce(inputValue, cbFunction);
      } else {
        cbFunction(handleAssignMapResponse(initialSkillGroups));
      }
    },
    [handleAssignMapResponse, handleDebounce, initialSkillGroups]
  );

  useEffect(() => {
    return () => {
      clearInputs();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDisabled = useMemo(() => {
    return (
      isSaveButtonDisabled || (
      !skillNameInput ||
      !infoLinkInput ||
      !isInfoLinkValid ||
      isMaxLength(skillNameInput, maxLimitName) ||
      isMaxLength(infoLinkInput, maxLimitLink) ||
      (!!id &&
        isEditSkill &&
        skillNameInput?.trim() === skill?.skillName?.trim() &&
        infoLinkInput?.trim() === skill?.infoLink?.trim() &&
        isEqual(
          skillGroupsAutocompleteInput?.map((item: any) => item.id).sort(),
          skill?.skillGroups?.map((item: any) => item.id).sort()
        ))
    ));
  }, [
    skillNameInput,
    infoLinkInput,
    isInfoLinkValid,
    id,
    isEditSkill,
    skill?.skillName,
    skill?.infoLink,
    skill?.skillGroups,
    skillGroupsAutocompleteInput,
    isSaveButtonDisabled
  ]);

  const SkillModalProps = {
    isModalOpen: isModalOpen,
    onCloseFunc: handleClose,
    headerTitle: skillModalProps.headerTitle,
    confirmButtonTitle: skillModalProps.confirmButtonTitle,
    confirmButtonFunc: handleSave,
    isDisabled,
    dataTestIdModal: 'skill-modal',
    dataTestIdModalOverlay: 'skill-modal-overlay',
    dataTestIdFooterCancelButton: 'skill-modal-cancel-button',
    dataTestIdFooterConfirmButton: 'skill-modal-save-button',
    showSecondConfirmButton: !isEditSkill,
    secondConfirmButtonTitle: 'Save and create new',
    secondConfirmButtonFunc: handleSaveAndCreate,
    isDisabledSecondConfirm: isDisabled,
    dataTestIdFooterSecondConfirmButton: 'skill-modal-saveAndCreateNew-button',
  };

  const openTheModal = useCallback(() => {
    dispatch(getInitialSkillGroups());
    setIsModalOpen(true);
    if (isEditSkill && id) {
      dispatch(getSkill(id)).then((res: any) => {
        setValue('skillNameInput', res?.payload?.skillName);
        setValue('infoLinkInput', res?.payload?.infoLink);
        setValue(
          'skillGroupsAutocompleteInput',
          res?.payload?.skillGroups?.map((e: SkillGroup) => ({
            value: e.name,
            label: e.name,
            id: e.id,
          }))
        );
      });
    }
  }, [dispatch, id, isEditSkill, setValue]);

  const inputArgs = {
    fontSize: 'lg',
    autoComplete: 'off',
  };

  return (
    <>
      <If condition={!!isEditSkill}>
        <Icon
          as={Edit}
          w="16px"
          h="16px"
          color={`${colors.greySolid[800]}`}
          _hover={{ color: colors.primary[500], cursor: 'pointer' }}
          className="organization-table-row-edit-icon skills"
          onClick={openTheModal}
          data-testid={`organization-edit-skill-button-${id}`}
          data-qa={`organization-edit-skill-button-${id}`}
        />
      </If>
      <If condition={!isEditSkill}>
        <Button
          leftIcon={<SvgSolidPlus width="20px" height="20px" />}
          colorScheme="primary"
          variant="solid"
          size="md"
          fontWeight="600"
          iconSpacing="4px"
          ml={3}
          onClick={openTheModal}
          data-testid="organization-create-skill-button"
          data-qa="organization-create-skill-button"
        >
          Create new
        </Button>
      </If>
      <ModalComponent {...SkillModalProps}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center"></Box>
        </Box>
        <Box>
          <InputField
            label="Skill name"
            placeholder="e.g. Javascript"
            isRequired
            errors={errors}
            {...register('skillNameInput', {
              required: 'Skill name field is required',
              validate: {
                maxLength: (v) =>
                  !isMaxLength(v, maxLimitName) ||
                  `Skill name cannot exceed more than ${maxLimitName} characters`,
              },
            })}
            {...inputArgs}
            data-qa="skill-modal-name-input"
            data-testid="skill-modal-name-input"
            defaultValue={skillNameInput}
          />
        </Box>
        <Box mt="24px">
          <InputField
            label="Info link"
            placeholder="Insert a website link"
            isRequired
            errors={errors}
            {...register('infoLinkInput', {
              required: 'Info link field is required',
              validate: {
                ValidityState: (v) =>
                  isValidHttpUrl(v) || 'Please enter a valid link',
                maxLength: (v) =>
                  !isMaxLength(v, maxLimitLink) ||
                  `Info link cannot exceed more than ${maxLimitLink} characters`,
              },
            })}
            {...inputArgs}
            data-qa="skill-modal-info-link-input"
            data-testid="skill-modal-info-link-input"
            defaultValue={infoLinkInput}
          />
        </Box>
        <Box mt="24px">
          <AsyncAutocompleteField
            label="Skill group"
            placeholder={
              <Flex alignItems="center">
                <Icon as={LightSearch} mr="1" fontSize={'16px'} />
                <Text>Search for groups</Text>
              </Flex>
            }
            hideDropdownIndicator
            numOfLabels={15}
            isMulti
            options={handleAssignMapResponse(initialSkillGroups)}
            loadOptions={handleLoadingOptions}
            name="skillGroupsAutocompleteInput"
            control={control}
            isClearable={false}
            id="skill-modal-skill-groups-autocomplete"
            defaultValue={skillGroupsAutocompleteInput}
          />
        </Box>
      </ModalComponent>
    </>
  );
};

export default SkillModal;

import React, { useCallback, useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import { LineTab as Tab } from '../../design/components/Tabs/LineTab';
import { colors } from '../../design/styles/foundations/colors';
import If from '../../components/If';
import { SkillsTabs } from '../../api/types';
import SkillsPendingTable from './components/SkillsPendingTable/SkillsPendingTable';
import SkillsAllTable from './components/SkillsAllTable';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { resetSkillsTotal } from '../../store/slices/skillsPending/skillsPending';
import SkillsCompletedTable from './components/SkillsCompletedTable';

const tabList = [
  { title: SkillsTabs.Pending, value: SkillsTabs.Pending },
  { title: SkillsTabs.Completed, value: SkillsTabs.Completed },
  { title: SkillsTabs.All, value: SkillsTabs.All },
];

const tabProps = {
  p: '4px',
  mr: 3,
  color: colors.greySolid[800],
  fontSize: 'xl',
};

const selectedTabLocalStorageItemName = 'selectedTabSkills';

const Skills = () => {
  const dispatch = useDispatch<AppDispatch>();
  const skillsTotal = useSelector(
    (state: RootState) => state.skillsPending.skillsTotal
  );
  const tab = localStorage.getItem(selectedTabLocalStorageItemName) || '0';
  const [selectedTab, setSelectedTab] = useState(parseInt(tab));

  const handleSelectTab = useCallback((value: number) => {
    localStorage.setItem(selectedTabLocalStorageItemName, `${value}`);
    setSelectedTab(value);
  }, []);

  useEffect(() => {
    handleSelectTab(parseInt(tab));
    return () => {
      handleSelectTab(0);
      dispatch(resetSkillsTotal());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box bg={colors.light.appBg} height="calc(100vh - 62px)">
      <Tabs isLazy index={selectedTab} onChange={handleSelectTab}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          bg="white"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p="28px 40px 20px 40px"
          >
            <Heading as="h1" size="4xl" fontWeight="700">
              Skills
            </Heading>
          </Box>
          <Box>
            <TabList px="40px">
              {tabList.map((item) => (
                <Tab
                  key={item.value}
                  {...tabProps}
                  data-testid={`skills-tab-${item.value}`}
                  data-qa={`skills-tab-${item.value}`}
                >
                  {item.title}
                  <If
                    condition={item.value === SkillsTabs.Pending && skillsTotal}
                  >
                    <Badge
                      colorScheme="yellow"
                      variant="solid"
                      borderRadius="4px"
                      fontWeight="600"
                      lineHeight="12px"
                      ml="6px"
                      p={1}
                      height="20px"
                      width="21px"
                    >
                      {skillsTotal}
                    </Badge>
                  </If>
                </Tab>
              ))}
            </TabList>
          </Box>
        </Box>
        <Box>
          <TabPanels>
            <TabPanel>
              <SkillsPendingTable />
            </TabPanel>
            <TabPanel>
              <SkillsCompletedTable />
            </TabPanel>
            <TabPanel>
              <SkillsAllTable />
            </TabPanel>
          </TabPanels>
        </Box>
      </Tabs>
    </Box>
  );
};

export default Skills;

import React from 'react';
import EmployeeGroupsModal from '../EmployeeGroupsModal';

interface Props {
  id: string;
  onSave?: () => void;
}

const EditEmployeeGroupsModal = ({ id, onSave }: Props) => {
  return (
    <>
      <EmployeeGroupsModal
        id={id}
        toastMessages={{
          title: {
            success: 'Successfully saved an employee group',
            error: 'Failed!',
          },
          description: {
            success: 'An employee group was saved.',
            error: 'The saving of an employee group failed.',
          },
        }}
        employeeGroupsModalProps={{
          headerTitle: 'Edit employee group',
          confirmButtonTitle: 'Save',
        }}
        isEditEmployeeGroup
        onSave={onSave}
      />
    </>
  );
};

export default EditEmployeeGroupsModal;

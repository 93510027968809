import { Text } from '@chakra-ui/react';
import { ChangeEvent, useCallback, useMemo } from 'react';

import { groupMultiCheckboxStyle } from './MenuItemMultiSelect.styles';
import { Checkbox, CheckboxProps } from '../../../components/Checkbox/Checkbox';

export type MenuItemCheckboxProps = {
  label?: string;
} & CheckboxProps;

export type MenuItemMultiSelectProps = {
  multiOptions: MenuItemCheckboxProps[];
  onChange: (options: MenuItemCheckboxProps[]) => void;
  title?: string;
};

export const MenuItemMultiSelect = ({
  multiOptions,
  onChange,
  title,
}: MenuItemMultiSelectProps) => {
  const allChecked = useMemo(() => {
    return multiOptions.every((item) => item.isChecked);
  }, [multiOptions]);

  const isIndeterminate = useMemo(
    () => multiOptions.some((item) => item.isChecked) && !allChecked,
    [allChecked, multiOptions]
  );

  const toggleAllCheckboxes = useCallback(() => {
    allChecked
      ? onChange(
          multiOptions.map((item) => ({
            ...item,
            isChecked: false,
          }))
        )
      : onChange(
          multiOptions.map((item) => ({
            ...item,
            isChecked: true,
          }))
        );
  }, [allChecked, multiOptions, onChange]);

  const handleOnChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(
      multiOptions.map((item) =>
        item.id === event.target.id
          ? { ...item, isChecked: !item.isChecked }
          : item
      )
    );
  };

  return (
    <>
      {title ? (
        <Checkbox
          {...groupMultiCheckboxStyle}
          isChecked={allChecked}
          isIndeterminate={isIndeterminate}
          onChange={toggleAllCheckboxes}
          size="md"
        >
          <Text fontWeight="semibold" textStyle="bodyS">
            {title}
          </Text>
        </Checkbox>
      ) : null}
      {multiOptions.map((item) => {
        return (
          <Checkbox
            id={item.id}
            key={item.id}
            {...groupMultiCheckboxStyle}
            isChecked={item.isChecked}
            onChange={handleOnChangeCheckbox}
            size="md"
          >
            <Text textStyle="bodyS">{item.label}</Text>
          </Checkbox>
        );
      })}
    </>
  );
};

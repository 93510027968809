import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api';
import { CreateSkillParams, EditSkillParams } from '../../../api/types';
import { URLParams } from '../../../types/params';
import URLParamsConvertor from '../../../utils/URLParamsConvertor';

const name = 'organizationSkills';

export const initialParams: URLParams = {
  page: 0,
  size: 20,
  sort: [],
  search: '',
};

export const initialState: any = {
  list: [],
  hasMore: true,
  params: initialParams,
  loading: false,
  error: null,
  initialSkillGroups: [],
  skillGroups: [],
  skill: {},
};

export const getAllSkills = createAsyncThunk(
  `${name}/getAllSkills`,
  async (params: URLParams) => {
    const response = await api.organization.allSkills(
      URLParamsConvertor(params)
    );
    return response;
  }
);

export const createSkill = createAsyncThunk(
  `${name}/createSkill`,
  async (skill: CreateSkillParams, { rejectWithValue }) => {
    try {
      const response = await api.organization.createSkill(skill);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editSkill = createAsyncThunk(
  `${name}/editSkill`,
  async (params: EditSkillParams, { rejectWithValue }) => {
    try {
      const response = await api.organization.editSkill(params);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteSkill = createAsyncThunk(
  `${name}/deleteSkill`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.organization.deleteSkill(id);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const assignSkillsToEmployees = createAsyncThunk(
  `${name}/assignSkillsToEmployees`,
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await api.organization.assignSkillsToEmployees(params);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const assignSkillsToEmployeeGroups = createAsyncThunk(
  `${name}/assignSkillsToEmployeeGroups`,
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await api.organization.assignSkills(params);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSkill = createAsyncThunk(
  `${name}/getSkill`,
  async (id: string) => {
    const response = await api.organization.getSkill(id);
    return response.data;
  }
);

export const getInitialSkillGroups = createAsyncThunk(
  `${name}/getInitialSkillGroups`,
  async () => {
    const response = await api.organization.initialSkillGroups();
    return response.data;
  }
);

export const searchSkillGroups = createAsyncThunk(
  `${name}/searchSkillGroups`,
  async (inputValue: string) => {
    const response = await api.organization.searchSkillGroups(inputValue);
    return response.data;
  }
);

const OrganizationSkillsSlice = createSlice({
  name,
  initialState,
  reducers: {
    updateParams: (state, { payload }) => {
      state.params = { ...state.params, [payload.prop]: payload.value };
    },
    resetTable: (state) => {
      state.list = initialState.list;
      state.hasMore = initialState.hasMore;
      state.loading = initialState.loading;
      state.params.page = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSkills.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllSkills.fulfilled, (state, action: any) => {
        state.list = [...state.list, ...action.payload.data.content];
        state.hasMore = !action.payload.data.last;
        state.loading = false;
      })
      .addCase(getAllSkills.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
        state.hasMore = false;
      })
      .addCase(getInitialSkillGroups.fulfilled, (state, action: any) => {
        state.initialSkillGroups = action.payload;
      })
      .addCase(searchSkillGroups.fulfilled, (state, action) => {
        state.skillGroups = action.payload;
      })
      .addCase(getSkill.fulfilled, (state, action) => {
        state.skill = action.payload;
      });
  },
});

export const { updateParams, resetTable } = OrganizationSkillsSlice.actions;
export const organizationSkillsSlice = OrganizationSkillsSlice;

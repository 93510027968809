import { get, isEmpty } from 'lodash';

export const ERROR_DENYLIST = [400, 409, 424];
export const SERVICE_UNAVAILABLE_CODE = 503;
export const ACCESS_DENIED_CODE = 403;
export const PAGE_NOT_FOUND = 404;
export const SESSION_EXPIRED_CODE = 401;

export const globalInterceptorErrorHandler = (
  error: any,
  dispatch: any,
  navigate: any,
  toast: any
) => {
  const responseStatus = get(error, 'response.status');
  if (responseStatus === ACCESS_DENIED_CODE || responseStatus === SESSION_EXPIRED_CODE) {
    localStorage.clear();
    window.location.href = '/';
  } else if (responseStatus === SERVICE_UNAVAILABLE_CODE) {
    toast({
      position: 'top',
      title: 'Error',
      status: 'error',
      description: error.response?.data?.errors[0]?.message,
      isClosable: true,
      duration: 7000,
      variant: 'subtle',
    });
    navigate('/error');
  } else if (responseStatus === PAGE_NOT_FOUND) {
    navigate('/error');
  }
  
  else if (
    !ERROR_DENYLIST.includes(responseStatus) &&
    !isEmpty(error)
  ) {
    navigate('/error');
  }

  return Promise.reject(error);
};

export default function getStatusValue(status: string) {
  switch (status) {
    case 'COMPLETED':
      return 'done';
    case 'PENDING':
      return 'pending';
    case 'NOT STARTED':
      return 'neutral';
  }
  return 'error';
};

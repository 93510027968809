import React from 'react';
import { useSelector } from 'react-redux';
import { SkillsTabs } from '../../../../api/types';
import {
  getAllPendingSkills,
  skillsPendingSlice,
} from '../../../../store/slices/skillsPending/skillsPending';
import { RootState } from '../../../../store/store';
import SkillsTable from '../SkillsTable';

function SkillsPendingTable() {
  const skillsState = useSelector((state: RootState) => state.skillsPending);

  return (
    <SkillsTable
      skillsSlice={skillsPendingSlice}
      getAllSlice={getAllPendingSkills}
      skillsType={SkillsTabs.Pending}
      skillsState={skillsState}
    />
  );
}

export default SkillsPendingTable;

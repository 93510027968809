import { BoxProps, FormLabelProps } from "@chakra-ui/react";

export const radioWrapperStyle: FormLabelProps = {
  display: "flex",
  cursor: "pointer",
  height: 8,
  width: "100%",
  margin: 0,
  color: "grey.900",
  textStyle: "bodyS",
  position: "relative",
};

export const selectedIndicatorStyle: BoxProps = {
  bgColor: "primary.500",
  height: 8,
  width: 0.5,
  borderRadius: "sm",
  position: "absolute",
  left: -2,
};

export const labelStyle = {
  width: "100%",
  borderRadius: "base",
  pt: 1.5,
  height: 8,
  pl: 1.5,
  _active: { backgroundColor: "light.appBg", borderRadius: "base" },
};

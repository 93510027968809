import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api';
import { UserSliceState } from '../../../api/types';
import { URLParams } from '../../../types/params';

const name = 'user';

export const initialParams: URLParams = {
  page: 0,
  size: 20,
  sort: [],
};

export const initialState: UserSliceState = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  image: '',
  status: '',
  role: '',
  profile: {
    name: '',
    email: '',
    role: '',
    location: null,
    status: '',
    employeeGroups: [],
    pendingSkills: [],
    completedSkills: [],
    levelOfExpertiseGroups: {
      One: [],
      Two: [],
      Three: [],
      Four: [],
      Five: [],
    },
  },
  history: [],
  initialProfile: {},
  hubLocations: [],
  loading: false,
  error: null,
  params: initialParams,
  list: [],
  hasMore: true,
};

export const signIn = createAsyncThunk(`${name}/login`, async (params: any) => {
  const response = await api.auth.login(params);
  return response;
});

export const signOut = createAsyncThunk(`${name}/logout`, async () => {
  const response = await api.auth.logout();
  return response;
});

export const getEmployeeProfile = createAsyncThunk(
  `${name}/getProfile`,
  async (id: any) => {
    const response = await api.auth.getEmployeeProfile(id);
    return response.data;
  }
);

export const getEmployeeHistory = createAsyncThunk(
  `${name}/getHistory`,
  async ({id , params}: any) => {
    const response = await api.auth.getEmployeeHistory(id, params);
    return response.data;
  }
);

export const updateEmployeeProfile = createAsyncThunk(
  `${name}/updateProfile`,
  async ({ data, id }: { data: any; id: any }) => {
    const response = await api.auth.updateEmployeeProfile(data, id);
    return response.data;
  }
);
export const getHubLocations = createAsyncThunk(
  `${name}/hubLocations`,
  async () => {
    const response = await api.auth.getHubLocations();
    return response.data;
  }
);

const UserSlice = createSlice({
  name,
  initialState,
  reducers: {
    updateParams: (state, { payload }) => {
      state.params = { ...state.params, [payload.prop]: payload.value };
    },
    updateEmployeeGroups: (state: any, { payload }) => {
      state.profile.employeeGroups = payload;
    },
    updateUserProfile: (state: any, { payload }) => {
      state.profile[payload.prop] = payload.value;
    },
    discardProfileChanges: (state: any) => {
      return {
        ...state,
        profile: {
          ...state.initialProfile,
        },
      };
    },
    resetTable: (state) => {
      state.list = initialState.list;
      state.hasMore = initialState.hasMore;
      state.loading = initialState.loading;
      state.history = initialState.history;
      state.params.page = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload.data,
          loading: false,
        };
      })
      .addCase(signIn.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        return {
          ...initialState,
        };
      })
      .addCase(getEmployeeProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmployeeProfile.fulfilled, (state, action) => {
        state.profile = { ...action.payload };
        state.initialProfile = { ...action.payload };
        state.loading = false;
      })
      .addCase(getEmployeeProfile.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
      })
      .addCase(getEmployeeHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmployeeHistory.fulfilled, (state, action) => {
        state.history = [ ...state.history, ...action.payload.content];
        state.hasMore = !action.payload.last;
        state.loading = false;

      })
      .addCase(getEmployeeHistory.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
        state.hasMore = false;
      })
      .addCase(getHubLocations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHubLocations.fulfilled, (state, action) => {
        state.hubLocations = action.payload;
        state.loading = false;
      })
      .addCase(getHubLocations.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
      });
  },
});
export const {
  updateEmployeeGroups,
  updateUserProfile,
  discardProfileChanges,
  resetTable,
  updateParams
} = UserSlice.actions;
export const userSlice = UserSlice;

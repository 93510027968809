import {
  Box,
  Flex,
  Icon,
  RadioProps,
  chakra,
  useRadio,
} from '@chakra-ui/react';

import {
  labelStyle,
  radioWrapperStyle,
  selectedIndicatorStyle,
} from './MenuItemSingleSelect.styles';
import { colors } from '../../../styles/foundations';
import { SVGProps } from 'react';

export type MenuItemSingleProps = RadioProps & {
  label: string;
  name: string;
  id?: string;
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  onItemClick?: (
    event:
      | React.MouseEvent<SVGElement, MouseEvent>
      | React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string | undefined,
    label: string,
    isDelete: boolean
  ) => void;
};
export const MenuItemSingle = ({
  label,
  name,
  id,
  icon,
  onItemClick,
  ...radioProps
}: MenuItemSingleProps) => {
  const { state, getInputProps, getCheckboxProps, htmlProps } =
    useRadio(radioProps);

  const { isChecked } = state;

  return (
    <Flex position="relative" className="organization-table-row">
      <chakra.label {...htmlProps} {...radioWrapperStyle}>
        <input {...getInputProps({})} hidden />
        {isChecked ? <Box {...selectedIndicatorStyle} /> : null}
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          className="organization-table-row"
          data-testid={`menu-item-single-${id}`}
          onClick={(event) => onItemClick?.(event, id, label, false)}
          bg={isChecked ? 'primary.100' : 'transparent'}
          _hover={{
            backgroundColor: !isChecked && 'light.appBg',
            borderRadius: 'base',
          }}
          {...getCheckboxProps()}
          {...labelStyle}
        >
          {label}
          {icon ? (
            <Icon
              as={icon}
              fontSize="17px"
              color={`${colors.greySolid[800]}`}
              _hover={{ color: colors.primary[500], cursor: 'pointer' }}
              className="organization-table-row-delete-icon "
              mr="8px"
              onClick={(event) => onItemClick?.(event, id, label, true)}
              data-testid={`menu-item-single-icon-${id}`}
            />
          ) : (
            ''
          )}
        </Box>
      </chakra.label>
    </Flex>
  );
};

import { useCallback, useMemo, useState } from 'react';
import { Box, chakra, Icon, Input, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../../store/store';
import ModalComponent from '../../../../../../components/Modal';
import { useToast } from '../../../../../../design/hooks/useToast';
import isMaxLength from '../../../../../../utils/isMaxLength/isMaxLength';
import { completeAssessment } from '../../../../../../store/slices/skillsPending/skillsPending';
import { colors } from '../../../../../../design/styles/foundations';
import { Skill, SkillsTabs } from '../../../../../../api/types';
import AssessmentStars from '../../../../../../components/AssessmentStars';
import { MAX_CHAR_LIMIT_255 as maxLimit } from '../../../../../../constants';
import { Button } from '../../../../../../design/components/Button/Button';
import { ReactComponent as PlayIcon } from '../../../../../../assets/icons/play.svg';
import SolidInfo from '../../../../../../design/styles/icons/solid/SolidInfo';
import If from '../../../../../../components/If';
interface Props {
  skills: Skill[];
  index?: number;
  selectedSkills: Skill[];
  skillsType?: string;
  onCompleteAssessment?: () => void;
  showByIndex?: boolean;
  isFromButton?: boolean;
}

interface ClickedStars {
  id: string;
  value: string;
  isClicked?: boolean;
}

const CompleteAssessmentModal = ({
  skills,
  index,
  selectedSkills,
  skillsType,
  onCompleteAssessment,
  showByIndex,
  isFromButton,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const userEmail = useSelector((state: RootState) => state.user.email);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [assessmentValue, setAssessmentValue] = useState<any>([]);
  const [assessedSkill, setAssessedSkill] = useState<any>([]);
  const [commentValue, setCommentValue] = useState<any>([]);

  const clearInputs = useCallback(() => {
    setAssessmentValue([]);
    setCommentValue([]);
  }, []);

  const showSingleMessage = useMemo(() => {
    return (
      !isFromButton ||
      assessmentValue.filter((item: ClickedStars) => item.isClicked)?.length ===
        1
    );
  }, [assessmentValue, isFromButton]);

  const toastMessage = useMemo(() => {
    return {
      title: '',
      description: '',
    };
  }, []);

  const setToastMessage = useCallback(
    (status: string) => {
      switch (status) {
        case 'success':
          toastMessage.title = showSingleMessage
            ? 'Successfully completed assessment'
            : 'Successfully completed assessments';
          toastMessage.description = showSingleMessage
            ? 'An assessment was completed.'
            : 'Assessments were completed.';
          return toastMessage;
        case 'error':
          toastMessage.title = 'Failed!';
          toastMessage.description = showSingleMessage
            ? 'The completion of an assessment failed.'
            : 'The completion of assessments failed.';
          return toastMessage;
      }
    },
    [showSingleMessage, toastMessage]
  );

  const handleClose = useCallback(() => {
    setIsModalOpen(false);
    clearInputs();
  }, [clearInputs]);

  const saveActions = useCallback(
    (res: any) => {
      const responseStatus = !res.error ? 'success' : 'error';
      clearInputs();
      setToastMessage(responseStatus);
      toast({
        position: 'top',
        title: toastMessage.title,
        status: responseStatus,
        description: toastMessage.description,
        isClosable: true,
        duration: 7000,
        variant: 'subtle',
      });
    },
    [
      clearInputs,
      setToastMessage,
      toast,
      toastMessage.description,
      toastMessage.title,
    ]
  );

  const handleSave = useCallback(() => {
    const clickedSkills = isFromButton
      ? selectedSkills.filter((item) =>
          assessmentValue
            .filter((item: ClickedStars) => item.isClicked)
            .some((clicked: ClickedStars) => clicked.id === item.id)
        )
      : selectedSkills;

    const data: any = {
      email: userEmail,
      skills:
        selectedSkills &&
        clickedSkills.map((item) => {
          return {
            skillId: item.id,
            levelOfExpertise: assessmentValue.find(
              (assessmentItem: any) => assessmentItem.id === item.id
            ).value,
            comment: commentValue.find(
              (commentItem: any) => commentItem.id === item.id
            ).value,
          };
        }),
    };
    dispatch(completeAssessment(data)).then((res: any) => {
      saveActions(res);
      handleClose();
      if (!res.error && onCompleteAssessment) {
        onCompleteAssessment();
      }
    });
  }, [
    assessmentValue,
    commentValue,
    dispatch,
    handleClose,
    isFromButton,
    onCompleteAssessment,
    saveActions,
    selectedSkills,
    userEmail,
  ]);

  const isDisabled = useMemo(() => {
    return (
      (assessedSkill.every((item: any) => !item.isClicked) && isFromButton) ||
      commentValue.some((item: any) => isMaxLength(item.value || '', maxLimit))
    );
  }, [assessedSkill, commentValue, isFromButton]);

  const SkillsCompleteAssessmentModalProps = {
    isModalOpen: isModalOpen,
    onCloseFunc: handleClose,
    headerTitle: 'Complete assessment',
    confirmButtonTitle: 'Save',
    confirmButtonFunc: handleSave,
    isDisabled,
    dataTestIdModal: 'skills-complete-assessment-modal',
    dataTestIdModalOverlay: 'skills-complete-assessment-modal-overlay',
    dataTestIdFooterCancelButton:
      'skills-complete-assessment-modal-footer-cancel-button',
    dataTestIdFooterConfirmButton:
      'skills-complete-assessment-modal-footer-save-button',
  };

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
    const assessements =
      selectedSkills &&
      selectedSkills.map((item) => {
        return {
          id: item.id,
          value: item.levelOfExpertise,
        };
      });
    setAssessmentValue(assessements);
    const comments =
      selectedSkills &&
      selectedSkills.map((item) => {
        return {
          id: item.id,
          value: item.comment,
        };
      });
    setCommentValue(comments);
    const items =
      selectedSkills &&
      selectedSkills.map((item) => {
        return {
          id: item.id,
          isClicked: false,
        };
      });
    setAssessedSkill(items);
  }, [selectedSkills]);

  const setAssessment = useCallback(
    (id: string, value: number[]) => {
      const assessment = [...assessmentValue];
      assessment.find((item: any) => item.id === id).value = value[0];
      setAssessmentValue(assessment);
      const clickedStars = [...assessmentValue];
      clickedStars.find((item: any) => item.id === id).isClicked = true;
      setAssessedSkill(clickedStars);
    },
    [assessmentValue]
  );

  const setComment = useCallback(
    (id: string, value: string) => {
      const comment = [...commentValue];
      comment.find((item: any) => item.id === id).value = value;
      setCommentValue(comment);
    },
    [commentValue]
  );

  const handleStarsClick = useCallback(
    (value: number[]) => {
      setIsModalOpen(true);
      setAssessmentValue([
        {
          id: selectedSkills[0].id,
          value: value[0] || selectedSkills[0].levelOfExpertise,
        },
      ]);
      setCommentValue([
        {
          id: selectedSkills[0].id,
          value: selectedSkills[0].comment,
        },
      ]);
    },
    [selectedSkills]
  );

  const isClickedItem = useCallback(
    (skillId: string) => {
      return assessedSkill.find((item: any) => item.id === skillId)?.isClicked;
    },
    [assessedSkill]
  );

  return (
    <>
      <If condition={!isFromButton}>
        <AssessmentStars
          skills={skills}
          skill={selectedSkills[0]}
          index={index || 0}
          dataTestId="skills-table-update-hover-rating"
          dataQa="skills-table-update-hover-rating"
          setAssessment={handleStarsClick}
          showByIndex
        />
      </If>
      <If condition={!!isFromButton}>
        <Button
          pr="16px"
          py="9px"
          size="lg"
          isDisabled={
            !skills.length || (skills.length > 0 && !selectedSkills.length)
          }
          leftIcon={chakra(PlayIcon, {
            baseStyle: {
              width: '10px!important',
              ml: '8px',
              mr: '6px',
            },
          })}
          onClick={handleOpenModal}
          data-testid="skills-table-complete-assessment"
          data-qa="skills-table-complete-assessment"
        >
          {skillsType === SkillsTabs.Completed
            ? 'Complete again'
            : 'Complete now'}
        </Button>
      </If>
      <ModalComponent {...SkillsCompleteAssessmentModalProps} setMaxHeight>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center"></Box>
        </Box>
        <Text color={colors.greySolid[900]} fontSize="lg" fontWeight="regular">
          Please rate your level of experience
        </Text>
        {selectedSkills &&
          selectedSkills.map((skill, index) => (
            <Box key={skill.id} mb="24px" maxHeight="200px" overflow="auto">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt="24px"
                mb="12px"
              >
                <Text
                  color={colors.greySolid[900]}
                  fontSize="md"
                  fontWeight="semibold"
                >
                  {skill.name}
                </Text>
                <AssessmentStars
                  skills={skills}
                  skill={skill}
                  index={index}
                  dataTestId="skills-complete-assessment-modal-star"
                  dataQa="skills-complete-assessment-modal-star"
                  assessmentValue={assessmentValue.map(
                    (assessment: { id: string; value: string[] }) =>
                      assessment.value
                  )}
                  setAssessment={(value) => setAssessment(skill.id, value)}
                  showByIndex={showByIndex}
                  isFromButton={isFromButton}
                  isClickedItem={isClickedItem(skill.id)}
                />
              </Box>
              <Box>
                <Text mb="12px" color={colors.greySolid[900]} fontSize="md">
                  Leave a comment (optional)
                </Text>
                <Input
                  placeholder="Tell us more details..."
                  data-qa="skills-complete-assessment-modal-skill-comment-input"
                  data-testid="skills-complete-assessment-modal-skill-comment-input"
                  defaultValue={
                    commentValue.find((item: any) => item.id === skill.id)
                      ?.value
                  }
                  onChange={(e) => setComment(skill.id, e.target.value)}
                  fontSize="lg"
                  autoComplete="off"
                  margin="3px"
                  width="calc(100% - 6px)"
                  isInvalid={isMaxLength(
                    commentValue.find((item: any) => item.id === skill.id)
                      ?.value || '',
                    maxLimit
                  )}
                  errorBorderColor={colors.red[100]}
                  disabled={isFromButton ? !isClickedItem(skill.id) : false}
                />
                <If
                  condition={isMaxLength(
                    commentValue.find((item: any) => item.id === skill.id)
                      ?.value || '',
                    maxLimit
                  )}
                >
                  <Box display="flex" alignItems="center" marginTop="1">
                    <Icon as={SolidInfo} color={colors.red[500]} height="3.5" />
                    <Text color={colors.red[700]} textStyle="bodyXS" ml="1">
                      {`Comment cannot exceed more than ${maxLimit} characters`}
                    </Text>
                  </Box>
                </If>
              </Box>
            </Box>
          ))}
      </ModalComponent>
    </>
  );
};
export default CompleteAssessmentModal;

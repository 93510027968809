export const MAX_CHAR_LIMIT_50 = 50;
export const MAX_CHAR_LIMIT_255 = 255;
export const MAX_CHAR_EMAIL_LIMIT = 50;
export const MAX_BULK_EMAIL_LIMIT = 100;
export const LEVEL_OF_EXPERTISE = [
  {
    id: '1',
    name: 'Level 1',
    label: 'Level 1',
  },
  {
    id: '2',
    name: 'Level 2',
    label: 'Level 2',
  },
  {
    id: '3',
    name: 'Level 3',
    label: 'Level 3',
  },
  {
    id: '4',
    name: 'Level 4',
    label: 'Level 4',
  },
  {
    id: '5',
    name: 'Level 5',
    label: 'Level 5',
  },
];

export const MULTI_OPTIONS = [
  { id: 'active', name: 'active', isChecked: false, label: 'Active' },
  {
    id: 'deactivated',
    name: 'deactivated',
    isChecked: false,
    label: 'Deactivated',
  },
  { id: 'invited', name: 'invited', isChecked: false, label: 'Invited' },
];

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tr, Td, Tag, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { Checkbox } from '../../../../design/components/Checkbox/Checkbox';
import Table from '../../../../components/Wrappers/Table';
import { Column } from '../../../../components/Wrappers/Table/Table';
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll';
import { URLParams } from '../../../../types/params';
import {
  getAllSkillGroups,
  organizationSkillGroupsSlice,
} from '../../../../store/slices/organizationSkillGroups/organizationSkillGroups';
import { AppDispatch, RootState } from '../../../../store/store';
import { SimpleSkill, SkillGroup } from '../../../../api/types';
import { colors } from '../../../../design/styles/foundations/colors';
import showMoreIndicator from '../../../../utils/showMoreIndicator';
import '../../../../assets/styles/main.scss';
import EditSkillGroupsModal from './components/EditSkillGroupsModal';
import DeleteSkillGroupsModal from './components/DeleteSkillGroupsModal';
import { debounce } from 'lodash';
import SearchInput from '../../../../components/SearchInput';
import { selectAll, selectItems } from '../../../../utils/checkboxGroupActions';
import AssignSkillGroupsToEmployeeModal from './components/AssignSkillGroupsToEmployeeModal';
import AssignEmployeeGroupsModal from './components/AssignEmployeeGroupsModal';

const HEADER_STYLE: React.CSSProperties = {
  top: '0px',
};

const TABLE_PADDING = 20;

const TABLE_COLUMNS_SKILL_GROUPS: Column[] = [
  {
    name: 'Name',
    sortValue: 'name',
    style: HEADER_STYLE,
  },
  {
    name: 'Skills',
    sortValue: '',
    style: HEADER_STYLE,
    disableSort: true,
  },
  {
    name: '',
    sortValue: '',
    style: HEADER_STYLE,
    disableSort: true,
  },
];

function SkillGroupsTable() {
  const dispatch = useDispatch<AppDispatch>();
  const skillGroups = useSelector(
    (state: RootState) => state.organizationSkillGroups.list
  );
  const hasMore = useSelector(
    (state: RootState) => state.organizationSkillGroups.hasMore
  );
  const loading = useSelector<RootState, boolean>(
    (state: RootState) => state.organizationSkillGroups.loading
  );
  const params = useSelector<RootState, URLParams>(
    (state: RootState) => state.organizationSkillGroups.params
  );
  const containerRef: any = useRef<HTMLHtmlElement>();

  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  const handleCall = useCallback(
    ({ page }: any) => {
      dispatch(getAllSkillGroups(params));
      dispatch(
        organizationSkillGroupsSlice.actions.updateParams({
          prop: 'page',
          value: page + 1,
        })
      );
    },
    [dispatch, params]
  );

  const handleSort = useCallback(
    (srt: any) => {
      dispatch(
        organizationSkillGroupsSlice.actions.updateParams({
          prop: 'sort',
          value: srt,
        })
      );
      dispatch(organizationSkillGroupsSlice.actions.resetTable());
    },
    [dispatch]
  );

  const handleDebounce = debounce((value) => {
    dispatch(
      organizationSkillGroupsSlice.actions.updateParams({
        prop: 'search',
        value,
      })
    );
    dispatch(organizationSkillGroupsSlice.actions.resetTable());
  }, 300);

  const handleSearch = useCallback(
    (value: string) => {
      handleDebounce(value);
    },
    [handleDebounce]
  );

  useEffect(() => {
    return () => {
      dispatch(organizationSkillGroupsSlice.actions.resetTable());
    };
  }, [dispatch]);

  const { Trigger } = useInfiniteScroll({
    rows: skillGroups,
    next: handleCall,
    container: containerRef.current,
    hasMore,
    loading,
    page: params.page,
    size: params.size,
    sort: params.sort,
  });

  const tableHeight = useCallback(() => {
    return (
      containerRef?.current?.getBoundingClientRect().top + TABLE_PADDING || 0
    );
  }, []);

  const handleSelectAllSkillGroups = useCallback(
    (isSelected: boolean) => {
      const checkedItemsList = selectAll(isSelected, skillGroups);
      setCheckedItems(checkedItemsList);
    },
    [skillGroups]
  );

  const handleSelectSkillGroups = useCallback(
    (id: string, e: React.BaseSyntheticEvent) => {
      const checkedItemsList = selectItems(id, e, checkedItems);
      setCheckedItems(checkedItemsList);
    },
    [checkedItems]
  );

  const renderWrapItem = useCallback((skill: SimpleSkill) => {
    return (
      <WrapItem key={skill.id}>
        <Tag
          variant="subtle"
          bg={colors.primary[200]}
          color={colors.primary[800]}
          p="4px 8px"
          borderRadius="3px"
          fontSize="sm"
          fontWeight="normal"
        >
          {skill.name}
        </Tag>
      </WrapItem>
    );
  }, []);

  const reloadTable = useCallback(() => {
    dispatch(organizationSkillGroupsSlice.actions.resetTable());
    const newParams = { ...params, page: 0 };
    dispatch(getAllSkillGroups(newParams));
    dispatch(
      organizationSkillGroupsSlice.actions.updateParams({
        prop: 'page',
        value: 1,
      })
    );
  }, [dispatch, params]);

  const renderRow = useCallback(
    (row: SkillGroup, index: number) => {
      return (
        <Tr
          key={row.id + index}
          data-testid="organization-skill-group-table-row"
          data-qa="organization-skill-group-table-row"
          className="organization-table-row"
          bgColor={checkedItems.includes(row.id) ? colors.primary[100] : ''}
        >
          <Td width="0" pr="0" mr="0">
            <Checkbox
              isChecked={checkedItems.includes(row.id)}
              onChange={(e) => handleSelectSkillGroups(row.id, e)}
              data-testid={`organization-skill-group-table-row-checkbox-${row.id}`}
              data-qa={`organization-skill-group-table-row-checkbox-${row.id}`}
              className="chkbox-shadow"
            />
          </Td>

          <Td width="270px">{row.name}</Td>

          <Td id={`organization-skill-group-table-row-skills-${row.id}`}>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Wrap height="24px">
                {row.skills.map((skill: SimpleSkill) => renderWrapItem(skill))}
              </Wrap>

              <Box ml="5px">
                <Text
                  color={colors.primary[800]}
                  fontSize="sm"
                  fontWeight="semibold"
                >
                  {showMoreIndicator(
                    row.id,
                    'organization-skill-group-table-row-skills'
                  )}
                </Text>
              </Box>
            </Box>
          </Td>
          <Td width="20px">
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <EditSkillGroupsModal id={row.id} onSave={reloadTable} />
              <DeleteSkillGroupsModal id={row.id} onDelete={reloadTable} />
            </Box>
          </Td>
        </Tr>
      );
    },
    [checkedItems, reloadTable, handleSelectSkillGroups, renderWrapItem]
  );
  return (
    <>
      <Box display="flex" px="25px" mt="25px" mb="30px">
        <SearchInput
          placeholder="Search a skill group by name"
          defaultValue={params.search}
          handleSearch={handleSearch}
          dataTestId="organization-skill-group-table-search-input"
          dataQa="organization-skill-group-table-search-input"
        />
      </Box>
      <Box
        px="25px"
        overflow="auto"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height={`calc(100vh - ${tableHeight()}px)`}
        ref={containerRef}
      >
        <Table
          data-testid="organization-skill-group-table"
          data-qa="organization-skill-group-table"
          columns={TABLE_COLUMNS_SKILL_GROUPS}
          rows={skillGroups}
          sort={params.sort}
          onSort={handleSort}
          Trigger={Trigger}
          stickyHeader
          showCheckbox
          isCheckboxDisabled={!skillGroups.length}
          isSelectedAll={
            checkedItems.length === skillGroups.length && skillGroups.length > 0
          }
          onSelectAll={handleSelectAllSkillGroups}
          renderRow={renderRow}
        />
        <Box display="flex" className="organization-table-row-bottom">
          <AssignSkillGroupsToEmployeeModal
            selectedIds={checkedItems}
            assignType={'employee'}
            isDisabled={
              !skillGroups.length ||
              Boolean(skillGroups.length && !checkedItems.length)
            }
            onSave={() => setCheckedItems([])}
          />
          <Box ml="8px">
            <AssignEmployeeGroupsModal
              selectedIds={checkedItems}
              assignType={'employeeGroupsToSkillGroups'}
              isDisabled={
                !skillGroups.length ||
                Boolean(skillGroups.length && !checkedItems.length)
              }
              onSave={() => setCheckedItems([])}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SkillGroupsTable;

import React from 'react';
import { Box, Heading, Image, Button, Text } from '@chakra-ui/react';
import { colors } from '../../design/styles/foundations/colors';
import loginBackground from './../../assets/images/login.svg';
import logo from './../../assets/images/logo.svg';
import { ReactComponent as GoogleLogo } from '../../assets/icons/google.svg';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { signIn } from '../../store/slices/user/user';
import { useGoogleLogin } from '@react-oauth/google';

function Login() {
  const dispatch = useDispatch<AppDispatch>();

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      dispatch(signIn(codeResponse.access_token));
    },
    onError: (error) => console.log('Login Failed:', error),
  });

  return (
    <Box
      bg={colors.light.appBg}
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          bgImg={`url(${loginBackground})`}
          bgSize="contain"
          bgRepeat="repeat-x"
          bgPosition="left top"
          width="100%"
          height="102px"
        ></Box>
      </Box>
      <Box
        height="calc(100vh - 190px)"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Image src={logo} alt="Logo" data-qa="login-logo" />

        <Heading
          as="h3"
          size="2xl"
          fontWeight="700"
          my="32px"
          color={colors.greySolid[900]}
          data-qa="login-welcome-text"
        >
          Welcome to SkilZTrakR
        </Heading>

        <Box display="flex">
          <Button
            leftIcon={
              <GoogleLogo
                width="52px"
                height="52px"
                style={{ borderRadius: '4px' }}
              />
            }
            iconSpacing="0"
            variant="solid"
            width="242px"
            height="56px"
            fontSize="15px"
            display="flex"
            justifyContent="space-between"
            paddingLeft="2px"
            onClick={() => loginWithGoogle()}
            data-testid="login-google-button"
            data-qa="login-google-button"
          >
            <Text mr="15px" fontWeight="600">
              Sign in with Google
            </Text>
          </Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" mb="24px">
        <Text
          fontSize="lg"
          width="330px"
          textAlign="center"
          fontWeight="300"
          color={colors.greySolid[700]}
          data-qa="login-terms-text"
        >
          By signing up, you agree to the{' '}
          <span style={{ fontWeight: '600' }}>
            Terms of Service and Data Processing Agreement.
          </span>
        </Text>
      </Box>
    </Box>
  );
}

export default Login;

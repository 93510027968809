import React from 'react';
import AssignModal from '../../../../../../components/AssignModal';
import {
  getInitialEmployeeList,
  searchEmployees,
} from '../../../../../../store/slices/organizationEmployeeGroups/organizationEmployeeGroups';
import { assignSkillsToEmployees } from '../../../../../../store/slices/organizationSkills/organizationSkills';

interface Props {
  selectedIds: string[];
  assignType: string;
  isDisabled: boolean;
  onSave?: () => void;
}

const AssignSkillsToEmployeeModal = ({
  selectedIds,
  assignType,
  isDisabled,
  onSave,
}: Props) => {
  return (
    <AssignModal
      selectedIds={selectedIds}
      assignType={assignType}
      assignModalProps={{
        headerTitle: 'Assign to employee',
        confirmButtonTitle: 'Assign',
        placeholderText: 'Search for employees by email',
        dataTestIdModal: 'organization-assign-skills-to-employee-modal',
        dataTestIdModalOverlay:
          'organization-assign-skills-to-employee-modal-overlay',
        dataTestIdFooterCancelButton:
          'organization-assign-skills-to-employee-modal-footer-cancel-button',
        dataTestIdFooterConfirmButton:
          'organization-assign-skills-to-employee-modal-footer-assign-button',
      }}
      toastMessages={{
        title: {
          success: 'Successfully assigned skills',
          error: 'Failed!',
        },
        description: {
          success: 'Skills were assigned to the selected employees.',
          error: 'The assigning skills to employees failed.',
        },
      }}
      assignModalTooltipText="For assigning to multiple employees use comma to separate two or more employees"
      assignModalLabel="Email address"
      assignAutocompleteInputId="organization-assign-skills-to-employee-autocomplete"
      assignSliceAction={assignSkillsToEmployees}
      assignSearchSliceAction={searchEmployees}
      assignInitialListSliceAction={getInitialEmployeeList}
      dataTestIdOpenModalButton="organization-assign-skills-to-employee-button"
      isDisabled={isDisabled}
      onSave={onSave}
    />
  );
};

export default AssignSkillsToEmployeeModal;

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  organizationSkillsSlice,
  getAllSkills,
} from '../../../../../../store/slices/organizationSkills/organizationSkills';
import { AppDispatch, RootState } from '../../../../../../store/store';
import { URLParams } from '../../../../../../types/params';
import SkillModal from '../SkillModal/SkillModal';

const CreateSkillModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const params = useSelector<RootState, URLParams>(
    (state: RootState) => state.organizationSkills.params
  );

  const reloadTable = useCallback(() => {
    dispatch(organizationSkillsSlice.actions.resetTable());
    const newParams = { ...params, page: 0 };
    dispatch(getAllSkills(newParams));
    dispatch(
      organizationSkillsSlice.actions.updateParams({
        prop: 'page',
        value: 1,
      })
    );
  }, [dispatch, params]);

  return (
    <>
      {' '}
      <SkillModal
        toastMessages={{
          title: {
            success: 'Successfully created a new skill',
            error: 'Failed!',
          },
          description: {
            success: 'A new skill was created.',
            error: 'The creation of a new skill failed.',
          },
        }}
        skillModalProps={{
          headerTitle: 'Create new skill',
          confirmButtonTitle: 'Save',
        }}
        onSave={reloadTable}
      />
    </>
  );
};

export default CreateSkillModal;

import SkillGroupsModal from '../SkillGroupsModal';

interface Props {
  id: string;
  onSave?: () => void;
}

const EditSkillGroupsModal = ({ id, onSave }: Props) => {
  return (
    <>
      <SkillGroupsModal
        id={id}
        toastMessages={{
          title: {
            success: 'Successfully saved a skill group',
            error: 'Failed!',
          },
          description: {
            success: 'A skill group was saved.',
            error: 'The saving of a skill group failed.',
          },
        }}
        skillGroupsModalProps={{
          headerTitle: 'Edit skill group',
          confirmButtonTitle: 'Save',
        }}
        isEditSkillGroup
        onSave={onSave}
      />
    </>
  );
};

export default EditSkillGroupsModal;

import React from 'react';
import AssignModal from '../../../../../../components/AssignModal';
import {
  assignSkills,
  searchSkills,
} from '../../../../../../store/slices/organizationEmployeeGroups/organizationEmployeeGroups';
import { getInitialSkillNames } from '../../../../../../store/slices/organizationSkillGroups/organizationSkillGroups';

interface Props {
  selectedIds: string[];
  assignType: string;
  isDisabled: boolean;
  onSave?: () => void;
}

const AssignSkillsModal = ({
  selectedIds,
  assignType,
  isDisabled,
  onSave,
}: Props) => {
  return (
    <AssignModal
      selectedIds={selectedIds}
      assignType={assignType}
      toastMessages={{
        title: {
          success: 'Successfully assigned skill',
          error: 'Failed!',
        },
        description: {
          success: 'Skills were assigned to the selected employee groups.',
          error: 'The assigning a skill to employee groups failed.',
        },
      }}
      assignModalProps={{
        headerTitle: 'Assign skills',
        confirmButtonTitle: 'Assign',
        placeholderText: 'Search for skills',
        dataTestIdModal: 'assign-skills-modal',
        dataTestIdModalOverlay: 'assign-skills-modal-overlay',
        dataTestIdFooterCancelButton: 'assign-skills-modal-cancel-button',
        dataTestIdFooterConfirmButton: 'assign-skills-modal-confirm-button',
      }}
      assignModalLabel="Skills"
      assignAutocompleteInputId="assign-skills-to-employee-groups-autocomplete"
      assignSliceAction={assignSkills}
      assignSearchSliceAction={searchSkills}
      assignInitialListSliceAction={getInitialSkillNames}
      dataTestIdOpenModalButton="assign-skills-to-employee-groups-button"
      isDisabled={isDisabled}
      onSave={onSave}
    />
  );
};

export default AssignSkillsModal;

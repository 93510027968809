import React from 'react';
import DeleteModal from '../../../../../../components/DeleteModal';
import { deleteSkill } from '../../../../../../store/slices/organizationSkills/organizationSkills';

interface Props {
  id: string;
  onDelete?: () => void;
}

const DeleteSkillModal = ({ id, onDelete }: Props) => {
  return (
    <DeleteModal
      id={id}
      toastMessages={{
        title: {
          success: 'Successfully deleted a skill',
          error: 'Failed!',
        },
        description: {
          success: 'A skill was deleted.',
          error: 'The deletion of a skill failed.',
        },
      }}
      deleteModalProps={{
        headerTitle: 'Delete skill',
        confirmButtonTitle: 'Delete',
        dataTestIdModal: 'organization-delete-skill-modal',
        dataTestIdModalOverlay: 'organization-delete-skill-modal-overlay',
        dataTestIdFooterCancelButton:
          'organization-delete-skill-modal-footer-cancel-button',
        dataTestIdFooterConfirmButton:
          'organization-delete-skill-modal-footer-delete-button',
      }}
      deleteModalBodyText="Are you sure you want to delete skill?"
      deleteModalBodyAdditionalText="* Deleting cannot be undone and all data within will be lost."
      deleteIconClassName="organization-table-row-delete-icon"
      deleteIconTestId="organization-delete-skill-button"
      deleteSliceAction={deleteSkill}
      onDelete={onDelete}
    />
  );
};

export default DeleteSkillModal;

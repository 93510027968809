import React, { useEffect, useCallback, useRef } from 'react';
import {
  Flex,
  Text,
  Box,
  Avatar,
  Tr,
  Td,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { URLParams } from '../../../../types/params';
import { colors } from '../../../../design/styles/foundations';
import Table from '../../../../components/Wrappers/Table';
import { Column } from '../../../../components/Wrappers/Table/Table';
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll';
import { History } from '../../../../api/types';
import {
  getEmployeeHistory,
  updateParams,
  resetTable
} from '../../../../store/slices/user/user';



const HEADER_STYLE: React.CSSProperties = {
  top: '0px',
  display: 'none',
};

const TABLE_PADDING = 20;

const TABLE_COLUMNS_HISTORY: Column[] = [
  {
    name: 'Message',
    style: HEADER_STYLE,
    disableSort: true,
  },
  {
    name: 'Time',
    style: HEADER_STYLE,
    disableSort: true,
  },
];

const ProfileHistory = () => {
  const dispatch = useDispatch<AppDispatch>();
  const profile = useSelector((state: RootState) => state.user.profile);
  const history = useSelector((state: RootState) => state.user.history);
  const hasMore = useSelector(
    (state: RootState) => state.user.hasMore
  );
  const loading = useSelector<RootState, boolean>(
    (state: RootState) => state.user.loading
  );
  const params = useSelector<RootState, URLParams>(
    (state: RootState) => state.user.params
  );
  const { id } = useParams();

  const containerRef: any = useRef<HTMLHtmlElement>();

  const tableHeight = useCallback(() => {
    return (
      containerRef?.current?.getBoundingClientRect().top + TABLE_PADDING || 0
    );
  }, []);

  const handleCall = useCallback(
    ({ page }: any) => {
      dispatch(getEmployeeHistory({ id, params }));
      dispatch(updateParams({
          prop: 'page',
          value: page + 1,
        })
      );
    },
    [dispatch, id, params]
  );

  const { Trigger } = useInfiniteScroll({
    rows: history,
    next: handleCall,
    container: containerRef.current,
    hasMore,
    loading,
    page: params.page,
    size: params.size,
    sort: params.sort,
  });

  useEffect(() => {
    return () => {
      dispatch(resetTable());
    };
  }, [dispatch]);

  const renderRow = useCallback(
    (row: History, index: number) => {
      return (
        <Tr
          key={index}
          data-testid="employee-history-table-row"
          data-qa="employee-history-table-row"
          height="80px"
          className="employee-history-table-row"
        >
          <Td id={`employee-history-table-row-message`}>
            <Flex alignItems="center">
              <Box
                ml="12px"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                {row.message}
              </Box>
            </Flex>
          </Td>
          <Td
            width="250px"
            id={`employee-history-table-row-time`}
          >
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              {row.time}
            </Box>
          </Td>
        </Tr>
      );
    },
    []
  );
  
  return (
    <>
      <Box padding="1.6rem">
        <Flex>
          <Flex alignItems="center">
            <Avatar src={profile.image} name={profile.name} w="50px" h="50px" />
            <Box
              ml="12px"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              data-testid="employee-profile-name-and-avatar"
            >
              <Text
                  fontSize="4xl"
                  fontWeight={700}
                  color={profile.status === 'ACTIVE' ? colors.primary[500] : colors.greySolid[600]}
                >
                  {profile.name}
                </Text>

                <Text fontSize="13px" color={profile.status === 'ACTIVE' ? colors.greySolid[900] : colors.greySolid[600]}>
                  {profile.email}
                </Text>
            </Box>
          </Flex>
        </Flex>
      </Box>
      <Box
        px="25px"
        overflow="auto"
        height={`calc(100vh - ${tableHeight()}px)`}
        ref={containerRef}
      >
        <Table
          data-testid="employee-history-table"
          data-qa="employee-history-table"
          columns={TABLE_COLUMNS_HISTORY}
          rows={history}
          Trigger={Trigger}
          stickyHeader
          renderRow={renderRow}
        />
      </Box>
    </>
  );
};

export default ProfileHistory;

import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  EmployeeProfileTabs,
  SimpleEmployeeGroupItem,
  UserRole,
} from '../../api/types';
import { colors } from '../../design/styles/foundations';
import RegularArrowLeft from '../../design/styles/icons/regular/RegularArrowLeft';
import SvgSolidSave from '../../design/styles/icons/solid/SolidSave';
import SvgSolidDelete from '../../design/styles/icons/solid/SolidDelete';
import ProfileHistory from './components/ProfileHistory/ProfileHistory';
import ProfileOverview from './components/ProfileOverview/ProfileOverview';
import If from '../../components/If';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import userSlice from '../../store/slices/user';
import {
  getEmployeeProfile,
  updateEmployeeProfile,
} from '../../store/slices/user/user';
import { isEqual } from 'lodash';
import { useToast } from '../../design/hooks/useToast';

const tabList = [
  { title: EmployeeProfileTabs.Overview, value: EmployeeProfileTabs.Overview },
  { title: EmployeeProfileTabs.History, value: EmployeeProfileTabs.History },
];

const tabProps = {
  p: '4px',
  mr: 3,
  color: colors.greySolid[800],
  fontSize: 'xl',
};

const selectedTabLocalStorageItemName = 'selectedTabEmployeeProfile';

const EmployeeProfile = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const toast = useToast();
  const { id } = useParams();
  const tab = localStorage.getItem(selectedTabLocalStorageItemName) || '0';
  const user = useSelector((state: RootState) => state.user);
  const profile = useSelector((state: RootState) => state.user.profile);
  const initialProfile = useSelector(
    (state: RootState) => state.user.initialProfile
  );
  const [selectedTab, setSelectedTab] = useState(parseInt(tab));
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] =
    useState<boolean>(false);

  const handleSelectTab = useCallback((value: number) => {
    localStorage.setItem(selectedTabLocalStorageItemName, `${value}`);
    setSelectedTab(value);
  }, []);

  useEffect(() => {
    handleSelectTab(parseInt(tab));
    return () => {
      handleSelectTab(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => {
    navigate(-1);
    localStorage.setItem('selectedTabOrganization', '2');
  };

  const handleDiscard = () => {
    setIsSaveButtonDisabled(true);
    dispatch(userSlice.actions.discardProfileChanges());
    setIsSaveButtonDisabled(false);
  };

  const responseAction = useCallback(
    (res: any) => {
      dispatch(getEmployeeProfile(id));
      setIsSaveButtonDisabled(false);
      toast({
        position: 'top',
        title: !res.error ? 'Successfully' : 'Unsuccessfully',
        status: !res.error ? 'success' : 'error',
        description: !res.error
          ? "Updated employee's profile"
          : 'Something went wrong',
        isClosable: true,
        duration: 7000,
        variant: 'subtle',
      });
    },
    [dispatch, id, toast]
  );

  const handleSave = () => {
    setIsSaveButtonDisabled(true);
    const addedGroups = profile?.employeeGroups
      .filter(
        ({ id: id1 }: { id: string }) =>
          !initialProfile?.employeeGroups.some(
            ({ id: id2 }: { id: string }) => id2 === id1
          )
      )
      .map((item: SimpleEmployeeGroupItem) => item.id);
    const removedGroups = initialProfile?.employeeGroups
      .filter(
        ({ id: id1 }: { id: string }) =>
          !profile?.employeeGroups.some(
            ({ id: id2 }: { id: string }) => id2 === id1
          )
      )
      .map((item: SimpleEmployeeGroupItem) => item.id);

    const editedProfile = {
      employeeRole: profile.role,
      hubLocation: profile.location,
      employeeStatus: profile.status,
      addedEmployeeGroups: addedGroups,
      removedEmployeeGroups: removedGroups,
    };

    dispatch(updateEmployeeProfile({ data: editedProfile, id: id })).then(
      (res) => responseAction(res)
    );
  };

  return (
    <>
      <Box bg={colors.light.appBg} height="100%" minHeight="calc(100vh - 60px)">
        <Tabs isLazy index={selectedTab} onChange={handleSelectTab}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            bg="white"
          >
            <Flex justifyContent="space-between" p="28px 40px 20px 40px">
              <Flex alignItems="center">
                <Icon
                  as={RegularArrowLeft}
                  onClick={() => goBack()}
                  cursor="pointer"
                  data-testid="employee-profile-back-button"
                  data-qa="employee-profile-back-button"
                />
                <Heading
                  as="h1"
                  size="4xl"
                  fontWeight="700"
                  paddingLeft="1.5rem"
                >
                  Employee’s profile
                </Heading>
              </Flex>
              <If condition={user.role === UserRole.Admin && selectedTab === 0}>
                <Flex>
                  <Button
                    leftIcon={
                      <SvgSolidDelete
                        width="20px"
                        height="20px"
                      ></SvgSolidDelete>
                    }
                    colorScheme="secondary"
                    size="md"
                    fontWeight="600"
                    iconSpacing="4px"
                    ml={3}
                    border="1px"
                    borderColor="gray.200"
                    variant="ghost"
                    textColor={colors.primary[500]}
                    onClick={handleDiscard}
                    isDisabled={
                      isEqual(profile, initialProfile) || isSaveButtonDisabled
                    }
                    data-testid="employee-profile-discard-button"
                    data-qa="employee-profile-discard-button"
                  >
                    Discard
                  </Button>
                  <Button
                    leftIcon={<SvgSolidSave />}
                    colorScheme="primary"
                    variant="solid"
                    size="md"
                    fontWeight="600"
                    iconSpacing="4px"
                    ml={3}
                    onClick={handleSave}
                    isDisabled={
                      isEqual(profile, initialProfile) || isSaveButtonDisabled
                    }
                    data-testid="employee-profile-save-button"
                  >
                    Save
                  </Button>
                </Flex>
              </If>
            </Flex>
            <Box>
              <TabList px="40px">
                {tabList.map((item) => (
                   user.role === UserRole.User && item.title === EmployeeProfileTabs.History ? null :
                  <Tab
                    key={item.value}
                    {...tabProps}
                    data-testid={`profile-tab-${item.value}`}
                    data-qa={`profile-tab-${item.value}`}
                  >
                    {item.title}
                  </Tab>
                  ))}
              </TabList>
            </Box>
          </Box>
          <Box>
            <TabPanels>
              <TabPanel>
                <ProfileOverview />
              </TabPanel>
              <TabPanel>
                <ProfileHistory />
              </TabPanel>
            </TabPanels>
          </Box>
        </Tabs>
      </Box>
    </>
  );
};

export default EmployeeProfile;
